import { AbundanceAreaApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { abundanceAreaActions } from "./actionTypes";

export const getAbundanceAreaList = (limit, pageNumber, search) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&title=${search}` : ''; // Conditionally add the search query

        const response = await Instance(
            getState, // state
            "GET", // method
            AbundanceAreaApiEndpoint.GET_ABUNDANCE_AREA_LIST_API + `?page=${pageNumber}&limit=${limit}` + searchParam, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: abundanceAreaActions.GET_ABUNDANCE_AREA_LIST,
                payload: data,
            });
        }
        return response;
    };
};

export const getSelectedAbundanceArea = (abundanceAreaDetails) => {
    return async (dispatch) => {
        return dispatch({
            type: abundanceAreaActions.SELECTED_ABUNDANCE_AREA,
            payload: abundanceAreaDetails,
            status: 200,
            success: true,
        });
    };
}

export const getAllAbundanceArea = () => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "GET", // method
            AbundanceAreaApiEndpoint.GET_ABUNDANCE_AREA_LIST_API + `?page=${0}`, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: abundanceAreaActions.GET_ALL_ABUNDANCE_AREA,
                payload: data,
            });
        }
        return response;
    };
};

export const addAbundanceArea = (params) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "POST", // method
            AbundanceAreaApiEndpoint.ADD_ABUNDANCE_AREA_API,// api-endpoint
            params, // data/params
            true // isAuth
        );

        return response;
    };
};

export const editAbundanceArea = (params) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "PUT", // method
            AbundanceAreaApiEndpoint.UPDATE_ABUNDANCE_AREA_API,// api-endpoint
            params, // data/params
            true // isAuth
        );

        return response;
    };
};

export const deleteAbundanceArea = (id) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "DELETE", // method
            AbundanceAreaApiEndpoint.DELETE_ABUNDANCE_AREA_API + id,// api-endpoint
            null, // data/params
            true // isAuth
        );

        return response;
    };
};

