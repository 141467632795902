import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { ButtonLoader, DataLoader, HeaderView, PhoneNumberInput, PopupModal, SearchBar, SidebarPanel, ValidationError } from "../components";
import { IMAGES } from "../static";
import { PaginationView } from "../common";
import { dropDownLimits, formatedCountryCode, formatedPhone, } from "../utils/helperFunctions";
import { deleteUserAccount, getAllUsers, getSelectedUser, updateUserDetails } from "../common/store/actions/userActions";
import s3Upload from "../utils/s3bucket";
import { doGetAwsCredentials } from "../common/store/actions/authActions";

export const UsersView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const users = useSelector(({ users }) => users?.users);
    const awsCredentials = useSelector(({ auth }) => auth?.awsCredentials);
    const keyId = process.env.REACT_APP_AWS_CONFIG_KEY_ID;

    const folder_name = "profile_images/";
    const timestamp = new Date().getTime() * 1000;

    const [showBody, setShowBody] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    // loader states
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const [limit, setLimit] = useState(dropDownLimits[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searched, setSearched] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [newProfileImage, setNewProfileImage] = useState("");
    const [phone, setPhone] = useState('');

    const tableHeaders = ["#",
        t('TableHeader.name'),
        t('TableHeader.email'),
        t('TableHeader.mobile_no'),
        t('TableHeader.status'),
        t('TableHeader.statusAction'),
        t('TableHeader.actions')];

    const fetchUsers = (search = searchKeyword) => {
        setIsLoading(true);
        dispatch(getAllUsers(limit, pageNumber, search)).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                toastr.error("Error", res?.data?.message);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                const { limit, totalItems, totalPages } = res?.data;
                setCount(limit ? limit : 0);
                setTotalEntries(totalItems ? totalItems : 0);
                setTotalPages(totalPages ? totalPages : 0);
            }
        })
    }

    const handleSelectedUser = (userDetails) => {
        dispatch(getSelectedUser(userDetails)).then((res) => {
            if (res?.status === 200 && res?.success) {
                navigate("/user-details");
            }
        })
    }

    const handleStatusModal = (userDetails) => {
        setSelectedUser(userDetails);
        setConfirmationModal(true);
    }

    const handleUpdateStatus = (status) => {
        const params = {
            id: selectedUser?._id,
            status: status
        }
        setIsSubmitLoader(true);
        dispatch(updateUserDetails(params)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
                setIsSubmitLoader(false);
                toastr.success("Success", res?.data?.message);
                setIsLoading(true);
                setConfirmationModal(false);
                fetchUsers();
            }
            else {
                setIsSubmitLoader(false);
                toastr.error("Error", res?.data?.message || res?.data?.data?.message);
            }
        })
    }

    const handleEditModal = (userDetails) => {
        setSelectedUser(userDetails);
        setPhone(userDetails?.countryCode + userDetails?.phoneNumber);
        UserFormik.setValues({
            id: userDetails?._id,
            bio: userDetails.bio,
            countryCode: Number(userDetails.countryCode),
            displayName: userDetails.displayName,
            phoneNumber: userDetails?.phoneNumber.replace(/[^0-9]/g, ""),
            profileImage: userDetails?.profileImage,
            userName: userDetails.userName,
        });
        setEditModal(true);
    }

    const handleUpdate = async (params) => {
        await dispatch(updateUserDetails(params)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
                setIsSubmitLoader(false);
                toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                fetchUsers();
                setEditModal(false);
                UserFormik.resetForm();
                setNewProfileImage("");
                setPhone("");
                setSelectedUser("");
            }
            else {
                setIsSubmitLoader(false);
                toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                setEditModal(false);
                UserFormik.resetForm();
                setNewProfileImage("");
                setPhone("");
                setSelectedUser("");
            }
        })
    }

    const handleDeleteModal = (userDetails) => {
        setSelectedUser(userDetails);
        setDeleteModal(true);
    }

    const handleDeleteUser = async () => {
        const params = {
            userId: selectedUser?._id,
        }
        setIsSubmitLoader(true);
        await dispatch(deleteUserAccount(params)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
                setIsSubmitLoader(false);
                toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                fetchUsers();
                setDeleteModal(false);
                setSelectedUser("");
            }
            else {
                setIsSubmitLoader(false);
                toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                setDeleteModal(false);
                setSelectedUser("");
            }
        })
    }


    const handleSearch = () => {
        setPageNumber(1);
        fetchUsers(searchKeyword);
        setSearched(true);
    };

    const UserFormik = useFormik({
        initialValues: {
            id: "",
            userName: "",
            displayName: "",
            phoneNumber: "",
            countryCode: "",
            bio: "",
            profileImage: "",
        },
        validationSchema: Yup.object({
            userName: Yup.string().required("Username is required!"),
            displayName: Yup.string(),
            phoneNumber: Yup.string().matches(/^[0-9]*$/, "Must be a number"),
            countryCode: Yup.string(),
            bio: Yup.string(),
            profileImage: Yup.mixed().test("fileType", "Incorrect file type", (file) => {
                if (!file?.type) {
                    return true;
                }
                return ["image/png", "image/jpg", "image/jpeg"].includes(file.type);
            }),
        }),
        onSubmit: async (values) => {
            if (UserFormik.isValid) {
                setIsSubmitLoader(true);

                if (typeof values?.profileImage === 'object' && values?.profileImage?.name) {
                    // const formData = new FormData();
                    // // Append all form fields to the formData
                    // Object.keys(values).forEach((key) => {
                    //     formData.append(key, values[key]);
                    // });

                    const keyName = `${folder_name + timestamp}_${values?.profileImage?.name}`;

                    try {
                        const res = await s3Upload(values.profileImage, keyName, values?.profileImage?.type, awsCredentials);

                        if (res?.Location) {
                            const params = { ...values, profileImage: keyName };
                            handleUpdate(params);
                        }
                    } catch (err) {
                        // Handle the error from s3Upload                        
                        setIsLoading(false);
                        setIsSubmitLoader(false);
                        setEditModal(false);
                        UserFormik.resetForm();
                        setNewProfileImage("");
                    }

                }
                else {
                    const params = { ...values }
                    delete params.profileImage
                    handleUpdate(params);
                }
            }
        },
    })

    useEffect(() => {
        dispatch(doGetAwsCredentials({ keyId: keyId })).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                toastr.error("Error", res?.data?.message || res?.data?.data?.message);
            }
        });
    }, [dispatch, keyId])

    useEffect(() => {
        fetchUsers();
        if (sessionStorage.getItem('searchKeyword')) {
            setSearchKeyword(sessionStorage.getItem('searchKeyword'))
        }        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dispatch, limit, pageNumber])

    useEffect(() => {
        if (searchKeyword.length === 0 && searched) {
            fetchUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword,searched])

    useEffect(() => {
        UserFormik.setFieldValue("phoneNumber", formatedPhone(phone));
        UserFormik.setFieldValue("countryCode", formatedCountryCode(phone));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phone])

    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={t("Sidebar.users")}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

                    <div className="main-container">
                        <div className="table-container mainCustomBG">
                            <div className="headingTbL filterResponsiveCus filterMain">
                                <Dropdown drop className="dropdown filtstatus" onSelect={(e) => {
                                    setLimit(e);
                                }}>
                                    <span className="sortingShow">{t('Filterbar.show')}
                                        <Dropdown.Toggle>
                                            <span>{limit}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="icon" />
                                        </Dropdown.Toggle>
                                        {t('Filterbar.enteries')}
                                    </span>
                                    <Dropdown.Menu
                                        variant=""
                                        id="style-5"
                                        className="cusDrupdown"
                                    >
                                        {dropDownLimits?.map((limit, index) => (
                                            <Dropdown.Item eventKey={limit} key={limit}>
                                                {limit}
                                            </Dropdown.Item>
                                        ))}

                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="search-container">
                                    <SearchBar name="Search" value={searchKeyword} setSearchKeyword={setSearchKeyword} onClick={() => {
                                        sessionStorage.setItem('searchKeyword', searchKeyword);
                                        handleSearch();
                                    }} />

                                </span>

                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {tableHeaders?.map((heading, index) => (
                                                <th key={heading} className={tableHeaders[tableHeaders.length - 2] === heading ? 'text-center' : ''}>
                                                    {heading}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <tr>
                                                <td colSpan={tableHeaders.length} className="text-center tableE_Caption">
                                                    <DataLoader />
                                                </td>

                                            </tr>
                                            :
                                            <>
                                                {users?.length > 0 ? users?.map((user, index) => (
                                                    <tr key={user._id}>
                                                        <td>{(parseInt(limit * (pageNumber - 1) + index + 1) < 10) ? "0" + (limit * (pageNumber - 1) + index + 1) : (limit * (pageNumber - 1) + index + 1)}</td>
                                                        <td>
                                                            {user.userName ? user.userName : "N/A"}
                                                        </td>
                                                        <td>
                                                            {user.email ? user.email : "N/A"}
                                                        </td>
                                                        <td>
                                                            {user.phoneNumber ? user?.countryCode + " " + user.phoneNumber : "N/A"}
                                                        </td>
                                                        <td className={user.status === "active" ? "activeclr" : "inactiveclr"}>
                                                            {user.status}
                                                        </td>
                                                        <td className={user.status === "active text-center" ? "activeclr text-center" : "inactiveclr text-center"}>
                                                            {/* {user.status} */}
                                                            <div className="toggleSwitch">
                                                                <input type="checkbox" id={"checkbox" + index}
                                                                    checked={user?.status === "active" ? true : false}
                                                                    onChange={() => { handleStatusModal(user) }} />
                                                                <label htmlFor={"checkbox" + index}></label>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button className="icon-button" title="View" onClick={() => { handleSelectedUser(user) }}><img src={IMAGES.view_icon} alt="View" /></button>
                                                            <button className="icon-button" title="Edit" disabled={user.status === "deleted"} onClick={() => { handleEditModal(user) }}><img src={IMAGES.edit_icon} alt="Edit" /></button>
                                                            <button className="icon-button" title="Delete" disabled={user.status !== "active"} onClick={() => { handleDeleteModal(user) }}><img src={IMAGES.delete_icon} alt="Delete" /></button>

                                                        </td>
                                                    </tr>

                                                )) :
                                                    <tr>
                                                        <td colSpan={tableHeaders.length} className="text-center">
                                                            {t('NotFound.user_record')}
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-pagination">
                                <div className="col-md-6 show_entrieNum">
                                    <p>
                                        {t('Pagination.showing')} {count > 0 ? 1 : 0} {t('Pagination.to')} {limit} {t('Pagination.of')} {totalEntries} {t('Pagination.entries')}
                                    </p>
                                </div>
                                <div className="col-md-6 pagination">
                                    <PaginationView
                                        totalPages={totalPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {confirmationModal && (
                <PopupModal
                    show={confirmationModal}
                    onHide={() => {
                        setConfirmationModal(false);
                    }}
                // title={"Update User Status"}
                >
                    <form>
                        <h1>{"Update User Status"}</h1>
                        <img src={selectedUser.status === "active" ? IMAGES.disebleModal : IMAGES.enableModal} alt="" />
                        <p className="praregraphP width50">{selectedUser.status === "active" ? "Are you sure want to disable this user?" : "Are you sure want to enable this user?"}</p>
                        <div className="footerBtnAll">
                            <button
                                onClick={() => {
                                    setConfirmationModal(false)
                                }}
                                className="cancelborder"
                            >
                                {t("Buttons.cancel")}
                            </button>
                            <button
                                type="button"
                                disabled={isSubmitLoader}
                                onClick={() => {
                                    handleUpdateStatus(selectedUser?.status === "active" ? "deactive" : "active");
                                }}
                            >
                                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.confirm")}
                            </button>
                        </div>
                    </form>
                </PopupModal>
            )}

            {editModal &&
                <PopupModal className={"areaModal"} show={editModal} onHide={() => {
                    setEditModal(false);
                    setIsLoading(false);
                    setIsSubmitLoader(false);
                    UserFormik.resetForm();
                    setNewProfileImage("");

                }} >
                    <form method="POST" onSubmit={UserFormik.handleSubmit}>
                        <h1>{"Update User Details"}</h1>
                        <div className="profile-add-img">
                            <div className="circle">
                                <img className="profile-pic" src={newProfileImage ? newProfileImage : UserFormik?.values?.profileImage ? `${awsCredentials?.CDN_URL}/${UserFormik?.values?.profileImage}` : IMAGES.profile_bg_icon} alt="" />
                            </div>
                            <div className="p-image ml-auto">
                                <label className="mb-0" htmlFor="contact-profile">
                                    <span className="upload-button" >
                                        <img src={IMAGES.profile_edit_icon} alt="Edit" />
                                    </span>
                                </label>

                                <input className="file-upload" id="contact-profile" name="profileImage" type="file" accept="image/*" hidden onChange={(event) => {
                                    if (event.currentTarget.files.length > 0) {
                                        UserFormik.setFieldValue(
                                            "profileImage",
                                            event.currentTarget.files[0]
                                        );
                                        setNewProfileImage(URL.createObjectURL(event.target.files[0]));
                                    }
                                }}
                                    onBlur={UserFormik.handleBlur} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Username <span>*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter Username" name="userName"
                                        onChange={UserFormik.handleChange} onBlur={UserFormik.handleBlur}
                                        value={UserFormik.values.userName} />
                                    {UserFormik.touched.userName && UserFormik.errors.userName && (
                                        <ValidationError error={UserFormik.errors.userName} />
                                    )}

                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Display Name </label>
                                    <input type="text" className="form-control" placeholder="Enter display name" name="displayName"
                                        onChange={UserFormik.handleChange}
                                        onBlur={UserFormik.handleBlur}
                                        value={UserFormik.values.displayName} />

                                    {UserFormik.touched.displayName && UserFormik.errors.displayName && (
                                        <ValidationError error={UserFormik.errors.displayName} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <PhoneNumberInput Formik={UserFormik} Phone={phone} setPhone={setPhone} disable={isSubmitLoader} />
                                    {UserFormik.touched.phoneNumber && UserFormik.errors.phoneNumber && (
                                        <ValidationError error={UserFormik.errors.phoneNumber} />
                                    )}
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control" placeholder="Enter email" name="email"
                                        onChange={UserFormik.handleChange} onBlur={UserFormik.handleBlur}
                                        value={selectedUser?.email} disabled readOnly />
                                </div>

                            </div>
                        </div>
                        <div className="form-group">
                            <label>Bio </label>
                            <textarea rows="5" type="text" className="form-control biotextarea" placeholder="Enter bio" name="bio"
                                onChange={UserFormik.handleChange} onBlur={UserFormik.handleBlur}
                                value={UserFormik.values.bio} >
                            </textarea>

                            {UserFormik.touched.bio && UserFormik.errors.bio && (
                                <ValidationError error={UserFormik.errors.bio} />
                            )}
                        </div>

                        <div className="footerBtnAll userupdateMargin">
                            <button onClick={() => {
                                setEditModal(false);
                                setIsLoading(false);
                                setIsSubmitLoader(false);
                                UserFormik.resetForm();
                                setNewProfileImage("");
                            }}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    "Update"
                                }
                            </button>
                        </div>
                    </form>
                </PopupModal>
            }

            {deleteModal && (
                <PopupModal
                    show={deleteModal}
                    onHide={() => {
                        setDeleteModal(false);
                    }}
                >
                    <form>
                        <h1>{"Delete User Account"}</h1>
                        <img src={IMAGES.deleteAccountIcon} alt="" />
                        <p className="praregraphP width50">{"Are you sure want to delete this user?"}</p>
                        <div className="footerBtnAll">
                            <button
                                onClick={() => {
                                    setDeleteModal(false)
                                }}
                                className="cancelborder"
                            >
                                {t("Buttons.cancel")}
                            </button>
                            <button
                                type="button"
                                disabled={isSubmitLoader}
                                onClick={() => {
                                    handleDeleteUser();
                                }}
                            >
                                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.confirm")}
                            </button>
                        </div>
                    </form>
                </PopupModal>
            )}

        </>
    )
}
