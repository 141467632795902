import { abundanceAreaActions } from "../actions/actionTypes";

const getInitialState = () => ({
  abundanceAreaList: [],
  selectedAbundanceArea: {},
  allAbundanceArea: []
});

export const abundanceAreaReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case abundanceAreaActions.GET_ABUNDANCE_AREA_LIST:
      return {
        ...state,
        abundanceAreaList: action.payload,
      };

    case abundanceAreaActions.GET_ALL_ABUNDANCE_AREA:
      return {
        ...state,
        allAbundanceArea: action.payload,
      };

    case abundanceAreaActions.SELECTED_ABUNDANCE_AREA:
      return {
        ...state,
        selectedAbundanceArea: action.payload
      }

    case abundanceAreaActions.AUTH_LOGOUT:
      return {
        ...state,
        abundanceAreaList: [],
        selectedAbundanceArea: {},
        allAbundanceArea: []
      };

    default:
      return state;
  }
};
