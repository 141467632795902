import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";

import { IMAGES } from "../static";
import { ButtonLoader, ValidationError } from "../components";
import { useTranslation } from "react-i18next";
import { doAuthSetPassword } from "../common/store/actions/authActions";

export const ResetPasswordView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userEmail = useSelector(({ auth }) => auth?.userEmail);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // loader states
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const ResetPasswordFormik = useFormik({
        initialValues: {
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: Yup.object({
            newPassword: Yup.string().required(t("Error.password_required")).min(6, t("Error.password_length")),
            confirmNewPassword: Yup.string().min(6, t("Error.password_length")).oneOf([Yup.ref('newPassword')], t("Error.password_doesnt_match")).required(t("Error.confirm_password_required")),
        }),
        onSubmit: async (values) => {

            if (ResetPasswordFormik.isValid && ResetPasswordFormik.dirty) {
                setIsSubmitLoader(true);

                const params = {
                    email: userEmail,
                    password: values?.confirmNewPassword
                }

                dispatch(doAuthSetPassword(params)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message);
                        navigate("/");
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message);
                    }
                });
            }
        },
    });

    return (
        <>
            <div className="cover-main ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="align-self-center d-flex form-section">
                                <div className="effect-box">
                                    <div className="logo_section">
                                        <img src={IMAGES.logo} alt="" />
                                    </div>
                                    <div className="cover-box-txt">
                                    <h1><button onClick={()=> navigate("/verify-code")}><img src={IMAGES.back_Shape} alt=""/></button> {t("ResetPassword.reset_password")}</h1>
                                        <div className="form-card">
                                            <form method="POST" onSubmit={ResetPasswordFormik.handleSubmit}>
                                                

                                                <div className="form-group">
                                                    <label>New password</label>

                                                    <div className="possiPassIcon">
                                                        <input type={showPassword ? "text" : "password"} className="form-control" placeholder={t("ResetPassword.new_password_placeholder")}
                                                            name="newPassword" value={ResetPasswordFormik?.values?.newPassword} onChange={ResetPasswordFormik.handleChange}
                                                            onBlur={ResetPasswordFormik.handleBlur} autoComplete="false" />
                                                        <img src={showPassword ? IMAGES.off_eye_icon : IMAGES.eye_icon} alt="" onClick={(e) => setShowPassword(!showPassword)} />
                                                    </div>

                                                    {ResetPasswordFormik.touched.newPassword && ResetPasswordFormik.errors.newPassword && (
                                                        <ValidationError error={ResetPasswordFormik.errors.newPassword} />
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Confirm new password</label>

                                                    <div className="possiPassIcon">
                                                        <input type={showConfirmPassword ? "text" : "password"} className="form-control" placeholder={t("ResetPassword.confirm_password_placeholder")}
                                                            name="confirmNewPassword" value={ResetPasswordFormik?.values?.confirmNewPassword} onChange={ResetPasswordFormik.handleChange}
                                                            onBlur={ResetPasswordFormik.handleBlur} autoComplete="false" />
                                                        <img src={showConfirmPassword ? IMAGES.off_eye_icon : IMAGES.eye_icon} alt="Eye" onClick={(e) => setShowConfirmPassword(!showConfirmPassword)} />
                                                    </div>

                                                    {ResetPasswordFormik.touched.confirmNewPassword && ResetPasswordFormik.errors.confirmNewPassword && (
                                                        <ValidationError error={ResetPasswordFormik.errors.confirmNewPassword} />
                                                    )}
                                                </div>
                                                <ul>
                                                    <li><img src={IMAGES.right_Tick} alt=""/> Minimum 8 characters</li>
                                                    <li><img src={IMAGES.right_Tick} alt=""/> 1 capital letter</li>
                                                    <li><img src={IMAGES.right_Tick} alt=""/> 1 lowercase latter</li>
                                                    <li><img src={IMAGES.right_Tick} alt=""/> 1 Special Character</li>
                                                </ul>

                                                <div className="footer-buttons">
                                                    <div className="form-group">
                                                        <button type="submit" disabled={isSubmitLoader}>
                                                            {isSubmitLoader ?
                                                                <span className="d-flex justify-content-between">
                                                                    <ButtonLoader />
                                                                    {t("Loading.please_wait")}
                                                                </span>
                                                                :
                                                                t("Buttons.reset")
                                                            }
                                                        </button>
                                                        {/* <p className="back-button" onClick={() => navigate(-1)}>{t("Buttons.back")}</p> */}
                                                    </div>
                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="rightAuthbx">
                                <img src={IMAGES.login_Auth_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
                    </>
                    )
}
