import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import moment from "moment"; // Import Moment.js

import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DataLoader, HeaderView, SearchBar, SidebarPanel } from "../components";
import { IMAGES } from "../static";
import { PaginationView } from "../common";
import { dropDownLimits } from "../utils/helperFunctions";
import { getAllUsersLogs } from "../common/store/actions/userActions";

export const LoggingView = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const users = useSelector(({ users }) => users?.usersLogs);
    const [showBody, setShowBody] = useState(false);

    // loader states
    const [isLoading, setIsLoading] = useState(true);
 
    // loader states
    const [limit, setLimit] = useState(dropDownLimits[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searched, setSearched] = useState(false);

    const tableHeaders = ["#", t('TableHeader.email'), t('TableHeader.login')];

    const fetchUsers = (search = searchKeyword) => {
        setIsLoading(true);
        dispatch(getAllUsersLogs(limit, pageNumber, search)).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                toastr.error("Error", res?.data?.message);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                const { limit, totalItems, totalPages } = res?.data;
                setCount(limit ? limit : 0);
                setTotalEntries(totalItems ? totalItems : 0);
                setTotalPages(totalPages ? totalPages : 0);
            }
        });
    };

    const handleSearch = () => {
        setPageNumber(1);
        fetchUsers(searchKeyword);
        setSearched(true);
    };

    useEffect(() => {
        fetchUsers();
        if (sessionStorage.getItem('searchKeyword')) {
            setSearchKeyword(sessionStorage.getItem('searchKeyword'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, limit, pageNumber]);

    useEffect(() => {
        if (searchKeyword.length === 0 && searched) {
            fetchUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword, searched]);

    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={t("Sidebar.users")}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

                    <div className="main-container">
                        <div className="table-container mainCustomBG">
                            <div className="headingTbL filterResponsiveCus filterMain">
                                <Dropdown drop className="dropdown filtstatus" onSelect={(e) => {
                                    setLimit(e);
                                }}>
                                    <span className="sortingShow">{t('Filterbar.show')}
                                        <Dropdown.Toggle>
                                            <span>{limit}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="icon" />
                                        </Dropdown.Toggle>
                                        {t('Filterbar.enteries')}
                                    </span>
                                    <Dropdown.Menu
                                        variant=""
                                        id="style-5"
                                        className="cusDrupdown"
                                    >
                                        {dropDownLimits?.map((limit, index) => (
                                            <Dropdown.Item eventKey={limit} key={limit}>
                                                {limit}
                                            </Dropdown.Item>
                                        ))}

                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="search-container">
                                    <SearchBar name="Search" value={searchKeyword} setSearchKeyword={setSearchKeyword} onClick={() => {
                                        sessionStorage.setItem('searchKeyword', searchKeyword);
                                        handleSearch();
                                    }} />
                                </span>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {tableHeaders?.map((heading, index) => (
                                                <th key={heading} className={tableHeaders[tableHeaders.length - 2] === heading ? 'text-center' : ''}>
                                                    {heading}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <tr>
                                                <td colSpan={tableHeaders.length} className="text-center tableE_Caption">
                                                    <DataLoader />
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {users?.length > 0 ? users?.map((user, index) => (
                                                    <tr key={user._id}>
                                                        <td>{(parseInt(limit * (pageNumber - 1) + index + 1) < 10) ? "0" + (limit * (pageNumber - 1) + index + 1) : (limit * (pageNumber - 1) + index + 1)}</td>
                                                        <td>
                                                            {user.email ? user.email : "N/A"}
                                                        </td>
                                                        <td>
                                                            {user.loginAt ? moment(user.loginAt).format('MMMM Do YYYY, h:mm:ss a') : "N/A"}
                                                        </td>
                                                    </tr>
                                                )) :
                                                    <tr>
                                                        <td colSpan={tableHeaders.length} className="text-center">
                                                            {t('NotFound.user_record')}
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-pagination">
                                <div className="col-md-6 show_entrieNum">
                                    <p>
                                        {t('Pagination.showing')} {count > 0 ? 1 : 0} {t('Pagination.to')} {limit} {t('Pagination.of')} {totalEntries} {t('Pagination.entries')}
                                    </p>
                                </div>
                                <div className="col-md-6 pagination">
                                    <PaginationView
                                        totalPages={totalPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
