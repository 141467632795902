import AWS from "aws-sdk";
import { Buffer } from "buffer";
import { toastr } from "react-redux-toastr";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const s3Upload = async (file, key, contentType, awsCredentials) => {
  try {
    const {
      AWS_ACCESS_KEY_ID: S3_ACCESS_KEY_ID,
      AWS_SECRET_ACCESS_KEY: S3_SECRET_ACCESS_KEY,
      AWS_BUCKET: S3_BUCKET,
      AWS_REGION: S3_REGION,
      AWS_ENDPOINT: S3_ENDPOINT,
    } = awsCredentials || {};

    // SDK Client configurations
    /* AWS Config */
    AWS.config.update({
      accessKeyId: S3_ACCESS_KEY_ID,
      secretAccessKey: S3_SECRET_ACCESS_KEY,
    });

    let endpoint = new AWS.Endpoint(S3_ENDPOINT);

    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: S3_REGION,
      endpoint: endpoint,
    });
    /* AWS Config */

    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: key,
      Body: file,
      ACL: "public-read",
      ContentType: contentType,
    };
    var options = { partSize: 50 * 1024 * 1024, queueSize: 1 };

    return new Promise((resolve, reject) => {
      // Upload the file to S3
      myBucket.upload(params, options, (err, data) => {
        if (err) {
          // console.error("Error uploading file:", err);
          toastr.error("FILE UPLOAD", err?.message);
          reject(err); // Reject the promise with an error
        } else if (data?.Location) {
          resolve(data); // Resolve the promise with the file URL
        }
      });
    });
  } catch (err) {
    // This block won't catch errors from asynchronous operations
    // console.log(err);
    toastr.error("Error", "Something went wrong!");
    throw err; // Rethrow the error if you want it to propagate to the calling code
  }
};

export default s3Upload;
