
import axiosInstance from "./axiosInstance"; // Adjust the path accordingly

// for initiating api call from redux-action
export const Instance = async (
  getState,
  method = "GET",
  URL,
  params = {},
  isAuth = false,
  isFormData
) => {
  const headers = isAuth ? { Authorization: `Bearer ${getState().auth.loginUser.accessToken}` } : {};
  headers["Content-Type"] = isFormData ? "multipart/form-data" : "application/json";

  try {
      let config = {
      method: method,
      url: URL,
      headers: headers,
    };

    if (method === "GET") {
      config = { ...config };
    } else if (method === "DELETE") {
      config.data = params ? JSON.stringify(params) : new FormData();
    } else if (method === "PUT") {
      config.data = params;
    } else if (method === "PATCH") {
      config.data = params ? params : new FormData();
    } else {
      config.data = params ? params : new FormData();
    }

    const response = await axiosInstance(config);
    return response;
  } catch (err) {
    return err;
  }
};

