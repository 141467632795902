import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
    ButtonLoader,
    DataLoader,
    HeaderView,
    PopupModal,
    SearchBar,
    SidebarPanel,
    ValidationError,
} from "../components";
import { addQuote, getQuotesByAreaId } from "../common/store/actions/quoteActions";
import { IMAGES } from "../static";
import { dropDownLimits } from "../utils/helperFunctions";
import { PaginationView } from "../common";
import { useNavigate } from "react-router-dom";

export const QuoteListView = () => {
 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selectedScreenArea = useSelector(({ quotes }) => quotes?.selectedScreenArea);
    const quotes = useSelector(({ quotes }) => quotes?.quotes);

    const [showBody, setShowBody] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);

    // loader states
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const [selectedQuote, setSelectedQuote] = useState({});
    const [limit, setLimit] = useState(dropDownLimits[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searched, setSearched] = useState(false);
    const [count, setCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);

    const tableHeaders = ["#", "Quote Text", "Status", "Actions"];

    const AddQuoteFormik = useFormik({
        initialValues: {
            quote: "",
            author:"",
            source:"",
            date:new Date().toISOString().split('T')[0]
        },
        validationSchema: Yup.object({
            quote:  Yup.string().required("Quote is required!"),
            author: Yup.string().required("Author is required!"),
            source: Yup.string(),
            date : Yup.date(),
        }),
        onSubmit: async (values) => {
            if (AddQuoteFormik.isValid && AddQuoteFormik.dirty) {
                setIsSubmitLoader(true);
                const quoteParams = {
                    ...values,
                    areaId: selectedScreenArea?._id
                }
                dispatch(addQuote(quoteParams)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                        fetchQuotes();
                        setAddModal(false);
                        AddQuoteFormik.resetForm();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                        setAddModal(false);
                    }
                })
            }
        },
    })

    const handleEditQuote = (quoteDetails) => {
        const formattedDate = new Date(quoteDetails.date).toISOString().split('T')[0];
        setSelectedQuote(quoteDetails);
        EditQuoteFormik.setValues({
            ...quoteDetails,
            date:formattedDate,
        });
        setEditModal(true);
    }

    const handleViewQuote = (quoteDetails) => {
        const formattedDate = new Date(quoteDetails.date).toISOString().split('T')[0];
        setSelectedQuote({
            ...quoteDetails,
            date:formattedDate,
        });
        setViewModal(true);
    }

    const EditQuoteFormik = useFormik({
        initialValues: {
            quote: "",
            author:"",
            source:"",
            date:"",
            status: ""
        },
        validationSchema: Yup.object({
            quote: Yup.string().required("Quote is required!"),
            author: Yup.string().required("Author is required!"),
            source :Yup.string(),
            date: Yup.date(),
            status: Yup.string().required("Status is required!"),
        }),
        onSubmit: async (values) => {
            if (EditQuoteFormik.isValid) {
                setIsSubmitLoader(true);
                const quoteParams = {
                    ...values,
                    id: selectedQuote?._id,
                    areaId: selectedScreenArea?._id
                }
                dispatch(addQuote(quoteParams)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                        fetchQuotes();
                        setEditModal(false);
                        EditQuoteFormik.resetForm();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                        setEditModal(false);
                    }
                })
            }
        },
    })

    const fetchQuotes = (search) => {
        setIsLoading(true);
        dispatch(getQuotesByAreaId(selectedScreenArea?._id, pageNumber, limit, search)).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                toastr.error("Error", res?.data?.message);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                const { limit, totalItems, totalPages } = res?.data;
                setCount(limit ? limit : 0);
                setTotalEntries(totalItems ? totalItems : 0);
                setTotalPages(totalPages ? totalPages : 0);
            }
        });
    };

    const handleSearch = () => {
        fetchQuotes(searchKeyword);
        setSearched(true);
    };

    useEffect(() => {
        fetchQuotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, limit, dispatch]);

    useEffect(() => {
        if (searchKeyword.length === 0 && searched) {
            fetchQuotes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword])

    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={t("Sidebar.quotes_management")}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

                    <div className="main-container">
                        <div className="userDetail-container mainCustomBG table-container">
                            <div className="main-heading">
                                <h1>
                                    {selectedScreenArea?.areaName}
                                    {/* <button type="button" className="button-back" onClick={() => { navigate("/quotes-management") }}><img src={IMAGES.rightErrow} alt="" /> Back</button> */}
                                    <span className="d-flex"><button type="button" className="button-back mr-2" onClick={() => { setAddModal(true) }}>
                                        <img src={IMAGES.plus_icon} alt="" />
                                        {t("Buttons.add")}
                                    </button>

                                        <button type="button" className="button-back" onClick={() => { navigate("/quotes-management") }}><img src={IMAGES.rightErrow} alt="" />
                                            {t('Buttons.back')}
                                        </button>
                                    </span>
                                </h1>
                            </div>

                            <div className="headingTbL filterResponsiveCus filterMain">
                                <Dropdown drop className="dropdown filtstatus" onSelect={(e) => {
                                    setLimit(e);
                                }}>
                                    <span className="sortingShow">{t('Filterbar.show')}
                                        <Dropdown.Toggle>
                                            <span>{limit}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                        {t('Filterbar.enteries')}
                                    </span>
                                    <Dropdown.Menu
                                        variant=""
                                        id="style-5"
                                        className="cusDrupdown"
                                    >
                                        {dropDownLimits?.map((limit, index) => (
                                            <Dropdown.Item eventKey={limit} key={limit}>
                                                {limit}
                                            </Dropdown.Item>
                                        ))}

                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="search-container">
                                    <SearchBar name="Search" value={searchKeyword} setSearchKeyword={setSearchKeyword} onClick={() => {
                                        handleSearch()
                                        
                                    }} />
                                </span>

                            </div>



                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {tableHeaders?.map((heading, index) => (
                                                <th key={heading} className={tableHeaders.length - 1 === index ? "text-center" : ""}>
                                                    {heading}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <tr>
                                                <td colSpan={tableHeaders.length} className="text-center tableE_Caption">
                                                    <DataLoader />
                                                </td>

                                            </tr>
                                            :
                                            <>
                                                {quotes?.length > 0 ? quotes?.map((quote, index) => (
                                                  <tr key={quote._id}>
                                                    {/* <td>{(parseInt(limit * (pageNumber - 1) + index + 1) < 10) ? "0" + (limit * (pageNumber - 1) + index + 1) : (limit * (pageNumber - 1) + index + 1)}</td> */}
                                                        <td>
                                                            {index + 1 < 10 ? `0${index + 1}.` : `${index + 1}.`}
                                                        </td>
                                                        <td className="ellipsis-text">
                                                            {quote.quote ? quote.quote : "N/A"}
                                                        </td>
                                                        <td className={`font-weight-bold ${quote.status === "active" ? "activeclr" : "inactiveclr"}`}>
                                                            {quote.status}
                                                        </td>
                                                        <td className="text-center">
                                                            <button className="icon-button" title="View" onClick={() => { handleViewQuote(quote) }}><img src={IMAGES.view_icon} alt="" /></button>
                                                            <button className="icon-button" title="Edit" onClick={() => { handleEditQuote(quote) }}><img src={IMAGES.edit_icon} alt="" /></button>
                                                            {/* <button className="icon-button" title="Delete" onClick={() => { handleDeleteModal(focusArea) }}><img src={IMAGES.delete_icon} alt="Delete" /></button> */}

                                                        </td>

                                                    </tr>

                                                )) :
                                                    <tr>
                                                        <td colSpan={tableHeaders.length} className="text-center">
                                                            No focus area records found
                                                        </td>
                                                    </tr>

                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {/* <div className="quote-section">
                                <ul className="quoteList">
                                    {isLoading ?
                                        <li className="no-quotes">
                                            <DataLoader />
                                        </li>
                                        :
                                        <>
                                            {quotes?.length > 0 ?
                                                quotes?.map((quote, index) => (
                                                    <li key={`quote + ${index}`} onClick={() => { }}>
                                                        <span className="sno">
                                                            {index + 1 < 10 ? `0${index + 1}.` : `${index + 1}.`}
                                                        </span>
                                                        <textarea rows="2" type="text" className="form-control" value={quote?.quote} readOnly disabled></textarea>
                                                        <button type="button" onClick={() => { handleEditQuote(quote) }} >
                                                            <img src={IMAGES.edit_icon} alt="" />
                                                        </button>
                                                    </li>
                                                ))
                                                :
                                                <li className="no-quotes">
                                                    No Quote Record Found
                                                </li>
                                            }
                                        </>
                                    }

                                </ul>

                            </div> */}
                            <div className="table-pagination">
                                <div className="col-md-6 show_entrieNum">
                                    <p>Showing {count > 0 ? 1 : 0} to {limit} of {totalEntries} entries</p>
                                </div>
                                <div className="col-md-6 pagination">
                                    <PaginationView
                                        totalPages={totalPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {addModal &&
                <PopupModal className={"areaModal"} show={addModal} onHide={() => {
                    setAddModal(false);
                    AddQuoteFormik.resetForm();
                }} >
                    <form method="POST" onSubmit={AddQuoteFormik.handleSubmit}>
                        <h1>{"Add Quote"}</h1>
                        <div className="row">
                            <div className="form-group">
                                <label>Quote</label>
                                <textarea className="form-control" placeholder="Enter Quote" name="quote" onChange={AddQuoteFormik.handleChange} onBlur={AddQuoteFormik.handleBlur}
                                    value={AddQuoteFormik.values.quote} rows="5">
                                </textarea>

                                {AddQuoteFormik.touched.quote && AddQuoteFormik.errors.quote && (
                                    <ValidationError error={AddQuoteFormik.errors.quote} />
                                )}
                            </div>

                        </div>

                        <div className="row">
                            <div className="form-group">
                                <label>Author</label>
                                <input className="form-control" placeholder="Enter Author" name="author" onChange={AddQuoteFormik.handleChange} onBlur={AddQuoteFormik.handleBlur}
                                    value={AddQuoteFormik.values.author} rows="5" />
                                

                                {AddQuoteFormik.touched.author && AddQuoteFormik.errors.author && (
                                    <ValidationError error={AddQuoteFormik.errors.author} />
                                )}
                            </div>

                        </div>

                        <div className="row">
                            <div className="form-group">
                                <label>Source</label>
                                <input className="form-control" placeholder="Enter Source" name="source" onChange={AddQuoteFormik.handleChange} onBlur={AddQuoteFormik.handleBlur}
                                    value={AddQuoteFormik.values.source} rows="5" />
                                {AddQuoteFormik.touched.source && AddQuoteFormik.errors.source && (
                                    <ValidationError error={AddQuoteFormik.errors.source} />
                                )}
                            </div>

                        </div>

                        <div className="row">
                            <div className="form-group">
                                <label>Date</label>
                                <input className="form-control" type="date"  name="date" onChange={AddQuoteFormik.handleChange} onBlur={AddQuoteFormik.handleBlur}
                                    value={AddQuoteFormik.values.date} rows="5" />
                                {AddQuoteFormik.touched.date && AddQuoteFormik.errors.date && (
                                    <ValidationError error={AddQuoteFormik.errors.date} />
                                )}
                            </div>

                        </div>

                        <div className="footerBtnAll mt-3">
                            <button onClick={() => {
                                setAddModal(false);
                                AddQuoteFormik.resetForm();
                            }}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    t("Buttons.submit")
                                }
                            </button>

                        </div>
                    </form>
                </PopupModal>
            }

            {editModal &&
                <PopupModal className={"areaModal"} show={editModal} onHide={() => {
                    setEditModal(false);
                    EditQuoteFormik.resetForm();
                }}>
                    <form method="POST" onSubmit={EditQuoteFormik.handleSubmit}>
                        <h1>{"Edit Quote"}</h1>
                        <div className="row">
                            <div className="form-group">
                                <label>Quote</label>
                                <textarea className="form-control" placeholder="Enter Quote" name="quote" onChange={EditQuoteFormik.handleChange} onBlur={EditQuoteFormik.handleBlur}
                                    value={EditQuoteFormik.values.quote} rows="5">
                                </textarea>
                                {EditQuoteFormik.touched.quote && EditQuoteFormik.errors.quote && (
                                    <ValidationError error={EditQuoteFormik.errors.quote} />
                                )}
                            </div>

                        </div>
                        <div className="row">
                            <div className="form-group">
                                    <label>Author</label>
                                    <input className="form-control" placeholder="Enter Author" name="author" onChange={EditQuoteFormik.handleChange} onBlur={EditQuoteFormik.handleBlur}
                                        value={EditQuoteFormik.values.author} rows="5" />
            
                                    {EditQuoteFormik.touched.author && EditQuoteFormik.errors.author && (
                                        <ValidationError error={EditQuoteFormik.errors.author} />
                                    )}
                                </div>
                        </div>

                        <div className="row">
                            <div className="form-group">
                                    <label>Source</label>
                                    <input className="form-control" placeholder="Enter Source" name="source" onChange={EditQuoteFormik.handleChange} onBlur={EditQuoteFormik.handleBlur}
                                        value={EditQuoteFormik.values.source} rows="5" />
            
                                    {EditQuoteFormik.touched.source && EditQuoteFormik.errors.source && (
                                        <ValidationError error={EditQuoteFormik.errors.source} />
                                    )}
                                </div>
                        </div>

                        <div className="row">
                            <div className="form-group">
                                    <label>Date</label>
                                    <input className="form-control" type="date" name="date" onChange={EditQuoteFormik.handleChange} onBlur={EditQuoteFormik.handleBlur}
                                        value={EditQuoteFormik.values.date} rows="5" />
            
                                    {EditQuoteFormik.touched.date && EditQuoteFormik.errors.date && (
                                        <ValidationError error={EditQuoteFormik.errors.date} />
                                    )}
                                </div>
                        </div>
                        <div className="row">
                            <div className="form-group">
                                <label>Status</label>
                                <Dropdown drop className="dropdown" onSelect={(e) => {
                                    EditQuoteFormik.setFieldValue("status", e);
                                }} >
                                    <Dropdown.Toggle name="status" onChange={EditQuoteFormik.handleChange} onBlur={EditQuoteFormik.handleBlur}
                                        value={EditQuoteFormik.values.status}>
                                        <span>{EditQuoteFormik.values.status}</span>
                                        <img src={IMAGES.filter_dropdown_icon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="" className="cusDrupdown" >
                                        <Dropdown.Item eventKey={"active"} key={"active"}>
                                            Active
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey={"inactive"} key={"inactive"}>
                                            Inactive
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>

                        <div className="footerBtnAll mt-3">
                            <button onClick={() => {
                                setEditModal(false);
                                EditQuoteFormik.resetForm();
                            }}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    "Update"
                                }
                            </button>

                        </div>
                    </form>
                </PopupModal>
            }

            {viewModal &&
                <PopupModal className={"areaModal"} show={viewModal} onHide={() => {
                    setViewModal(false);
                    setSelectedQuote({});
                }} title>
                    <form>
                        <h1>{"View Q Details"}</h1>
                        <div className="row">
                            <div className="form-group">
                                <label>Quote</label>
                                <textarea className="form-control" name="quote" value={selectedQuote?.quote} readOnly disabled rows="5">
                                </textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group">
                                <label>Author</label>
                                <input className="form-control" name="author" value={selectedQuote?.author} readOnly disabled rows="5" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group">
                                <label>Source</label>
                                <input className="form-control" name="source" value={selectedQuote?.source} readOnly disabled rows="5" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group">
                                <label>Date</label>
                                <input className="form-control" type="date" name="date" value={selectedQuote?.date} readOnly disabled rows="5" />
                            </div>
                        </div>
                    </form>
                </PopupModal>
            }

        </>
    );
};
