import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import { ButtonLoader, HeaderView, SidebarPanel, ValidationError } from "../components";
import { doAuthChangePassword } from "../common/store/actions/authActions";
import { IMAGES } from "../static";

export const ChangePasswordView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [showBody, setShowBody] = useState(false);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const ChangePasswordFormik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required(t("Error.old_password_required")).min(6, t("Error.password_length")),
            newPassword: Yup.string().required(t("Error.password_required")).min(6, t("Error.password_length")),
            confirmNewPassword: Yup.string().min(6, t("Error.password_length")).oneOf([Yup.ref('newPassword')], t("Error.password_doesnt_match")).required(t("Error.confirm_password_required")),
        }),
        onSubmit: async (values) => {

            if (ChangePasswordFormik.isValid && ChangePasswordFormik.dirty) {
                setIsSubmitLoader(true);

                const params = {
                    oldPassword: values?.oldPassword,
                    newPassword: values.confirmNewPassword
                }
                dispatch(doAuthChangePassword(params)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message);
                        navigate("/");
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.response?.data?.message);
                    }
                });
            }
        },
    });

    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={"Change Password"}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

                    <div className="main-container">
                        <div className="mainCustomBG cusformbox">
                            <h1>Change Password</h1>
                            <form method="POST" onSubmit={ChangePasswordFormik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Old password</label>
                                            <div className="possiPassIcon">
                                                <input type={showOldPassword ? "text" : "password"} className="form-control" placeholder={t("ResetPassword.confirm_password_placeholder")}
                                                    name="oldPassword" value={ChangePasswordFormik?.values?.oldPassword} onChange={ChangePasswordFormik.handleChange}
                                                    onBlur={ChangePasswordFormik.handleBlur} autoComplete="false" />
                                                <img src={showOldPassword ? IMAGES.off_eye_icon : IMAGES.eye_icon} alt="" onClick={(e) => setShowOldPassword(!showOldPassword)} />
                                            </div>

                                            {ChangePasswordFormik.touched.oldPassword && ChangePasswordFormik.errors.oldPassword && (
                                                <ValidationError error={ChangePasswordFormik.errors.oldPassword} />
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>New password</label>

                                            <div className="possiPassIcon">
                                                <input type={showPassword ? "text" : "password"} className="form-control" placeholder={t("ResetPassword.new_password_placeholder")}
                                                    name="newPassword" value={ChangePasswordFormik?.values?.newPassword} onChange={ChangePasswordFormik.handleChange}
                                                    onBlur={ChangePasswordFormik.handleBlur} autoComplete="false" />
                                                <img src={showPassword ? IMAGES.off_eye_icon : IMAGES.eye_icon} alt="" onClick={(e) => setShowPassword(!showPassword)} />
                                            </div>

                                            {ChangePasswordFormik.touched.newPassword && ChangePasswordFormik.errors.newPassword && (
                                                <ValidationError error={ChangePasswordFormik.errors.newPassword} />
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm new password</label>

                                            <div className="possiPassIcon">
                                                <input type={showConfirmPassword ? "text" : "password"} className="form-control" placeholder={t("ResetPassword.confirm_password_placeholder")}
                                                    name="confirmNewPassword" value={ChangePasswordFormik?.values?.confirmNewPassword} onChange={ChangePasswordFormik.handleChange}
                                                    onBlur={ChangePasswordFormik.handleBlur} autoComplete="false" />
                                                <img src={showConfirmPassword ? IMAGES.off_eye_icon : IMAGES.eye_icon} alt="" onClick={(e) => setShowConfirmPassword(!showConfirmPassword)} />
                                            </div>

                                            {ChangePasswordFormik.touched.confirmNewPassword && ChangePasswordFormik.errors.confirmNewPassword && (
                                                <ValidationError error={ChangePasswordFormik.errors.confirmNewPassword} />
                                            )}
                                        </div>
                                        <div className="footer-buttons">
                                            <div className="form-group">
                                                <button type="submit" disabled={isSubmitLoader}>
                                                    {isSubmitLoader ?
                                                        <span className="d-flex justify-content-between">
                                                            <ButtonLoader />
                                                            {t("Loading.please_wait")}
                                                        </span>
                                                        :
                                                        t("Buttons.change_password")
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <h2>Password must contain:</h2>
                                        <ul>
                                            <li><img src={IMAGES.right_Tick} alt="" /> Minimum 6 characters</li>
                                            <li><img src={IMAGES.right_Tick} alt="" /> 1 capital letter</li>
                                            <li><img src={IMAGES.right_Tick} alt="" /> 1 lowercase latter</li>
                                            <li><img src={IMAGES.right_Tick} alt="" /> 1 Special Character</li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
