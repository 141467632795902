import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { ButtonLoader, DataLoader, HeaderView, PopupModal, SearchBar, SidebarPanel, ValidationError } from "../components";
import { IMAGES } from "../static";
import { PaginationView } from "../common";
import { dropDownLimits } from "../utils/helperFunctions";
import { addActionArea, deleteActionArea, editActionArea, getActionAreaList, getActionAreaListById, getSelectedActionArea } from "../common/store/actions/actionAreaActions";

export const ActionAreaView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { focusAreaId } = useParams();
    const actionAreaList = useSelector(({ actionArea }) => actionArea?.actionAreaList);
    const selectedFocusArea = useSelector(({ focusArea }) => focusArea?.selectedFocusArea);

    const [showBody, setShowBody] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    // loader states
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const tableHeaders = ["#", t('TableHeader.title'), t('TableHeader.description'), t('TableHeader.help_text'), t('TableHeader.created_by'), t('TableHeader.status'), t('TableHeader.actions'),];

    const [limit, setLimit] = useState(dropDownLimits[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searched, setSearched] = useState(false);
    const [selectedActionArea, setSelectedActionArea] = useState({})

    const handleselectedActionArea = (actionAreaDetails) => {
        setSelectedActionArea(actionAreaDetails);
        EditActionAreaFormik.setValues({
            title: actionAreaDetails.title,
            status: actionAreaDetails.status,
            description: actionAreaDetails?.description,
            helpText: actionAreaDetails?.helpText
        });
        setEditModal(true);
    }

    const handleViewActionArea = (actionArea) => {
        setSelectedActionArea(actionArea);
        dispatch(getSelectedActionArea(actionArea));
        setViewModal(true);
        // dispatch(getActionAreaListById(10, 1, null, focusArea?._id)).then((res) => {
        //   navigate("/action-area-management");
        // })
    }

    const AddActionAreaFormik = useFormik({
        initialValues: {
            focusAreaId: selectedFocusArea?._id,
            title: "",
            status: "active",
            description: '',
            helpText: ''
        },
        validationSchema: Yup.object({
            focusAreaId: Yup.string().required("Action area is required!"),
            title: Yup.string().required("Title is required!"),
            status: Yup.string().required("Status is required!"),
            description: Yup.string(),
            helpText: Yup.string()
        }),
        onSubmit: async (values) => {
            if (AddActionAreaFormik.isValid && AddActionAreaFormik.dirty) {
                setIsSubmitLoader(true);
                dispatch(addActionArea(values)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        setIsLoading(true);
                        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                        fetchActionArea();
                        setAddModal(false);
                        AddActionAreaFormik.resetForm();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                        setAddModal(false);
                    }
                })
            }
        },
    })

    const EditActionAreaFormik = useFormik({
        initialValues: {
            // abundanceAreaId: "",
            title: "",
            status: "active",
            description: '',
            helpText: ''
        },
        validationSchema: Yup.object({
            // abundanceAreaId: Yup.string().required("Abundance area is required!"),
            title: Yup.string().required("Title is required!"),
            status: Yup.string().required("Status is required"),
            description: Yup.string(),
            helpText: Yup.string()
        }),
        onSubmit: async (values) => {
            if (EditActionAreaFormik.isValid) {
                setIsSubmitLoader(true);
                const params = {
                    ...values,
                    id: selectedActionArea?._id
                }
                dispatch(editActionArea(params)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        setIsLoading(true);
                        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                        fetchActionArea();
                        setEditModal(false);
                        EditActionAreaFormik.resetForm();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                        setEditModal(false);
                    }
                })
            }
        },
    })

    const fetchActionArea = (search = null) => {
        setIsLoading(true);
        dispatch(focusAreaId ? getActionAreaListById(limit, pageNumber, search, focusAreaId) : getActionAreaList(limit, pageNumber, search)).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                // toastr.error("Error", res?.data?.message);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                const { limit, totalItems, totalPages } = res?.data;
                setCount(limit ? limit : 0);
                setTotalEntries(totalItems ? totalItems : 0);
                setTotalPages(totalPages ? totalPages : 0);
            }
        })
    }

    const handleDeleteModal = (actionAreaDetails) => {
        setSelectedActionArea(actionAreaDetails);
        setDeleteModal(true);
    }

    const handleDeleteActionArea = () => {
        setIsSubmitLoader(true);
        dispatch(deleteActionArea(selectedActionArea?._id)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
                setIsLoading(true);
                setIsSubmitLoader(false);
                toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                fetchActionArea();
                setDeleteModal(false);
                setSelectedActionArea("");
            }
            else {
                setIsSubmitLoader(false);
                toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                setDeleteModal(false);
                setSelectedActionArea("");
            }
        })
    }

    const handleCloseAddModal = () => {
        setAddModal(false);
        AddActionAreaFormik.resetForm();
    }

    const handleCloseEditModal = () => {
        setEditModal(false);
        EditActionAreaFormik.resetForm();
        setSelectedActionArea("");
    }

    const handleCloseViewModal = () => {
        setViewModal(false);
        setSelectedActionArea("");
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setSelectedActionArea("");
    }

    const handleSearch = () => {
        fetchActionArea(searchKeyword);
        setSearched(true);
    };
    

    useEffect(() => {
        fetchActionArea();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, limit, dispatch])

    useEffect(() => {
        if (searchKeyword.length === 0 && searched) {
            fetchActionArea();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword])

    return (
        <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
            <HeaderView
                headingName={"Action Area"}
                setShowBody={setShowBody}
                showBody={showBody}
            />
            <div className="wrapper">
                <SidebarPanel setShowBody={setShowBody} showBody={showBody} />
                <div className="main-container">
                    <div className="betcreBx">
                        <ul>
                            <li><button onClick={() => navigate("/dashboard")}>Dashboard</button></li>
                            <li>/</li>
                            <li><button onClick={() => navigate("/abundance-area-management")}>Abundance Area</button></li>
                            <li>/</li>
                            <li><button onClick={() => navigate(`/focus-area-management/${selectedFocusArea?.abundanceAreaId?._id}`)}>Focus Area</button></li>
                            <li>/</li>
                            <li className="active">Action Area</li>
                        </ul>
                    </div>
                    <div className="table-container mainCustomBG userDetail-container">
                        <div className="main-heading">
                            <h1>
                                {focusAreaId && `Focus Area: ${selectedFocusArea?.title}`}
                                &nbsp;
                                {focusAreaId &&
                                    (
                                        <span className="d-flex"><button type="button" className="button-back mr-2" onClick={() => { setAddModal(true) }}>
                                            <img src={IMAGES.plus_icon} alt="" />
                                            {t("Buttons.add")}
                                        </button>

                                            <button type="button" className="button-back" onClick={() => { navigate(focusAreaId ? `/focus-area-management/${selectedFocusArea?.abundanceAreaId?._id}` : "/dashboard") }}><img src={IMAGES.rightErrow} alt="" />
                                                {t('Buttons.back')}
                                            </button>
                                        </span>
                                    )
                                }
                            </h1>
                        </div>

                        <div className="headingTbL filterResponsiveCus filterMain">
                            <Dropdown drop className="dropdown filtstatus" onSelect={(e) => {
                                setLimit(e);
                            }}>
                                <span className="sortingShow">{t('Filterbar.show')}
                                    <Dropdown.Toggle>
                                        <span>{limit}</span>
                                        <img src={IMAGES.filter_dropdown_icon} alt="" />
                                    </Dropdown.Toggle>
                                    {t('Filterbar.enteries')}
                                </span>
                                <Dropdown.Menu
                                    variant=""
                                    id="style-5"
                                    className="cusDrupdown"
                                >
                                    {dropDownLimits?.map((limit, index) => (
                                        <Dropdown.Item eventKey={limit} key={`limit+${index}`}>
                                            {limit}
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>

                            <span className="search-container">
                                <SearchBar name="Search" value={searchKeyword} setSearchKeyword={setSearchKeyword} onClick={() => {
                                    handleSearch()
                                }} />
                            </span>

                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {tableHeaders?.map((heading, index) => (
                                            <th key={heading} className={tableHeaders.length - 1 === index ? "" : ""}>
                                                {heading}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <tr>
                                            <td colSpan={tableHeaders.length} className="text-center tableE_Caption">
                                                <DataLoader />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {actionAreaList?.length > 0 ? actionAreaList?.map((actionArea, index) => (
                                                <tr key={actionArea._id}>
                                                    <td>{(parseInt(limit * (pageNumber - 1) + index + 1) < 10) ? "0" + (limit * (pageNumber - 1) + index + 1) : (limit * (pageNumber - 1) + index + 1)}</td>
                                                    <td className="highLight-text">
                                                        {actionArea.title ? actionArea.title : "N/A"}
                                                    </td>
                                                    <td className="ellipsis-text">
                                                        {actionArea.description
                                                            ? actionArea.description
                                                            : "N/A"}
                                                    </td>
                                                    <td className="ellipsis-text">
                                                        {actionArea.helpText
                                                            ? actionArea.helpText
                                                            : "N/A"}
                                                    </td>
                                                    <td>
                                                        {actionArea.userId
                                                            ? actionArea.userId?.userName ? actionArea.userId?.userName : actionArea?.type : actionArea?.type}
                                                    </td>
                                                    <td className={`font-weight-bold ${actionArea.status === "active" ? "activeclr" : "inactiveclr"}`}>
                                                        {actionArea.status}
                                                    </td>
                                                    <td className="">
                                                        <button className="icon-button" title="View" onClick={() => { handleViewActionArea(actionArea) }}><img src={IMAGES.view_icon} alt="" /></button>
                                                        <button className="icon-button" title="Edit" onClick={() => { handleselectedActionArea(actionArea) }}><img src={IMAGES.edit_icon} alt="" /></button>
                                                        <button className="icon-button" title="Delete" onClick={() => { handleDeleteModal(actionArea) }}><img src={IMAGES.delete_icon} alt="Delete" /></button>
                                                        {/* <button><img src={IMAGES.rightTickImg} alt="" /></button> */}
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan={tableHeaders.length} className="text-center">
                                                        {t('NotFound.action_record')}
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="table-pagination">
                            <div className="col-md-6 show_entrieNum">
                                <p>
                                    {t('Pagination.showing')} {count > 0 ? 1 : 0} {t('Pagination.to')} {limit} {t('Pagination.of')} {totalEntries} {t('Pagination.entries')}
                                </p>
                            </div>
                            <div className="col-md-6 pagination">
                                <PaginationView
                                    totalPages={totalPages}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {addModal &&
                <PopupModal className={"areaModal"} show={addModal} onHide={() => { handleCloseAddModal() }}>
                    <form method="POST" onSubmit={AddActionAreaFormik.handleSubmit}>
                        <h1>{t('ModalTitles.add_action_area')}</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Action.title_label')} <span>*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter title" name="title"
                                        onChange={AddActionAreaFormik.handleChange} onBlur={AddActionAreaFormik.handleBlur}
                                        value={AddActionAreaFormik.values.title} />
                                    {AddActionAreaFormik.touched.title && AddActionAreaFormik.errors.title && (
                                        <ValidationError error={AddActionAreaFormik.errors.title} />
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Action.status_label')} </label>
                                    <Dropdown drop className="dropdown" onSelect={(e) => {
                                        AddActionAreaFormik.setFieldValue("status", e);
                                    }} >
                                        <Dropdown.Toggle disabled name="slotId" onChange={AddActionAreaFormik.handleChange} onBlur={AddActionAreaFormik.handleBlur}
                                            value={AddActionAreaFormik.values.slotId}>
                                            <span>{AddActionAreaFormik.values.status}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu variant="" className="cusDrupdown" >
                                            <Dropdown.Item eventKey={"active"} key={"active"}>
                                                Active
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey={"retrieve"} key={"retrieve"}>
                                                Retrieve
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {AddActionAreaFormik.touched.status && AddActionAreaFormik.errors.status && (
                                        <ValidationError error={AddActionAreaFormik.errors.status} />
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.description_label')}</label>
                                    <textarea className="form-control" name="description" rows={4} value={AddActionAreaFormik.values.description}
                                        onChange={AddActionAreaFormik.handleChange} onBlur={AddActionAreaFormik.handleBlur}>

                                    </textarea>
                                    {AddActionAreaFormik.touched.description &&
                                        AddActionAreaFormik.errors.description && (
                                            <ValidationError
                                                error={AddActionAreaFormik.errors.description}
                                            />
                                        )}
                                </div>

                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.help_text_label')}</label>
                                    <textarea className="form-control" name="helpText" rows={3} value={AddActionAreaFormik.values.helpText}
                                        onChange={AddActionAreaFormik.handleChange} onBlur={AddActionAreaFormik.handleBlur}>

                                    </textarea>
                                    {AddActionAreaFormik.touched.helpText &&
                                        AddActionAreaFormik.errors.helpText && (
                                            <ValidationError
                                                error={AddActionAreaFormik.errors.helpText}
                                            />
                                        )}
                                </div>

                            </div>
                        </div>

                        <div className="footerBtnAll mt-3">
                            <button onClick={() => handleCloseAddModal()}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    t("Buttons.submit")
                                }
                            </button>

                        </div>
                    </form>
                </PopupModal>
            }

            {editModal &&
                <PopupModal className={"areaModal"} show={editModal} onHide={() => { handleCloseEditModal() }}>
                    <form method="POST" onSubmit={EditActionAreaFormik.handleSubmit}>
                        <h1>{t('ModalTitles.edit_action_area')}</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Action.title_label')} <span>*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter title" name="title"
                                        onChange={EditActionAreaFormik.handleChange} onBlur={EditActionAreaFormik.handleBlur}
                                        value={EditActionAreaFormik.values.title} />
                                    {EditActionAreaFormik.touched.title && EditActionAreaFormik.errors.title && (
                                        <ValidationError error={EditActionAreaFormik.errors.title} />
                                    )}

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Action.status_label')} </label>
                                    <Dropdown drop className="dropdown" onSelect={(e) => {
                                        EditActionAreaFormik.setFieldValue("status", e);
                                    }} >
                                        <Dropdown.Toggle disabled name="status" onChange={EditActionAreaFormik.handleChange} onBlur={EditActionAreaFormik.handleBlur}
                                            value={EditActionAreaFormik.values.status}>
                                            <span>{EditActionAreaFormik.values.status}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu variant="" className="cusDrupdown" >
                                            <Dropdown.Item eventKey={"active"} key={"active"}>
                                                Active
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey={"retrieve"} key={"retrieve"}>
                                                Retrieve
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {EditActionAreaFormik.touched.status && EditActionAreaFormik.errors.status && (
                                        <ValidationError error={EditActionAreaFormik.errors.status} />
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.description_label')}</label>
                                    <textarea className="form-control" name="description" rows={4} value={EditActionAreaFormik.values.description}
                                        onChange={EditActionAreaFormik.handleChange} onBlur={EditActionAreaFormik.handleBlur}>

                                    </textarea>
                                    {EditActionAreaFormik.touched.description &&
                                        EditActionAreaFormik.errors.description && (
                                            <ValidationError
                                                error={EditActionAreaFormik.errors.description}
                                            />
                                        )}
                                </div>

                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.help_text_label')}</label>
                                    <textarea className="form-control" name="helpText" rows={3} value={EditActionAreaFormik.values.helpText}
                                        onChange={EditActionAreaFormik.handleChange} onBlur={EditActionAreaFormik.handleBlur}>
                                    </textarea>
                                    {EditActionAreaFormik.touched.helpText &&
                                        EditActionAreaFormik.errors.helpText && (
                                            <ValidationError
                                                error={EditActionAreaFormik.errors.helpText}
                                            />
                                        )}
                                </div>

                            </div>

                        </div>
                        <div className="footerBtnAll mt-3">
                            <button onClick={() => handleCloseEditModal()}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    "Update"
                                }
                            </button>

                        </div>
                    </form>
                </PopupModal>
            }

            {viewModal && (
                <PopupModal
                    className={"areaModal"}
                    show={viewModal}
                    onHide={() => {
                        handleCloseViewModal()
                    }}
                >
                    <form method="POST">
                        <h1>{t('ModalTitles.view_action_area')}</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>
                                        {t('Action.title_label')} <span>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter title"
                                        name="title"
                                        value={selectedActionArea?.title}
                                        readOnly disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Action.status_label')} </label>
                                    <Dropdown drop className="dropdown">
                                        <Dropdown.Toggle readOnly disabled name="slotId" value={selectedActionArea?.status} >
                                            <span>{selectedActionArea?.status}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.description_label')}</label>
                                    <textarea className="form-control" name="description" rows={4} value={selectedActionArea.description} readOnly disabled>
                                    </textarea>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.help_text_label')}</label>
                                    <textarea className="form-control" name="helpText" rows={3} value={selectedActionArea.helpText} readOnly disabled>
                                    </textarea>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Action.created_by')}</label>
                                    <input type="text" className="form-control text-capitalize" name="userId" value={selectedActionArea.userId ? selectedActionArea.userId?.userName : selectedActionArea?.type} readOnly disabled />
                                </div>
                            </div>
                        </div>
                    </form>
                </PopupModal>
            )}

            {deleteModal && (
                <PopupModal
                    show={deleteModal}
                    onHide={() => {
                        handleCloseDeleteModal()
                    }}
                >
                    <form>
                        <img src={IMAGES.deleteAccountIcon} alt="" />
                        <p className="praregraphP width50">
                            {t('Confirmation.delete_action_area')}
                        </p>
                        <div className="footerBtnAll">
                            <button
                                onClick={() => {
                                    handleCloseDeleteModal()
                                }}
                                className="cancelborder"
                            >
                                {t("Buttons.cancel")}
                            </button>
                            <button
                                type="button"
                                disabled={isSubmitLoader}
                                onClick={() => {
                                    handleDeleteActionArea();
                                }}
                            >
                                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.confirm")}
                            </button>
                        </div>
                    </form>
                </PopupModal>
            )}
        </div>
    )
}
