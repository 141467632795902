import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HeaderView, SidebarPanel } from "../components";
import { getDashboardData } from "../common/store/actions/authActions";
import { IMAGES } from "../static";
import { statusList } from "../utils/helperFunctions";
import { journeyStatus } from "../common/store/actions/journeyActions";

export const DashboardView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dashboardInfo = useSelector(({ auth }) => auth.dashboardData);

  const [showBody, setShowBody] = useState(false);

  const tabList = [
    {
      tabName: t("DashboardTabs.users"),
      icon: IMAGES.dash_user,
      count:
        dashboardInfo?.userCount <= 10
          ? "0" + dashboardInfo?.userCount
          : dashboardInfo?.userCount || "00",
      location: "/users",
    },
    {
      tabName: t("DashboardTabs.total_journeys"),
      icon: IMAGES.dash_journeys,
      count: dashboardInfo?.journeyTotalCount <= 10
        ? "0" + dashboardInfo?.journeyTotalCount
        : dashboardInfo?.journeyTotalCount || "00",
      location: "/journeys",
      className: "totalJourneys",
      // journeyActiveCount
    },
    {
      tabName: t("DashboardTabs.total_team"),
      icon: IMAGES.dash_team,
      count: dashboardInfo?.teamCount <= 10
        ? "0" + dashboardInfo?.teamCount
        : dashboardInfo?.teamCount || "00",
      location: "/teams",
      className: "totalTeam",
    },
    {
      tabName: t("DashboardTabs.active_journeys"),
      icon: IMAGES.dash_active,
      count: dashboardInfo?.journeyActiveCount <= 10
        ? "0" + dashboardInfo?.journeyActiveCount
        : dashboardInfo?.journeyActiveCount || "00",
      location: "/journeys",
      className: "activeJourneys",
      status: statusList[0],
    },
    {
      tabName: t("DashboardTabs.paused_journeys"),
      icon: IMAGES.dash_paused,
      count: dashboardInfo?.journeyPausedCount <= 10
        ? "0" + dashboardInfo?.journeyPausedCount
        : dashboardInfo?.journeyPausedCount || "00",
      location: "/journeys",
      className: "pausedJourneys",
      status: statusList[1],
    },
    {
      tabName: t("DashboardTabs.completed_journeys"),
      icon: IMAGES.dash_completed,
      count: dashboardInfo?.journeyCompleteCount <= 10
        ? "0" + dashboardInfo?.journeyCompleteCount
        : dashboardInfo?.journeyCompleteCount || "00",
      location: "/journeys",
      className: "completedJourneys",
      status: statusList[2],
    },
    {
      tabName: t("DashboardTabs.archived_journeys"),
      icon: IMAGES.dash_trashed,
      count: dashboardInfo?.journeyArchiveCount <= 10
        ? "0" + dashboardInfo?.journeyArchiveCount
        : dashboardInfo?.journeyArchiveCount || "00",
      location: "/journeys",
      className: "trashedJourneys",
      status: statusList[3]
    },
  ];

  useEffect(() => {
    dispatch(getDashboardData());
  }, [dispatch]);

  return (
    <>
      <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView
          headingName={t("Sidebar.dashboard")}
          setShowBody={setShowBody}
          showBody={showBody}
        />

        <div className="wrapper">
          <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

          <div className="main-container">
            <div className="dashboard-tabs">
              <div className="row">
                {tabList?.length &&
                  tabList?.map((tab) => (
                    <div className="col-lg-4 col-md-3" key={tab.tabName}>
                      <div
                        className="dashboard-tab-content"
                        onClick={() => {
                          navigate(tab.location);
                          if (tab?.status) {
                            dispatch(journeyStatus(tab?.status))
                          }
                          else {
                            dispatch(journeyStatus(""))
                          }

                        }}
                      >
                        <p>
                          {tab.tabName}{" "}
                          <span className={tab.className ? tab.className : ""}>
                            <img src={tab.icon} alt="" />
                          </span>
                        </p>
                        <h1>
                          <span>{tab.count}</span>
                        </h1>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
