import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { IMAGES } from "../static";
import { doAuthLogin } from "../common/store/actions/authActions";
import { ButtonLoader, ValidationError } from "../components";


export const LoginView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // for translation of static text
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    // loader states
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const LoginFormik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t("Error.valid_email")).required(t("Error.email_required")),
            password: Yup.string().required(t("Error.password_required")).min(6, t("Error.password_length")),
        }),
        onSubmit: async (values) => {
            if (LoginFormik.isValid && LoginFormik.dirty) {
                setIsSubmitLoader(true);

                dispatch(doAuthLogin(values)).then((res) => {

                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        navigate("/dashboard");
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message);
                    }
                })
            }
        },
    });

    return (
        <>
            <div className="cover-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="align-self-center d-flex form-section">
                                <div className="effect-box">
                                    <div className="logo_section">
                                        <img src={IMAGES.logo} alt="" />
                                    </div>
                                    <div className="cover-box-txt">
                                        <h1>Login </h1>
                                        <div className="form-card">
                                            <form method="POST" onSubmit={LoginFormik.handleSubmit}>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="text" className="form-control" placeholder={t("Login.email_placholder")} name="email" value={LoginFormik?.values?.email}
                                                        onChange={LoginFormik.handleChange} onBlur={LoginFormik.handleBlur} autoComplete="false" />

                                                    {LoginFormik.touched.email && LoginFormik.errors.email && (
                                                        <ValidationError error={LoginFormik.errors.email} />
                                                    )}

                                                </div>

                                                <div className="form-group">
                                                    <label>Password</label>

                                                    <div className="possiPassIcon">

                                                        <input type={showPassword ? "text" : "password"} className="form-control" placeholder={t("Login.password_placeholder")}
                                                            name="password" value={LoginFormik?.values?.password} onChange={LoginFormik.handleChange} onBlur={LoginFormik.handleBlur} autoComplete="false" />

                                                        <img src={showPassword ? IMAGES.off_eye_icon : IMAGES.eye_icon} alt="" onClick={(e) => setShowPassword(!showPassword)} />
                                                    </div>

                                                    {LoginFormik.touched.password && LoginFormik.errors.password && (
                                                        <ValidationError error={LoginFormik.errors.password} />
                                                    )}

                                                </div>

                                                <div className="form-group">
                                                    <div className="forg-box">
                                                        <button type="button" onClick={() => navigate("/forgot-password")}>{t("Buttons.forgot_password")}?</button>
                                                    </div>
                                                </div>

                                                <div className="footer-buttons">
                                                    <div className="form-group">
                                                        <button type="submit" disabled={isSubmitLoader} >
                                                            {isSubmitLoader ?
                                                                <span className="d-flex justify-content-between">
                                                                    <ButtonLoader />
                                                                    {t("Loading.please_wait")}
                                                                </span>
                                                                :
                                                                t("Buttons.login")
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="footer_bottom">
                                                    {/* <p><button type="button" onClick={() => { navigate("/terms-and-conditions") }}>Terms of Service</button> and <button type="button" onClick={() => { navigate("/privacy-policy") }}>Privacy Policy</button></p> */}
                                                    <p>Version:  1.0.0</p>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="rightAuthbx">
                                <img src={IMAGES.login_Auth_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
