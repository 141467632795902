import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { IMAGES } from "../static";
import { doAuthLogout, getLinks } from "../common/store/actions/authActions";
import { PopupModal } from "./modals";
import { ButtonLoader } from "./loaders";

export const HeaderView = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginUser = useSelector(({ auth }) => auth.loginUser?.user);

  const { headingName } = props;

  const [show, setShow] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  // loader states
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  // loader states

  const handleLogout = () => {
    setIsSubmitLoader(true);
    dispatch(doAuthLogout()).then((res) => {
      if (res?.status === 200 && res?.data?.success) {
        setIsSubmitLoader(false);
        toastr.success("Success", "Logged out successfully");
        navigate("/");
      } else {
        setIsSubmitLoader(false);
      }
    });
  };

  const headerMenuList = [
    // {
    //   option: "Your Profile",
    //   // location: "/profile",
    //   icon: IMAGES.user_icon,
    //   icon_Active: IMAGES.user_iconActive,
    // },
    // {
    //   option: "Account Settings",
    //   // location: "/profile",
    //   icon: IMAGES.setting_icon,
    //   icon_Active: IMAGES.setting_iconActive,
    // },
    {
      option: t("Header.change_password"),
      location: "/change-password",
      icon: IMAGES.key_Icon,
      icon_Active: IMAGES.key_IconActive,
    },
  ];

  useEffect(() => {
    dispatch(getLinks());
  }, [dispatch]);

  return (
    <>
      <div className="header_dash">
        <div className="">
          <nav className="navbar navbar-expand-md navbar-dark d-flex justify-content-between">
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="AdminNameHead">
                <h2>{headingName}</h2>
              </div>
              <ul className="navbar-nav ml-auto">
                <li
                  className="nav-item dropdown"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <button
                    className={
                      show
                        ? "nav-link profile-drop dropdown-toggle active"
                        : "nav-link profile-drop dropdown-toggle"
                    }
                    aria-expanded="false"
                  >
                    <img
                      src={IMAGES.profile_icon}
                      className="userIconimg"
                      alt=""
                    />
                    <span className="profile_NameOverf">
                      {loginUser?.fullName}
                      <small>{loginUser?.email}</small>
                    </span>
                    <img
                      src={IMAGES.dropdown_img}
                      className="right-down"
                      alt=""
                    />
                  </button>
                  <div
                    className={
                      show ? "dropdown-menu dropdownOpen" : "dropdown-menu"
                    }
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {headerMenuList.map((menu) => (
                      <button
                        className={
                          menu?.location ? "dropdown-item" : "dropdown-item "
                        }
                        key={menu.option}
                        onClick={() => {
                          navigate(menu.location);
                        }}
                      >
                        {/* {menu.location} */}
                        <img src={menu.icon} alt="" className="iconDrop" />
                        <img
                          src={menu.icon_Active}
                          alt=""
                          className="iconDropActive"
                        />
                        {menu.option}
                      </button>
                    ))}
                    {/* <button className="dropdown-item cursor-no-drop">
                                            {t("Header.profile")}
                                        </button> */}
                    {/* <button className="dropdown-item cursor-no-drop">
                                            {t("Header.change_password")}
                                        </button> */}
                    <hr />
                    <button
                      className="dropdown-item dropdown-item-no"
                      onClick={() => setLogoutModal(true)}
                    >
                      <img src={IMAGES.logout_icon} alt="" />
                      {t("Header.logout")}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      {logoutModal && (
        <PopupModal
          show={logoutModal}
          onHide={() => {
            setLogoutModal(false);
          }}
        // title={t("Header.logout")}
        >
          <form>
            <img src={IMAGES.logoutModal} alt="" />
            <p className="praregraphP width50">{t("Header.logout_text")}</p>
            <div className="footerBtnAll">
              <button
                onClick={() => setLogoutModal(false)}
                className="cancelborder"
              >
                {t("Buttons.cancel")}
              </button>
              <button
                type="button"
                onClick={() => {
                  handleLogout();
                }}
                disabled={isSubmitLoader}
              >
                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.confirm")}
              </button>
            </div>
          </form>
        </PopupModal>
      )}
    </>
  );
};
