export const IMAGES = {
  logo: require("./logo.png"),
  logo_Mark: require("./markBrand.png"),
  off_eye_icon: require("./off-eye-icon.png"),
  eye_icon: require("./eye-icon.png"),
  toggle_icon: require("./toggle-icon.png"),
  dropdown_icon: require("./dropdown.png"),
  dropdown_img: require("./dropdown_icon.png"),
  profile_icon: require("./profile-icon.png"),
  logout_icon: require("./logout-icon.png"),
  dashboard_icon: require("./dashboard-icon.png"),
  dashboard_iconActive: require("./dashboard_active.png"),
  user_icon: require("./user-icon.png"),
  user_iconActive: require("./user_active.png"),
  journey_icon: require("./journey_icon.png"),
  journey_iconActive: require("./journey_active.png"),
  link_icon: require("./link-icon.png"),
  link_iconActive: require("./link_active.png"),
  setting_icon: require("./setting-icon.png"),
  setting_iconActive: require("./setting_active.png"),
  view_icon: require("./view.png"),
  close_modal_icon: require("./close-modal-button.png"),
  filter_dropdown_icon: require("./dropdown_icon.png"),
  edit_icon: require("./edit_icon.png"),
  delete_icon: require("./delete_icon.png"),
  login_Auth_img: require("./loginBg.png"),
  back_Shape: require("./back_Shape.png"),
  back_ShapeRight: require("./back_ShapeIcon.png"),
  right_Tick: require("./right_tick.png"),
  dash_user: require("./total_user.png"),
  dash_journeys: require("./total_journeys.png"),
  dash_team: require("./total_team.png"),
  dash_active: require("./active_journeys.png"),
  dash_paused: require("./paused_journeys.png"),
  dash_completed: require("./completed-journeys.png"),
  dash_trashed: require("./trashed_journeys.png"),
  key_Icon: require("./key_icon.png"),
  key_IconActive: require("./key_active.png"),
  searchingIcon: require("./searchIcon.png"),
  rightErrow: require("./right-errow.png"),
  LinkInputIcon: require("./link-input-Icon.png"),
  profile_bg_icon:require("./profile-bg.png"),
  profile_edit_icon:require("./profile_edit_icon.png"),
  plus_icon:require("./plus_icon.png"),
  logoutModal:require("./logoutModal.png"),
  disebleModal:require("./disebleModal.png"),
  enableModal:require("./enableModal.png"),
  notfoundImg:require("./notfoundImg.png"),
  rightTickImg:require("./rightTick.png"),
  deleteAccountIcon:require("./delete_account_icon.png"),
  log_icon:require("./log.png"),
  log_iconActive:require("./log.png")
};
