export const authActions = {
  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_SEND_OTP: "AUTH_SEND_OTP",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_LINKS: "GET_LINKS",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  // SET_ACTIVE_INDEX: "SET_ACTIVE_INDEX",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const userActions = {
  GET_USER_LIST: "GET_USER_LIST",
  GET_USER_LOGS:"GET_USER_LOGS",
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_SELECTED_USER: "GET_SELECTED_USER",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const journeyActions = {
  GET_JOURNEY_LIST: "GET_JOURNEY_LIST",
  GET_SELECTED_JOURNEY: "GET_SELECTED_JOURNEY",
  GET_JOURNEY_STATUS: "GET_JOURNEY_STATUS",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const focusAreaActions = {
  GET_FOCUS_AREA_LIST: "GET_FOCUS_AREA_LIST",
  SELECTED_FOCUS_AREA: "SELECTED_FOCUS_AREA",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const actionAreaActions = {
  GET_ACTION_AREA_LIST: "GET_ACTION_AREA_LIST",
  SELECTED_ACTION_AREA: "SELECTED_ACTION_AREA",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const abundanceAreaActions = {
  GET_ABUNDANCE_AREA_LIST: "GET_ABUNDANCE_AREA_LIST",
  GET_ALL_ABUNDANCE_AREA: "GET_ALL_ABUNDANCE_AREA",
  SELECTED_ABUNDANCE_AREA: "SELECTED_ABUNDANCE_AREA",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const teamActions = {
  GET_TEAMS: "GET_TEAMS",
  GET_SELECTED_TEAM: "GET_SELECTED_TEAM",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const quoteActions = {
  GET_SCREENS_AREAS: "GET_SCREENS_AREAS",
  GET_QUOTES: "GET_QUOTES",
  GET_SELECTED_SCREEN: "GET_SELECTED_SCREEN",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const instructionActions = {
  GET_INSTRUCTION_SCREENS_AREAS: "GET_INSTRUCTION_SCREENS_AREAS",
  GET_INSTRUCTIONS: "GET_INSTRUCTIONS",
  GET_SELECTED_INSTRUCTION_AREA: "GET_SELECTED_INSTRUCTION_AREA",
  AUTH_LOGOUT: "AUTH_LOGOUT",
};

export const credentialsActions = {
  GET_CREDENTIALS: "GET_CREDENTIALS",
};
