import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HeaderView, SidebarPanel } from "../components";
import { getFormatedJourneyDate } from "../utils/helperFunctions";
import { IMAGES } from "../static";

export const JourneyDetailsView = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const journeyInfo = useSelector(({ journeys }) => journeys?.selectedJourney);

    const [showBody, setShowBody] = useState(false);

    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={"Journey Details"}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

                    <div className="main-container">

                        <div className="mainCustomBG userDetail-container">
                            <h1>{t('JourneyDetails.journey_details')}
                                <button type="button" className="button-back" onClick={() => { navigate(-1) }}>
                                    <img src={IMAGES.rightErrow} alt="" />
                                    {t('Buttons.back')}
                                </button>
                            </h1>
                            <form>
                                <div className="row">
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.journey_name')}</label>
                                        <input type="text" className="form-control" value={journeyInfo?.name} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.team_name')}</label>
                                        <input type="text" className="form-control" value={journeyInfo?.teamName} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.journey_owner')}</label>
                                        <input type="text" className="form-control" value={journeyInfo?.userId?.userName} disabled readOnly />
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.team_creator')}</label>
                                        <input type="text" className="form-control" value={journeyInfo?.userId?.userName} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.abundance_area')}</label>
                                        <input type="text" className="form-control text-capitalize" style={{ color: journeyInfo?.colourCode }} value={journeyInfo?.abundanceArea} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.focus_area')}</label>
                                        <input type="text" className="form-control" value={journeyInfo?.focusArea} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.desired_end_date')}</label>
                                        <input type="text" className="form-control" value={getFormatedJourneyDate(journeyInfo?.desiredJourneyEndDate)} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.likely_end_date')}</label>
                                        <input type="text" className="form-control" value={getFormatedJourneyDate(journeyInfo?.likelyJourneyEndDate)} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.change_state')}</label>
                                        <input type="text" className="form-control text-capitalize" value={journeyInfo?.status} disabled readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>{t('JourneyDetails.journey_start_date')} </label>
                                        <input type="text" className="form-control" value={getFormatedJourneyDate(journeyInfo?.journeyStartDate)} disabled readOnly />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
