import axios from "axios";
import { store } from "../.."; // Adjust the import according to your store configuration
import { authActions } from "./actions/actionTypes";
import {AuthApiEndpoint,BaseUrl} from "./apiEndpoints";

const refreshToken = async (refreshToken) => {
  return await axios.post(`${AuthApiEndpoint.AUTH_REFRESH_TOKEN_API}`, { refreshToken: refreshToken });
};

const axiosInstance = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "your-custom-value",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state?.auth?.loginUser?.accessToken;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error,"error")
    const originalRequest = error.config;
    
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      const state = store.getState();
      const refreshTokenValue = state?.auth?.loginUser?.refreshToken;

      try {
        const { data } = await refreshToken(refreshTokenValue);
        store.dispatch({
          type: authActions.REFRESH_TOKEN_SUCCESS,
          payload: data.accessToken,
        });

        axiosInstance.defaults.headers["Authorization"] = `Bearer ${data.accessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${data.accessToken}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        store.dispatch({
          type: authActions.AUTH_LOGOUT,
        });
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
