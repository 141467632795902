import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { IMAGES } from "../static";
import { doAuthLogin } from "../common/store/actions/authActions";
import { ButtonLoader, ValidationError } from "../components";


export const HomeView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // for translation of static text
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    // loader states
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const LoginFormik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t("Error.valid_email")).required(t("Error.email_required")),
            password: Yup.string().required(t("Error.password_required")).min(6, t("Error.password_length")),
        }),
        onSubmit: async (values) => {
            if (LoginFormik.isValid && LoginFormik.dirty) {
                setIsSubmitLoader(true);

                dispatch(doAuthLogin(values)).then((res) => {

                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        navigate("/dashboard");
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message);
                    }
                })
            }
        },
    });

    return (
        <>
            <div className="cover-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="align-self-center d-flex form-section">
                                <div className="effect-box">
                                    <div className="logo_section">
                                        <img src={IMAGES.logo} alt="" />
                                    </div>
                                    <div className="cover-box-txt ">
                                        <h1 className="align">Welcome to Beyond Abundance </h1>
                                        <p>
                                            "Welcome to Beyond Abundance, where every life journey is celebrated through action and abundance. Our app is your platform to share the unique chapters of your story, from the moments of triumph to the challenges overcome. Join a community dedicated to embracing the richness of life's experiences, and inspire others with your resilience, growth, and abundance. Start your journey today and discover the power of sharing your story with the world."
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="rightAuthbx">
                                <img src={IMAGES.login_Auth_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
