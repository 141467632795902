import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
// import { ChromePicker } from "react-color";
import {
  ButtonLoader,
  DataLoader,
  HeaderView,
  PopupModal,
  SearchBar,
  SidebarPanel,
  ValidationError,
} from "../components";
import { IMAGES } from "../static";
import { PaginationView } from "../common";
import { dropDownLimits } from "../utils/helperFunctions";
import {
  addAbundanceArea,
  deleteAbundanceArea,
  editAbundanceArea,
  getAbundanceAreaList,
  getSelectedAbundanceArea,
} from "../common/store/actions/abundanceAreaActions";
import { getFocusAreaListById } from "../common/store/actions/focusAreaActions";

export const AbundanceAreaView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const abundanceAreaList = useSelector(
    ({ abundanceArea }) => abundanceArea?.abundanceAreaList
  );

  const [showBody, setShowBody] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [displayColorPicker, setDisplayColorPicker] = useState(false);
  // loader states
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  // loader states

  const tableHeaders = ["#", t('TableHeader.title'), t('TableHeader.description'), t('TableHeader.help_text'), t('TableHeader.created_by'), t('TableHeader.status'), t('TableHeader.actions'),];
  // const popover = {
  //   position: "absolute",
  //   zIndex: "2",
  //   right: "0px",
  // };
  // const cover = {
  //   position: "fixed",
  //   top: "0px",
  //   right: "0px",
  //   bottom: "0px",
  //   left: "0px",
  //   overflow: "hidden",
  // };

  // const pickerWidth = "200px";

  const [limit, setLimit] = useState(dropDownLimits[0]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searched, setSearched] = useState(false);
  const [selectedAbundanceArea, setSelectedAbundanceArea] = useState({});

  const handleSelectedAbundanceArea = (abundanceAreaDetails) => {
    setSelectedAbundanceArea(abundanceAreaDetails);
    EditAbundanceAreaFormik.setValues({
      title: abundanceAreaDetails.title,
      status: abundanceAreaDetails.status,
      colourCode: abundanceAreaDetails.colourCode,
      helpText: abundanceAreaDetails?.helpText,
      description: abundanceAreaDetails?.description
    });
    setEditModal(true);
  };

  const handleViewAbundanceArea = (abundanceArea) => {
    setSelectedAbundanceArea(abundanceArea);
    dispatch(getSelectedAbundanceArea(abundanceArea));
    setViewModal(true);
    // dispatch(getFocusAreaListById(10, 1, null, selectedAbundanceArea?._id)).then((res) => {
    //   navigate("/focus-area-management");
    // });
  }

  const handleNavigateFocusArea = (abundanceArea) => {
    setSelectedAbundanceArea(abundanceArea);
    dispatch(getSelectedAbundanceArea(abundanceArea));
    dispatch(getFocusAreaListById(10, 1, null, selectedAbundanceArea?._id)).then((res) => {
      navigate(`/focus-area-management/${abundanceArea?._id}`);
    });
  }

  // const handleFocusAreaNavigation = () => {
  //   setIsSubmitLoader(true);
  //   dispatch(getFocusAreaListById(10, 1, null, selectedAbundanceArea?._id)).then((res) => {
  //     setIsSubmitLoader(false);
  //     // navigate("/focus-area-management");
  //     navigate(`/focus-area-management/${selectedAbundanceArea?._id}`);
  //   });
  // }

  const handleDeleteModal = (abundanceAreaDetails) => {
    setSelectedAbundanceArea(abundanceAreaDetails);
    setDeleteModal(true);
  }

  const handleCloseAddModal = () => {
    setAddModal(false);
    AddAbundanceAreaFormik.resetForm();
  }

  const handleCloseEditModal = () => {
    setEditModal(false);
    EditAbundanceAreaFormik.resetForm();
    setSelectedAbundanceArea("");
  }

  const handleCloseViewModal = () => {
    setViewModal(false);
    setSelectedAbundanceArea("");
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedAbundanceArea("");
  }

  const handleSearch = () => {
    fetchAbundanceArea(searchKeyword);
    setSearched(true);
};

  const AddAbundanceAreaFormik = useFormik({
    initialValues: {
      title: "",
      status: "active",
      colourCode: "#000",
      description: '',
      helpText: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required!"),
      status: Yup.string().required("Status is required"),
      colourCode: Yup.string().required("Color code is required!"),
      description: Yup.string(),
      helpText: Yup.string()
    }),
    onSubmit: async (values) => {
      if (AddAbundanceAreaFormik.isValid && AddAbundanceAreaFormik.dirty) {
        setIsSubmitLoader(true);

        dispatch(addAbundanceArea(values)).then((res) => {
          if (res?.status === 200 && res?.data?.success) {
            setIsSubmitLoader(false);
            toastr.success(
              "Success",
              res?.data?.message || res?.data?.data?.message
            );
            fetchAbundanceArea();
            setAddModal(false);
            AddAbundanceAreaFormik.resetForm();
          } else {
            setIsSubmitLoader(false);
            toastr.error(
              "Error",
              res?.data?.message || res?.data?.data?.message
            );
            setAddModal(false);
          }
        });
      }
    },
  });

  const EditAbundanceAreaFormik = useFormik({
    initialValues: {
      title: "",
      status: "active",
      colourCode: "",
      description: '',
      helpText: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required!"),
      status: Yup.string().required("Status is required"),
      colourCode: Yup.string().required("Color code is required!"),
      description: Yup.string(),
      helpText: Yup.string()
    }),
    onSubmit: async (values) => {
      if (EditAbundanceAreaFormik.isValid) {
        setIsSubmitLoader(true);

        const params = {
          ...values,
          id: selectedAbundanceArea?._id,
        };

        dispatch(editAbundanceArea(params)).then((res) => {
          if (res?.status === 200 && res?.data?.success) {
            setIsSubmitLoader(false);
            toastr.success(
              "Success",
              res?.data?.message || res?.data?.data?.message
            );
            fetchAbundanceArea();
            setEditModal(false);
            EditAbundanceAreaFormik.resetForm();
          } else {
            setIsSubmitLoader(false);
            toastr.error(
              "Error",
              res?.data?.message || res?.data?.data?.message
            );
            setEditModal(false);
          }
        });
      }
    },
  });

  const fetchAbundanceArea = (search = null) => {
    setIsLoading(true);
    dispatch(getAbundanceAreaList(limit, pageNumber, search)).then((res) => {
      if (res?.status !== 200 && !res?.data?.success) {
        toastr.error("Error", res?.data?.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        const { limit, totalItems, totalPages } = res?.data;
        setCount(limit ? limit : 0);
        setTotalEntries(totalItems ? totalItems : 0);
        setTotalPages(totalPages ? totalPages : 0);
      }
    });
  };

  const handleDeleteAbundanceArea = async () => {
    setIsSubmitLoader(true);
    await dispatch(deleteAbundanceArea(selectedAbundanceArea?._id)).then((res) => {
      if (res?.status === 200 && res?.data?.success) {
        setIsSubmitLoader(false);
        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
        fetchAbundanceArea();
        setDeleteModal(false);
        setSelectedAbundanceArea("");
      }
      else {
        setIsSubmitLoader(false);
        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
        setDeleteModal(false);
        setSelectedAbundanceArea("");
      }
    })
  }
  useEffect(() => {
    fetchAbundanceArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, limit, dispatch]);

  useEffect(() => {
    if (searchKeyword.length === 0 && searched) {
      fetchAbundanceArea();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  return (
    <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
      <HeaderView
        headingName={t("Sidebar.abundance_area")}
        setShowBody={setShowBody}
        showBody={showBody}
      />

      <div className="wrapper">
        <SidebarPanel setShowBody={setShowBody} showBody={showBody} />
        <div className="main-container">
          <div className="betcreBx">
            <ul>
              <li><button onClick={() => navigate("/dashboard")}>Dashboard</button></li>
              <li>/</li>
              <li className="active">Abundance Area</li>
            </ul>
          </div>
          <div className="table-container mainCustomBG">
            <div className="main-heading">
              <h1>
                <button className="addButton" onClick={() => setAddModal(true)}>
                  <img src={IMAGES.plus_icon} alt="" />
                  {t('Buttons.add')}
                </button>
              </h1>
            </div>
            <div className="headingTbL filterResponsiveCus filterMain">
              <Dropdown
                drop
                className="dropdown filtstatus"
                onSelect={(e) => {
                  setLimit(e);
                }}
              >
                <span className="sortingShow">
                  {t('Filterbar.show')}
                  <Dropdown.Toggle>
                    <span>{limit}</span>
                    <img src={IMAGES.filter_dropdown_icon} alt="" />
                  </Dropdown.Toggle>
                  {t('Filterbar.enteries')}
                </span>
                <Dropdown.Menu
                  variant=""
                  id="style-5"
                  className="cusDrupdown"
                >
                  {dropDownLimits?.map((limit, index) => (
                    <Dropdown.Item eventKey={limit} key={limit}>
                      {limit}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <span className="search-container">
                <SearchBar
                  name="Search"
                  value={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  onClick={() => {
                    handleSearch()
                  }}
                />
              </span>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {tableHeaders?.map((heading, index) => (
                      <th
                        key={heading}
                        className={
                          tableHeaders.length - 1 === index
                            ? ""
                            : ""
                        }
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={tableHeaders.length} className="text-center tableE_Caption" >
                        <DataLoader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {abundanceAreaList?.length > 0 ? (
                        abundanceAreaList?.map((abundanceArea, index) => (
                          <tr key={abundanceArea._id}>
                            <td>
                              {parseInt(
                                limit * (pageNumber - 1) + index + 1
                              ) < 10
                                ? "0" + (limit * (pageNumber - 1) + index + 1)
                                : limit * (pageNumber - 1) + index + 1}
                            </td>
                            <td style={{ color: abundanceArea.colourCode }} className="highLight-text" onClick={() => {
                              handleNavigateFocusArea(abundanceArea)
                            }}>
                              {abundanceArea.title
                                ? abundanceArea.title
                                : "N/A"}
                            </td>
                            <td className="ellipsis-text">
                              {abundanceArea.description
                                ? abundanceArea.description
                                : "N/A"}
                            </td>
                            <td className="ellipsis-text">
                              {abundanceArea.helpText
                                ? abundanceArea.helpText
                                : "N/A"}
                            </td>
                            <td >
                              {abundanceArea.userId
                                ? abundanceArea.userId?.userName ? abundanceArea.userId?.userName : abundanceArea.type : abundanceArea.type}
                            </td>
                            <td className={`font-weight-bold ${abundanceArea.status === "active" ? "activeclr" : "inactiveclr"}`}>
                              {abundanceArea.status}
                            </td>
                            <td className="">
                              <button className="icon-button" title="View" onClick={() => { handleViewAbundanceArea(abundanceArea) }}> <img src={IMAGES.view_icon} alt="" />
                              </button>
                              <button
                                className="icon-button"
                                title="Edit"
                                onClick={() => {
                                  handleSelectedAbundanceArea(abundanceArea);
                                }}
                              >
                                <img src={IMAGES.edit_icon} alt="" />
                              </button>

                              <button className="icon-button" title="Delete" onClick={() => { handleDeleteModal(abundanceArea) }}><img src={IMAGES.delete_icon} alt="Delete" /></button>
                              <button type="button" title="Go To Focus Area" onClick={() => handleNavigateFocusArea(abundanceArea)} ><img src={IMAGES.rightTickImg} alt="" /></button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={tableHeaders.length} className="text-center">
                            {t('NotFound.abundance_record')}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-pagination">
              <div className="col-md-6 show_entrieNum">
                <p>
                  {t('Pagination.showing')} {count > 0 ? 1 : 0} {t('Pagination.to')} {limit} {t('Pagination.of')} {totalEntries} {t('Pagination.entries')}
                </p>
              </div>
              <div className="col-md-6 pagination">
                <PaginationView
                  totalPages={totalPages}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {addModal && (
        <PopupModal
          className={"areaModal"}
          show={addModal}
          onHide={() => {
            handleCloseAddModal();
          }}
        >
          <form method="POST" onSubmit={AddAbundanceAreaFormik.handleSubmit}>
            <h1>{t('ModalTitles.add_abundance_area')}</h1>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Abundance.title_label')}<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter title"
                    name="title"
                    onChange={AddAbundanceAreaFormik.handleChange}
                    onBlur={AddAbundanceAreaFormik.handleBlur}
                    value={AddAbundanceAreaFormik.values.title}
                  />
                  {AddAbundanceAreaFormik.touched.title &&
                    AddAbundanceAreaFormik.errors.title && (
                      <ValidationError
                        error={AddAbundanceAreaFormik.errors.title}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Abundance.status_label')}<span>*</span>
                  </label>
                  <Dropdown
                    drop
                    className="dropdown"
                    onSelect={(e) => {
                      AddAbundanceAreaFormik.setFieldValue("status", e);
                    }}
                  >
                    <Dropdown.Toggle disabled
                      name="slotId"
                      onChange={AddAbundanceAreaFormik.handleChange}
                      onBlur={AddAbundanceAreaFormik.handleBlur}
                      value={AddAbundanceAreaFormik.values.slotId}
                    >
                      <span>{AddAbundanceAreaFormik.values.status}</span>
                      <img src={IMAGES.filter_dropdown_icon} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="" className="cusDrupdown">
                      <Dropdown.Item eventKey={"active"} key={"active"}>
                        Active
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey={"inactive"} key={"inactive"}>
                        Inactive
                      </Dropdown.Item> */}
                      <Dropdown.Item eventKey={"retrieve"} key={"retrieve"}>
                        Retrieve
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {AddAbundanceAreaFormik.touched.status &&
                    AddAbundanceAreaFormik.errors.status && (
                      <ValidationError
                        error={AddAbundanceAreaFormik.errors.status}
                      />
                    )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.description_label')}</label>
                  <textarea className="form-control" name="description" rows={4} value={AddAbundanceAreaFormik.values.description}
                    onChange={AddAbundanceAreaFormik.handleChange} onBlur={AddAbundanceAreaFormik.handleBlur}>

                  </textarea>
                  {AddAbundanceAreaFormik.touched.description &&
                    AddAbundanceAreaFormik.errors.description && (
                      <ValidationError
                        error={AddAbundanceAreaFormik.errors.description}
                      />
                    )}
                </div>

              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.help_text_label')}</label>
                  <textarea className="form-control" name="helpText" rows={3} value={AddAbundanceAreaFormik.values.helpText}
                    onChange={AddAbundanceAreaFormik.handleChange} onBlur={AddAbundanceAreaFormik.handleBlur}>

                  </textarea>
                  {AddAbundanceAreaFormik.touched.helpText &&
                    AddAbundanceAreaFormik.errors.helpText && (
                      <ValidationError
                        error={AddAbundanceAreaFormik.errors.helpText}
                      />
                    )}
                </div>

              </div>
              {/* <div className="form-group">
                <label>
                  Select color code<span>*</span>
                </label>

                <div className="possiPassIcon">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Select color"}
                    name="colourCode"
                    value={AddAbundanceAreaFormik?.values?.colourCode}
                    onChange={AddAbundanceAreaFormik.handleChange}
                    onBlur={AddAbundanceAreaFormik.handleBlur}
                    autoComplete="false"
                  />
                  <i
                    className="color-box"
                    style={{
                      "--colorCode": AddAbundanceAreaFormik.values.colourCode,
                    }}
                    onClick={() => {
                      setDisplayColorPicker(true);
                    }}
                  >
                    {" "}
                  </i>
                  {displayColorPicker ? (
                    <div style={popover}>
                      <div
                        style={cover}
                        onClick={() => setDisplayColorPicker(false)}
                      />
                      <ChromePicker
                        width={pickerWidth}
                        color={
                          AddAbundanceAreaFormik.values.colourCode || "#30aadd"
                        }
                        onChange={(e) => {
                          AddAbundanceAreaFormik.setFieldValue(
                            "colourCode",
                            e.hex
                          );
                        }}
                        disableAlpha
                      />
                    </div>
                  ) : null}
                </div>

                {AddAbundanceAreaFormik.touched.colourCode &&
                  AddAbundanceAreaFormik.errors.colourCode && (
                    <ValidationError
                      error={AddAbundanceAreaFormik.errors.colourCode}
                    />
                  )}
              </div> */}
            </div>
            <div className="footerBtnAll mt-3">
              <button
                onClick={() => handleCloseAddModal()}
                className="cancelborder"
              >
                {t("Buttons.cancel")}
              </button>
              <button type="submit" disabled={isSubmitLoader}>
                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.submit")}
              </button>
            </div>
          </form>
        </PopupModal>
      )}

      {editModal && (
        <PopupModal
          className={"areaModal"}
          show={editModal}
          onHide={() => {
            handleCloseEditModal();
          }}
        >
          <form method="POST" onSubmit={EditAbundanceAreaFormik.handleSubmit}>
            <h1>{t('ModalTitles.edit_abundance_area')}</h1>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Abundance.title_label')}<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter title"
                    name="title"
                    onChange={EditAbundanceAreaFormik.handleChange}
                    onBlur={EditAbundanceAreaFormik.handleBlur}
                    value={EditAbundanceAreaFormik.values.title}
                  />
                  {EditAbundanceAreaFormik.touched.title &&
                    EditAbundanceAreaFormik.errors.title && (
                      <ValidationError
                        error={EditAbundanceAreaFormik.errors.title}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t('Abundance.status_label')} </label>
                  <Dropdown
                    drop
                    className="dropdown"
                    onSelect={(e) => {
                      EditAbundanceAreaFormik.setFieldValue("status", e);
                    }}
                  >
                    <Dropdown.Toggle disabled
                      name="slotId"
                      onChange={EditAbundanceAreaFormik.handleChange}
                      onBlur={EditAbundanceAreaFormik.handleBlur}
                      value={EditAbundanceAreaFormik.values.slotId}
                    >
                      <span>{EditAbundanceAreaFormik.values.status}</span>
                      <img src={IMAGES.filter_dropdown_icon} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="" className="cusDrupdown">
                      <Dropdown.Item eventKey={"active"} key={"active"}>
                        Active
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey={"inactive"} key={"inactive"}>
                        Inactive
                      </Dropdown.Item> */}
                      <Dropdown.Item eventKey={"retrieve"} key={"retrieve"}>
                        Retrieve
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {EditAbundanceAreaFormik.touched.status &&
                    EditAbundanceAreaFormik.errors.status && (
                      <ValidationError
                        error={EditAbundanceAreaFormik.errors.status}
                      />
                    )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.description_label')}</label>
                  <textarea className="form-control" name="description" rows={4} value={EditAbundanceAreaFormik.values.description}
                    onChange={EditAbundanceAreaFormik.handleChange} onBlur={EditAbundanceAreaFormik.handleBlur}>

                  </textarea>
                  {EditAbundanceAreaFormik.touched.description &&
                    EditAbundanceAreaFormik.errors.description && (
                      <ValidationError
                        error={EditAbundanceAreaFormik.errors.description}
                      />
                    )}
                </div>

              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.help_text_label')}</label>
                  <textarea className="form-control" name="helpText" rows={3} value={EditAbundanceAreaFormik.values.helpText}
                    onChange={EditAbundanceAreaFormik.handleChange} onBlur={EditAbundanceAreaFormik.handleBlur}>
                  </textarea>
                  {EditAbundanceAreaFormik.touched.helpText &&
                    EditAbundanceAreaFormik.errors.helpText && (
                      <ValidationError
                        error={EditAbundanceAreaFormik.errors.helpText}
                      />
                    )}
                </div>

              </div>

              {/* <div className="form-group">
                <label>
                  Select color code<span>*</span>
                </label>

                <div className="possiPassIcon">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={"Select color"}
                    name="colourCode"
                    value={EditAbundanceAreaFormik?.values?.colourCode}
                    onChange={EditAbundanceAreaFormik.handleChange}
                    onBlur={EditAbundanceAreaFormik.handleBlur}
                    autoComplete="false"
                  />
                  <i
                    className="color-box"
                    style={{
                      "--colorCode": EditAbundanceAreaFormik.values.colourCode,
                    }}
                    onClick={() => {
                      setDisplayColorPicker(true);
                    }}
                  >
                    {" "}
                  </i>
                  {displayColorPicker ? (
                    <div style={popover}>
                      <div
                        style={cover}
                        onClick={() => setDisplayColorPicker(false)}
                      />
                      <ChromePicker
                        width={pickerWidth}
                        color={
                          EditAbundanceAreaFormik.values.colourCode || "#30aadd"
                        }
                        onChange={(e) => {
                          EditAbundanceAreaFormik.setFieldValue(
                            "colourCode",
                            e.hex
                          );
                        }}
                        disableAlpha
                      />
                    </div>
                  ) : null}
                </div>

                {EditAbundanceAreaFormik.touched.colourCode &&
                  EditAbundanceAreaFormik.errors.colourCode && (
                    <ValidationError
                      error={EditAbundanceAreaFormik.errors.colourCode}
                    />
                  )}
              </div> */}
            </div>
            <div className="footerBtnAll mt-3">
              <button
                onClick={() => handleCloseEditModal()}
                className="cancelborder"
              >
                {t("Buttons.cancel")}
              </button>
              <button type="submit" disabled={isSubmitLoader}>
                {isSubmitLoader ? <ButtonLoader /> : "Update"}
              </button>
            </div>
          </form>
        </PopupModal>
      )}

      {viewModal && (
        <PopupModal
          className={"areaModal"}
          show={viewModal}
          onHide={() => {
            handleCloseViewModal();
          }}
        >
          <form method="POST">
            <h1>{t('ModalTitles.view_abundance_area')}</h1>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    {t('Abundance.title_label')} <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter title"
                    name="title"
                    value={selectedAbundanceArea?.title}
                    readOnly disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t('Abundance.status_label')} </label>
                  <Dropdown drop className="dropdown">
                    <Dropdown.Toggle readOnly disabled name="slotId" value={selectedAbundanceArea?.status} >
                      <span>{selectedAbundanceArea?.status}</span>
                      <img src={IMAGES.filter_dropdown_icon} alt="" />
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.description_label')}</label>
                  <textarea className="form-control" name="description" rows={4} value={selectedAbundanceArea.description} readOnly disabled>
                  </textarea>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.help_text_label')}</label>
                  <textarea className="form-control" name="helpText" rows={3} value={selectedAbundanceArea.helpText} readOnly disabled>
                  </textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>{t('Abundance.created_by')}</label>
                  <input type="text" className="form-control text-capitalize" name="userId" value={selectedAbundanceArea.userId ? selectedAbundanceArea.userId?.userName : selectedAbundanceArea.type} readOnly disabled />
                </div>
              </div>
            </div>
            {/* <div className="footerBtnAll mt-3">
              <button onClick={() => handleCloseViewModal()} className="cancelborder" >
                {t("Buttons.cancel")}
              </button>
              <button type="button" disabled={isSubmitLoader} onClick={() => { handleFocusAreaNavigation() }}>
                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.add_focus_area")}
              </button>
            </div> */}
          </form>
        </PopupModal>
      )}

      {deleteModal && (
        <PopupModal
          show={deleteModal}
          onHide={() => {
            handleCloseDeleteModal()
          }}
        >
          <form>
            <img src={IMAGES.deleteAccountIcon} alt="" />
            <p className="praregraphP width50">
              {t('Confirmation.delete_abundance_area')}
            </p>
            <div className="footerBtnAll">
              <button
                onClick={() => {
                  handleCloseDeleteModal()
                }}
                className="cancelborder"
              >
                {t("Buttons.cancel")}
              </button>
              <button
                type="button"
                disabled={isSubmitLoader}
                onClick={() => {
                  handleDeleteAbundanceArea();
                }}
              >
                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.confirm")}
              </button>
            </div>
          </form>
        </PopupModal>
      )}
    </div>
  );
};
