import { useEffect } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import { useLocation } from "react-router";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return null;
}

export const dropDownLimits = [10, 25, 50, 100];

export const statusList = ["active", "paused", "completed", "archived"];

export const getFormatedDate = (date) => {
  const d = new Date(date).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });
  return d;
};

export const getFormatedJourneyDate = (date) => {
  const d = new Date(date).toLocaleString(undefined, {
    month: "2-digit",
    year: "numeric",
    day: "2-digit",
  });
  return d;
};

export const handlePhoneNumberChange = (event, Formik, key) => {
  // Remove any non-numeric characters
  const sanitizedValue = event.target.value.replace(/[^0-9]/g, "");
  Formik.setFieldValue(key, sanitizedValue);
  return sanitizedValue;
};

export const formatedCountryCode = (phone) => {
  const countryCode = formatPhoneNumberIntl(phone)?.split(" ")[0]?.toString();
  return countryCode;
};

export const formatedPhone = (phone) => {
  if (!phone) {
    return undefined;
  }

  const number = parsePhoneNumber(phone);

  if (!number) {
    return undefined;
  }

  let phoneNumber = formatPhoneNumber(phone)
    ?.split(" ")
    ?.join("")
    ?.toString()
    .replace(/[^0-9]/g, "");

  if (number.country === "IN" && phone.length === 13) {
    phoneNumber = phoneNumber?.slice(1);
  }
  return phoneNumber;
};
