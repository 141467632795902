// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --background: #fff;
  --background-theme: #f2f8ff;
  /* --primary-Bg: #19be6f ; */
  --primary-Bg: #0070c0;
  --secondary-Bg: #1c1c28;
  --background-danger: #ff4a4a;

  --text-color: #fff;
  --text-primary: #1c1c28;
  --text-secondary: #0070c0;
  /* --text-secondary: #19be6f; */
  --text-color-danger: #ff4a4a;
  --text-opcity: #aba8a8;

  --border-white: #fff;
  --border: #aba8a8;
  --border-active: #19be6f;
  --border-primary: #1c1c28;
  --border-danger: #ff4a4a;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,4BAA4B;EAC5B,qBAAqB;EACrB,uBAAuB;EACvB,4BAA4B;;EAE5B,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;EACzB,+BAA+B;EAC/B,4BAA4B;EAC5B,sBAAsB;;EAEtB,oBAAoB;EACpB,iBAAiB;EACjB,wBAAwB;EACxB,yBAAyB;EACzB,wBAAwB;AAC1B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n:root {\n  --background: #fff;\n  --background-theme: #f2f8ff;\n  /* --primary-Bg: #19be6f ; */\n  --primary-Bg: #0070c0;\n  --secondary-Bg: #1c1c28;\n  --background-danger: #ff4a4a;\n\n  --text-color: #fff;\n  --text-primary: #1c1c28;\n  --text-secondary: #0070c0;\n  /* --text-secondary: #19be6f; */\n  --text-color-danger: #ff4a4a;\n  --text-opcity: #aba8a8;\n\n  --border-white: #fff;\n  --border: #aba8a8;\n  --border-active: #19be6f;\n  --border-primary: #1c1c28;\n  --border-danger: #ff4a4a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
