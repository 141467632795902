import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "./helperFunctions";

import {
    AbundanceAreaView,
    ActionAreaView,
    ChangePasswordView,
    DashboardView,
    FocusAreaView,
    ForgotPasswordView,
    HomeView,
    InstructionManagementView,
    JourneyDetailsView,
    JourneysView,
    LinksView,
    LoginView,
    PrivacyPolicyView,
    QuoteListView,
    QuoteManagementView,
    ResetPasswordView,
    TermsOfServiceView,
    UserDetailsView,
    UsersView,
    VerifyCodeView,
    LoggingView
    
} from "../pages";
import { PageNotFoundView } from "../common";
import { AdminAuthRoutes, PrivateRoutes } from "./protectedRoutes";

// initial routes
export const Router = () => {
    return (
        <React.Fragment>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route exact={true} path="*" element={<PageNotFoundView />} />
                    <Route exact={true} path="/privacy-policy" element={<PrivacyPolicyView />} />
                    <Route exact={true} path="/terms-of-service" element={<TermsOfServiceView />} />
                    <Route exact={true} path="/" element={<HomeView />} />

                    <Route element={<PrivateRoutes />}>
                        <Route exact={true} path="/admin" element={<LoginView />} />
                        <Route exact={true} path="/forgot-password" element={<ForgotPasswordView />} />
                        <Route exact={true} path="/reset-password" element={<ResetPasswordView />} />
                        <Route exact={true} path="/verify-code" element={<VerifyCodeView />} />
                    </Route>

                    {/* DashboardRoutes */}
                    <Route element={<AdminAuthRoutes />}>
                        <Route path="/dashboard" element={<DashboardView />} />
                        <Route path="/users" element={<UsersView />} />
                        <Route path="/user-details" element={<UserDetailsView />} />
                        <Route path="/change-password" element={<ChangePasswordView />} />
                        <Route path="/links-management" element={<LinksView />} />
                        <Route path="/journeys" element={<JourneysView />} />
                        <Route path="/journeys/:userId" element={<JourneysView />} />
                        <Route path="/journey-details" element={<JourneyDetailsView />} />
                        <Route path="/focus-area-management" element={<FocusAreaView />} />
                        <Route path="/focus-area-management/:abundanceAreaId" element={<FocusAreaView />} />
                        <Route path="/abundance-area-management" element={<AbundanceAreaView />} />
                        <Route path="/action-area-management" element={<ActionAreaView />} />
                        <Route path="/action-area-management/:focusAreaId" element={<ActionAreaView />} />
                        <Route path="/quotes-management" element={<QuoteManagementView />} />
                        <Route path="/quote-list" element={<QuoteListView />} />
                        <Route path="/instructions-management" element={<InstructionManagementView />} />
                        <Route path="/log-management" element={<LoggingView />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
};
