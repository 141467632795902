import { AWSCredentialsApiEndpoint, AuthApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { authActions, credentialsActions } from "./actionTypes";

export const doAuthLogin = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "POST", //method
      AuthApiEndpoint.AUTH_LOGIN_API, //api-endpoint
      params, //data/params
      false //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      const { data } = response;
      dispatch({
        type: authActions.AUTH_LOGIN,
        payload: data,
      });
    }
    return response;
  };
};

export const doAuthSendOtp = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_SEND_OTP_API,
      params,
      false
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: authActions.AUTH_SEND_OTP,
        payload: params?.email,
      });
    }

    return response;
  };
};

export const doAuthVerifyOtp = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_VERIFY_OTP_API,
      params,
      false
    );

    return response;
  };
};

export const doAuthSetPassword = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_SET_PASSWORD_API,
      params,
      false
    );

    return response;
  };
};

export const doAuthChangePassword = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_CHANGE_PASSWORD_API,
      params,
      true
    );

    return response;
  };
};

export const doAuthRefreshToken = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_REFRESH_TOKEN_API,
      params,
      true
    );

    return response;
  };
};

export const doAuthLogout = () => {
  return async (dispatch, getState) => {
    const state = getState().auth.loginUser;
    const params = {
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
    };

    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_LOGOUT_API,
      params,
      true
    );

    if (response?.status === 200 && response?.data?.success) {
      dispatch({
        type: authActions.AUTH_LOGOUT,
        payload: {},
      });
      localStorage.clear();
    }
    dispatch({
      type: authActions.AUTH_LOGOUT,
      payload: {},
    });
    localStorage.clear();
    return response;
  };
};

export const getDashboardData = () => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      AuthApiEndpoint.AUTH_DASHBOARD_DATA_API, //api-endpoint
      null, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      const { data } = response?.data;

      dispatch({
        type: authActions.GET_DASHBOARD_DATA,
        payload: data,
      });
    }
    return response;
  };
};

export const addLink = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AuthApiEndpoint.AUTH_ADD_LINK_API,
      params,
      true
    );
    return response;
  };
};

export const getLinks = () => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, //state
      "GET", //method
      AuthApiEndpoint.AUTH_GET_LINK_API, //api-endpoint
      null, //data/params
      true //isAuth
    );
    if (response?.status === 200 && response?.data?.success) {
      const { data } = response?.data;

      dispatch({
        type: authActions.GET_LINKS,
        payload: data ? data : { privacy_policy: "", terms_conditions: "" },
      });
    }
    return response;
  };
};

export const doGetAwsCredentials = (params) => {
  
  return async (dispatch, getState) => {
    const response = await Instance(
      getState,
      "POST",
      AWSCredentialsApiEndpoint.GET_CREDENTIALS,
      params,
      false,
      false
    );

    if (response?.status === 200 && response?.data?.success) {
      const { data } = response?.data;

      dispatch({
        type: credentialsActions.GET_CREDENTIALS,
        payload: data,
      });
    }

    return response;
  };
};
// export const setActiveIndexAction = (data) => {
//   return async (dispatch) => {
//     return dispatch({
//       type: authActions.SET_ACTIVE_INDEX,
//       payload: data,
//       status: 200,
//       success: true,
//     });
//   };
// };
