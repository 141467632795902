import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  formatedCountryCode,
  formatedPhone,
} from "../../utils/helperFunctions";

export const PhoneNumberInput = ({ Phone, setPhone, disable, Formik }) => {
  return (
    <div
      className={
        disable
          ? "phoneInputPerent phoneInputPerentdisabled"
          : "phoneInputPerent"
      }
      style={{ background: disable ? "##e9ecef" : "#fff" }}
    >
      <PhoneInput
        international
        name="phoneNumber"
        countryCallingCodeEditable={false}
        defaultCountry="IN"
        value={Phone}
        onChange={async (e) => {
          if (Formik) {
            await setPhone(e);
            await Formik?.setFieldValue("countryCode", formatedCountryCode(e));
            await Formik?.setFieldValue("phoneNumber", formatedPhone(e));
          }
        }}
        rules={{ required: true }}
        onBlur={Formik?.handleBlur && Formik?.handleBlur}
        onFocus={Formik?.handleBlur && Formik?.handleBlur}
        disabled={disable ? true : false}
      />
    </div>
  );
};
