import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IMAGES } from '../../static';

export const AccordionContentView = ({ title, children, index, activeIndex, setActiveIndex, setShowBody }) => {

    const { abundanceAreaId, focusAreaId } = useParams();
    const targetPaths = ["/quotes-management", "/quote-list", "/abundance-area-management", '/focus-area-management', "/action-area-management", `/focus-area-management/${abundanceAreaId}`, `/action-area-management/${focusAreaId}`, "/instructions-management", "/instruction-list"];
    const handleSetIndex = (index) => (activeIndex !== index) ? setActiveIndex(index) : setActiveIndex(0);
    const { pathname } = useLocation();

    useEffect(() => {
        if (targetPaths.includes(pathname)) {
            setActiveIndex(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, setActiveIndex]);

    return (
        <>
            <div className="accordion_bx" onClick={() => {
                handleSetIndex(index);
            }}>
                {(activeIndex === index) ? (
                    <>
                        <div className={`accordion-heading active cursor-pointer ${(targetPaths.includes(pathname)) ? "active" : ""}`}>
                            <img src={IMAGES.setting_icon} alt="" className="LiIconImg" />
                            <img
                                src={IMAGES.setting_iconActive}
                                alt=""
                                className="LiIconImgActive"
                            />
                            <span className='settinghideText'>{title}</span>
                            <span className='settinghideErrow'><img src={IMAGES.dropdown_img} alt="" className='imgMenuDrop' /></span>

                        </div>
                        <div className="accordion-content" style={{ display: "block" }}>
                            {children}
                        </div>
                    </>
                ) :
                    <div className={`accordion-heading cursor-pointer  ${(pathname === "/quotes-management" || pathname === "/quote-list" || pathname === "/abundance-area-management" || pathname === "/focus-area-management" || pathname === `/focus-area-management/${abundanceAreaId}` || pathname === `/action-area-management/${focusAreaId}`) || pathname === `/instructions-management` || pathname === `/instruction-list` ? "pathActive" : ""}`}>
                        <img src={IMAGES.setting_icon} alt="" className="LiIconImg" />
                        <img
                            src={IMAGES.setting_iconActive}
                            alt=""
                            className="LiIconImgActive"
                        />
                        <span className='settinghideText'>{title}</span>
                        <span className='settinghideErrow'><img src={IMAGES.dropdown_img} alt="" className='imgMenuDrop' /></span>
                    </div>
                }
            </div>
        </>
    );
};
