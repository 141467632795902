import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import { ButtonLoader, HeaderView, SidebarPanel, ValidationError } from "../components";
import { addLink, getLinks } from "../common/store/actions/authActions";
import { IMAGES } from "../static";

export const LinksView = () => {
  
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const links = useSelector(({ auth }) => auth.links);

    const [showBody, setShowBody] = useState(false);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const LinkFormik = useFormik({
        initialValues: {
            privacy_policy: links?.privacy_policy ? links?.privacy_policy : "",
            terms_conditions: links?.terms_conditions ? links?.terms_conditions : "",
        },
        validationSchema: Yup.object({
            privacy_policy: Yup.string(),
            terms_conditions: Yup.string(),
        }),
        onSubmit: async (values) => {

            if (LinkFormik.isValid) {
                setIsSubmitLoader(true);
                const params = {
                    ...values
                }
                if (links?._id) {
                    params.id = links?._id
                }
                dispatch(addLink(params)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message);
                        // fetchLinks();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.response?.data?.message);
                    }
                });
            }
        },
    });

    // const fetchLinks = async () => {
    //     dispatch(getLinks());
    // }

    useEffect(() => {
        console.log("working")
        dispatch(getLinks()); // Fetch links on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={t("Sidebar.links_management")}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} activeIndex={activeIndex} setActiveIndex={setActiveIndex} />

                    <div className="main-container">
                        <div className="mainCustomBG cusformbox">
                            {/* <h1>Links Management</h1> */}

                            <form method="POST" onSubmit={LinkFormik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('LinksManagement.privacy_policy')}</label>
                                            <div className="possiPassIcon">
                                                <input type="text" className="form-control" placeholder="Enter your url"
                                                    name="privacy_policy" value={LinkFormik?.values?.privacy_policy} onChange={LinkFormik.handleChange}
                                                    onBlur={LinkFormik.handleBlur} autoComplete="false" />
                                                <img src={IMAGES.LinkInputIcon} alt="" />
                                            </div>
                                            {LinkFormik.touched.privacy_policy && LinkFormik.errors.privacy_policy && (
                                                <ValidationError error={LinkFormik.errors.privacy_policy} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('LinksManagement.terms_of_service')}</label>
                                            <div className="possiPassIcon">
                                                <input type="text" className="form-control" placeholder="Enter your url" name="terms_conditions" value={LinkFormik?.values?.terms_conditions} onChange={LinkFormik.handleChange}
                                                    onBlur={LinkFormik.handleBlur} autoComplete="false" />
                                                <img src={IMAGES.LinkInputIcon} alt="" />
                                            </div>

                                            {LinkFormik.touched.terms_conditions && LinkFormik.errors.terms_conditions && (
                                                <ValidationError error={LinkFormik.errors.terms_conditions} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>{t('LinksManagement.about_us')}</label>
                                            <div className="possiPassIcon">
                                                <input type="text" className="form-control" name="about_us" placeholder="Enter your url" onChange={LinkFormik.handleChange}
                                                    onBlur={LinkFormik.handleBlur} autoComplete="false" />
                                                <img src={IMAGES.LinkInputIcon} alt="" />
                                            </div>

                                            {LinkFormik.touched.about_us && LinkFormik.errors.about_us && (
                                                <ValidationError error={LinkFormik.errors.about_us} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-buttons">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group ">
                                                <button type="submit" disabled={isSubmitLoader}>
                                                    {isSubmitLoader ?
                                                        <span className="d-flex justify-content-between">
                                                            <ButtonLoader />
                                                            {t("Loading.please_wait")}
                                                        </span>
                                                        :
                                                        t("Buttons.submit")
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}