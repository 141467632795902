import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  ButtonLoader,
  DataLoader,
  HeaderView,
  PopupModal,
  SidebarPanel,
  ValidationError,
} from "../components";
import { IMAGES } from "../static";
import { addInstruction, getInstructionScreenAreas, getInstructions } from "../common/store/actions/instructionsActions";

export const InstructionManagementView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const screenAreas = useSelector(({ instructions }) => instructions?.instructionScreenAreas);
  const instructions = useSelector(({ instructions }) => instructions?.instructions);

  const [selectedInstructionScreenArea, setSelectedInstructionArea] = useState(false);
  const [selectedInstruction, setSelectedInstruction] = useState(false);
  const [showBody, setShowBody] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editable, setEditable] = useState(false);

  // loader states
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  // loader states

  useEffect(() => {
    if (editable) {
      inputRef.current.setSelectionRange(Formik.values.instruction.length, Formik.values.instruction.length);
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  const Formik = useFormik({
    initialValues: {
      instruction: ""
    },
    validationSchema: Yup.object({
      instruction: Yup.string().required("Instruction is required!"),
    }),
    onSubmit: async (values) => {
      if (Formik.isValid && Formik.dirty) {
        setIsSubmitLoader(true);
        const params = {
          ...values,
          title: selectedInstructionScreenArea?.areaName?.toString()
        }
        if (values?._id) {
          params.id = values?._id
        }
        dispatch(addInstruction(params)).then((res) => {
          if (!res?.data?.success) {
            toastr.error("Error", res?.data?.message);
            setIsSubmitLoader(false);
          } else {
            setIsLoading(true);
            toastr.success('Success', res?.data?.message);
            setIsSubmitLoader(false);
            fetchInstructions();
            setViewModal(false);
            Formik.resetForm();
            setEditable(false);
          }
        })
      }
    },
  })

  const fetchScreenAreas = () => {
    dispatch(getInstructionScreenAreas()).then((res) => {
      if (res?.status !== 200 && !res?.data?.success) {
        toastr.error("Error", res?.data?.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleFilterInstruction = async (screenAreaDetails) => {
    const filteredInstruction = await instructions?.find((instruction) => instruction?.title?.toString().trim() === screenAreaDetails?.areaName?.toString().trim());
    return filteredInstruction;
  }

  const handleViewScreenInstructions = async (screenAreaDetails) => {
    setSelectedInstructionArea(screenAreaDetails);
    const instruction = await handleFilterInstruction(screenAreaDetails);
    setSelectedInstruction(instruction);
    if (instruction) {
      await Formik.setValues(instruction);
    }
    setViewModal(true);
  }

  const fetchInstructions = (search) => {
    setIsLoading(true);
    dispatch(getInstructions()).then((res) => {
      if (res?.status !== 200 && !res?.data?.success) {
        toastr.error("Error", res?.data?.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleEditable = () => {
    setEditable(true);
  }

  const handleCloseInstructionModal = () => {
    setEditable(false);
    setViewModal(false);
    setSelectedInstruction({});
    Formik.resetForm();
    setEditable(false);
  }

  useEffect(() => {
    setIsLoading(true);
    fetchScreenAreas();
    fetchInstructions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView
          headingName={t("Sidebar.instructions_management")}
          setShowBody={setShowBody}
          showBody={showBody}
        />

        <div className="wrapper">
          <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

          <div className="main-container">
            <div className="mainCustomBG">
              <div className="main-heading">
                <h1>Instructions Screen Areas</h1>
              </div>
              <div className="quote-section">
                <ul className="screenAreaList">
                  <div className="row">
                    {isLoading ?
                      <DataLoader />
                      :
                      <>
                        {screenAreas?.length > 0 ?
                          screenAreas?.map((screenArea, index) => (
                            <div className="col-md-6" key={`screenArea + ${index}`} >
                              <li onClick={() => handleViewScreenInstructions(screenArea)}>
                                {screenArea?.areaName}
                                <img src={IMAGES.rightTickImg} alt="" />
                              </li>
                            </div>
                          ))
                          :
                          <li className="no-quotes">
                            {t('NotFound.quote_record')}
                          </li>
                        }
                      </>
                    }
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {viewModal &&
          <PopupModal className={"areaModal"} show={viewModal} onHide={() => {
            handleCloseInstructionModal()
          }} title>
            <form method="POST" onSubmit={Formik.handleSubmit}>
              <h1>{`${selectedInstructionScreenArea?.areaName} Instructions`}</h1>
              <div className="row">
                <div className="form-group">
                  <label>Instructions:</label>
                  <textarea className="form-control" name="instruction" ref={inputRef} value={Formik.values.instruction} placeholder={Formik.values.instruction ? Formik.values.instruction : "No Instructions"} onChange={Formik.handleChange} onBlur={Formik.handleBlur} readOnly={!editable} disabled={!editable} rows="5">
                  </textarea>
                  {Formik.touched.instruction && Formik.errors.instruction && (
                    <ValidationError error={Formik.errors.instruction} />
                  )}
                </div>
                <div className="footerBtnAll mt-3">

                  {!editable ?
                    (
                      <button type="button" onClick={() => { handleEditable() }}>
                        {t(selectedInstruction ? 'Buttons.edit' : 'Buttons.add')}
                      </button>
                    )
                    :
                    (
                      <>
                        <button type="button" onClick={() => {
                          handleCloseInstructionModal()
                        }} className="cancelborder" >
                          {t("Buttons.cancel")}
                        </button>
                        <button type="submit" disabled={isSubmitLoader}>
                          {isSubmitLoader ? <ButtonLoader /> : t(selectedInstruction ? "Buttons.update_instructions" : "Buttons.add_instructions")}
                        </button>
                      </>
                    )
                  }

                </div>
              </div>
            </form>
          </PopupModal>
        }
      </div>
    </>
  );
};
