import { UserApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { userActions } from "./actionTypes";

export const getAllUsers = (limit, pageNumber, search) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&search=${search}` : ''; // Conditionally add the search query

        const response = await Instance(
            getState, // state
            "GET", // method
            UserApiEndpoint.GET_USER_LIST_API + `?page=${pageNumber}&limit=${limit}` + searchParam, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { users } = response?.data;

            dispatch({
                type: userActions.GET_USER_LIST,
                payload: users,
            });
        }
        return response;
    };
};

export const getAllUsersLogs = (limit, pageNumber, search) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&search=${search}` : ''; // Conditionally add the search query

        const response = await Instance(
            getState, // state
            "GET", // method
            UserApiEndpoint.GET_USER_LOGS_API + `?page=${pageNumber}&limit=${limit}` + searchParam, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { users } = response?.data;

            dispatch({
                type: userActions.GET_USER_LOGS,
                payload: users,
            });
        }
        return response;
    };
};

export const getUsers = () => {
    return async (dispatch, getState) => {
        const response = await Instance(
            getState, // state
            "GET", // method
            UserApiEndpoint.GET_USER_LIST_API + `?page=${0}`, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { users } = response?.data;

            dispatch({
                type: userActions.GET_ALL_USERS,
                payload: users,
            });
        }
        return response;
    };
}


export const getSelectedUser = (data) => {
    return async (dispatch) => {

        return dispatch({
            type: userActions.GET_SELECTED_USER,
            payload: data,
            status: 200,
            success: true
        });
    };
};

export const updateUserDetails = (params) => {
    return async (dispatch, getState) => {
        const response = await Instance(
            getState,
            "PUT",
            UserApiEndpoint.UPDATE_USER_DETAILS_API,
            params,
            true,
            false
        );

        return response;
    };
};

export const deleteUserAccount = (params) => {
    return async (dispatch, getState) => {
        const response = await Instance(
            getState,
            "DELETE",
            UserApiEndpoint.DELETE_USER_API,
            params,
            true,
            false
        );

        return response;
    };
};