import { userActions } from "../actions/actionTypes";

const getInitialState = () => ({
  users: [],
  usersLogs:[],
  allUsers: [],
  selectedUser: {},
});

export const userReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case userActions.GET_USER_LIST:
      return {
        ...state,
        users: action.payload,
      };

    case userActions.GET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case userActions.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };

    case userActions.GET_USER_LOGS:
      return {
        ...state,
        usersLogs: action.payload,
      };
 
    case userActions.AUTH_LOGOUT:
      return {
        ...state,
        users: [],
        usersLogs:[],
        allUsers: [],
        selectedUser: {},
      };

    default:
      return state;
  }
};
