import React from "react";
import { ProgressBar, RotatingLines, ThreeDots } from "react-loader-spinner";

export const ButtonLoader = ({ color }) => {
  return (
    <span className="button-loader">
      <RotatingLines
        strokeColor={color ? color : "white"}
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />
    </span>
  );
};

export const DataLoader = () => {
  return (
    <div className="d-flex justify-content-center">
      <ThreeDots
        height="50"
        width="50"
        radius="9"
        color="#0070c0"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export const ModalDataLoader = () => {
  return (
    <div className="d-flex justify-content-center">
      <ProgressBar
        height="60"
        width="60"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor="#19be6f"
        barColor="#19be6f"
      />
    </div>
  );
};
