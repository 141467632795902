import { quoteActions } from "../actions/actionTypes";

const getInitialState = () => ({
  screenAreas: [],
  selectedScreenArea: {},
  quotes: [],
});

export const quoteReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case quoteActions.GET_SCREENS_AREAS:
      return {
        ...state,
        screenAreas: action.payload,
      };

    case quoteActions.GET_SELECTED_SCREEN:
      return {
        ...state,
        selectedScreenArea: action.payload,
      };
    case quoteActions.GET_QUOTES:
      return {
        ...state,
        quotes: action.payload,
      };

    case quoteActions.AUTH_LOGOUT:
      return {
        ...state,
        screenAreas: [],
        selectedScreenArea: {},
        quotes: [],
      };

    default:
      return state;
  }
};
