import { QuotesApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { quoteActions } from "./actionTypes";

export const getScreenAreas = (search) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&title=${search}` : ''; // Conditionally add the search query

        const response = await Instance(
            getState, // state
            "GET", // method
            QuotesApiEndpoint.GET_ALL_SCREEN_AREAS_API + searchParam, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: quoteActions.GET_SCREENS_AREAS,
                payload: data,
            });
        }
        return response;
    };
};

export const getQuotesByAreaId = (areaId, pageNumber, limit, search) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&quote=${search}` : ''; // Conditionally add the search query

        const response = await Instance(
            getState, // state
            "GET", // method
            QuotesApiEndpoint.GET_ALL_QUOTES_API + `${areaId}?page=${pageNumber}&limit=${limit}` + searchParam, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: quoteActions.GET_QUOTES,
                payload: data,
            });
        }
        return response;
    };
};

export const getSelectedScreen = (screenAreaDetails) => {
    return async (dispatch) => {
        return dispatch({
            type: quoteActions.GET_SELECTED_SCREEN,
            payload: screenAreaDetails,
            status: 200,
            success: true,
        });
    };
}

export const addQuote = (params) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "POST", // method
            QuotesApiEndpoint.ADD_UPDATE_QUOTE_API,// api-endpoint
            params, // data/params
            true // isAuth
        );

        return response;
    };
};
