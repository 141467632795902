import React from "react";
import { IMAGES } from "../../static";

export const SearchBar = ({ value, setSearchKeyword, onClick }) => {
  return (
    <>
      <div className="searchFilter">
        <img
          src={IMAGES.searchingIcon}
          alt=""
          className={!value ? "cursor-no-drop" : ""}
          onClick={() => {
            if (value && value.length >= 1) {
              onClick();
            }
          }}
        />
        <input
          type="text"
          value={value}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
            if(!e.target.value)
            {
              sessionStorage.removeItem('searchKeyword');
            }
          }}
          placeholder="Search...."
        />
      </div>
    </>
  );
};
