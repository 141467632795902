import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLinks } from "../common/store/actions/authActions";

export const PrivacyPolicyView = () => {

    const dispatch = useDispatch();
    const link = useSelector(({ auth }) => auth.links);

    useEffect(() => {
        dispatch(getLinks());
    }, [dispatch])

    return (
        <>
            <div className="policy-section">
                <div className="container-fluid">

                    <div className="policy-heading">
                        <h1> Privacy Policy</h1>
                        <p className="policy-subheading">
                            Last Updated On {new Date(link?.updatedDate).toLocaleDateString(undefined, { year: "numeric", month: "long" })}
                        </p>
                    </div>

                    <ol>
                        <li>
                            Policy
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                        <li>Lorem ipsum, or lipsume
                            <p>
                                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                                used in laying out print, graphic or web designs. The passage is
                                attributed to an unknown typesetter. The passage experienced a
                                surge in popularity during the 1960s when Letraset used it on
                                their dry-transfer sheets, and again during the 90s as desktop
                                publishers bundled the text with their software.
                            </p>
                        </li>
                    </ol>
                </div>
            </div>

        </>
    );
};
