import React from "react";

export const FooterView = () => {

    const year = new Date()?.getFullYear();

    return (
        <>
            <div className="footer-section">
                <p>Copyright © {year} ByteCipher Pvt. All Rights Reserved.</p>
            </div>
        </>
    )
}