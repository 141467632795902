import { FocusAreaApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { focusAreaActions } from "./actionTypes";

export const getFocusAreaList = (limit, pageNumber, search) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&title=${search}` : ''; // Conditionally add the search query
        
        const response = await Instance(
            getState, // state
            "GET", // method
            FocusAreaApiEndpoint.GET_FOCUS_AREA_LIST_API + `?page=${pageNumber}&limit=${limit}` + searchParam, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: focusAreaActions.GET_FOCUS_AREA_LIST,
                payload: data,
            });
        }
        return response;
    };
};


export const getFocusAreaListById = (limit, pageNumber, search, abundanceAreaId) => {
    return async (dispatch, getState) => {
        const searchParam = search ? `&title=${search}` : ''; // Conditionally add the search query
        const abundanceParams = abundanceAreaId ? `&abundanceAreaId=${abundanceAreaId}` : '';

        const response = await Instance(
            getState, // state
            "GET", // method
            FocusAreaApiEndpoint.GET_FOCUS_AREA_LIST_API + `?page=${pageNumber}&limit=${limit}` + searchParam + abundanceParams, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: focusAreaActions.GET_FOCUS_AREA_LIST,
                payload: data,
            });
        }
        return response;
    };
};

export const addFocusArea = (params) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "POST", // method
            FocusAreaApiEndpoint.ADD_FOCUS_AREA_API,// api-endpoint
            params, // data/params
            true // isAuth
        );

        return response;
    };
};

export const editFocusArea = (params) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "PUT", // method
            FocusAreaApiEndpoint.UPDATE_FOCUS_AREA_API,// api-endpoint
            params, // data/params
            true // isAuth
        );

        return response;
    };
};

export const deleteFocusArea = (id) => {
    return async (dispatch, getState) => {

        const response = await Instance(
            getState, // state
            "DELETE", // method
            FocusAreaApiEndpoint.DELETE_FOCUS_AREA_API + id,// api-endpoint
            null, // data/params
            true // isAuth
        );

        return response;
    };
};

export const getSelectedFocusArea = (focusAreaDetails) => {
    return async (dispatch) => {
        return dispatch({
            type: focusAreaActions.SELECTED_FOCUS_AREA,
            payload: focusAreaDetails,
            status: 200,
            success: true,
        });
    };
}
