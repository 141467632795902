import { actionAreaActions } from "../actions/actionTypes";

const getInitialState = () => ({
  actionAreaList: [],
  selectedActionArea: {},
});

export const actionAreaReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case actionAreaActions.GET_ACTION_AREA_LIST:
      return {
        ...state,
        actionAreaList: action.payload,
      };
    case actionAreaActions.SELECTED_ACTION_AREA:
      return {
        ...state,
        selectedActionArea: action.payload,
      };

    case actionAreaActions.AUTH_LOGOUT:
      return {
        ...state,
        actionAreaList: [],
        selectedActionArea: {},
      };

    default:
      return state;
  }
};
