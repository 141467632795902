import { ActionAreaApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { actionAreaActions } from "./actionTypes";

export const getActionAreaList = (limit, pageNumber, search, focusAreaId) => {
  return async (dispatch, getState) => {
    const searchParam = search ? `&title=${search}` : ""; // Conditionally add the search query

    const response = await Instance(
      getState, // state
      "GET", // method
      ActionAreaApiEndpoint.GET_ACTION_AREA_LIST_API +
      `?page=${pageNumber}&limit=${limit}` +
      searchParam, // api-endpoint
      null, // data/params
      true // isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      const { data } = response?.data;
      dispatch({
        type: actionAreaActions.GET_ACTION_AREA_LIST,
        payload: data,
      });
    }
    return response;
  };
};

export const getActionAreaListById = (limit, pageNumber, search, focusAreaId) => {
  return async (dispatch, getState) => {
    const searchParam = search ? `&title=${search}` : ""; // Conditionally add the search query
    const focusParams = focusAreaId ? `&focusAreaId=${focusAreaId}` : "";

    const response = await Instance(
      getState, // state
      "GET", // method
      ActionAreaApiEndpoint.GET_ACTION_AREA_LIST_API +
      `?page=${pageNumber}&limit=${limit}` +
      searchParam +
      focusParams, // api-endpoint
      null, // data/params
      true // isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      const { data } = response?.data;
      dispatch({
        type: actionAreaActions.GET_ACTION_AREA_LIST,
        payload: data,
      });
    }
    return response;
  };
};

export const addActionArea = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, // state
      "POST", // method
      ActionAreaApiEndpoint.ADD_ACTION_AREA_API, // api-endpoint
      params, // data/params
      true // isAuth
    );

    return response;
  };
};

export const editActionArea = (params) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, // state
      "PUT", // method
      ActionAreaApiEndpoint.UPDATE_ACTION_AREA_API, // api-endpoint
      params, // data/params
      true // isAuth
    );

    return response;
  };
};

export const deleteActionArea = (id) => {
  return async (dispatch, getState) => {
    const response = await Instance(
      getState, // state
      "DELETE", // method
      ActionAreaApiEndpoint.DELETE_ACTION_AREA_API + id, // api-endpoint
      null, // data/params
      true // isAuth
    );

    return response;
  };
};

export const getSelectedActionArea = (actionAreaDetails) => {
  return async (dispatch) => {
    return dispatch({
      type: actionAreaActions.SELECTED_ACTION_AREA,
      payload: actionAreaDetails,
      status: 200,
      success: true,
    });
  };
};
