import { InstructionsApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { instructionActions } from "./actionTypes";

export const getInstructionScreenAreas = (search) => {
    return async (dispatch, getState) => {
        const response = await Instance(
            getState, // state
            "GET", // method
            InstructionsApiEndpoint.GET_ALL_SCREEN_AREAS_API, // api-endpoint
            null, // data/params
            true // isAuth
        );

        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            // const screenAreas = ["Discovery Stage Artifact Screen", "Smart Action Creator", "Vision Stage Artifact screen"];
            dispatch({
                type: instructionActions.GET_INSTRUCTION_SCREENS_AREAS,
                // payload: data?.filter((area) => screenAreas?.includes(area?.areaName)),
                payload:data
            });
        }
        return response;
    };
};

export const getInstructions = () => {
    return async (dispatch, getState) => {
        const response = await Instance(
            getState, // state
            "GET", // method
            InstructionsApiEndpoint.GET_INSTRUCTIONS_LIST_API, // api-endpoint
            null, // data/params
            true // isAuth
        );
        if (response?.status === 200 && response?.data?.success) {
            const { data } = response?.data;
            dispatch({
                type: instructionActions.GET_INSTRUCTIONS,
                payload: data,
            });
        }
        return response;
    };
};

export const getSelectedInstructionArea = (screenAreaDetails) => {
    return async (dispatch) => {
        return dispatch({
            type: instructionActions.GET_SELECTED_INSTRUCTION_AREA,
            payload: screenAreaDetails,
            status: 200,
            success: true,
        });
    };
};

export const addInstruction = (params) => {
    return async (dispatch, getState) => {
        const response = await Instance(
            getState, // state
            "POST", // method
            InstructionsApiEndpoint.ADD_JOURNEY_INSTRUCTIONS_API, // api-endpoint
            params, // data/params
            true // isAuth
        );
        return response;
    };
};
