import { authActions, credentialsActions } from "../actions/actionTypes";

const getInitialState = () => ({
  loginUser: {},
  userType: "",
  userEmail: "",
  dashboardData: {},
  links: {},
  awsCredentials: {},
  // activeIndex: "0",
});

export const authReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case authActions.AUTH_LOGIN:
      return {
        ...state,
        loginUser: action.payload,
        userType: action.payload.userType,
      };

      case authActions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loginUser: {
          ...state.loginUser,
          accessToken: action.payload,
        },
      };

    case authActions.AUTH_SEND_OTP:
      return {
        ...state,
        userEmail: action.payload,
      };

    case authActions.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case authActions.GET_LINKS:
      return {
        ...state,
        links: action.payload,
      };

    case credentialsActions.GET_CREDENTIALS:
      return {
        ...state,
        awsCredentials: action.payload,
      };

    // case authActions.SET_ACTIVE_INDEX:
    //   return {
    //     ...state,
    //     activeIndex: action.payload,
    //   };

    case authActions.AUTH_LOGOUT:
      return {
        ...state,
        loginUser: {},
        userType: "",
        userEmail: "",
        dashboardData: {},
        links: {},
        awsCredentials: {},
        // activeIndex: "0",
      };

    default:
      return state;
  }
};
