import React from "react";
import { IMAGES } from "../static";

// pagination component with conditional rendering
export const PaginationView = ({ totalPages, pageNumber, setPageNumber }) => {
  const pageNumbers =
    totalPages >= 1 ? [...Array(totalPages + 1)?.keys()]?.slice(1) : 0;

  const nextPage = () => {
    if (pageNumber !== totalPages && totalPages !== 1)
      setPageNumber(pageNumber + 1);
  };
  const prevPage = () => {
    if (pageNumber !== 1) setPageNumber(pageNumber - 1);
  };

  return (
    <>
      <ul>
        <li
          onClick={prevPage}
          className={pageNumber === 1 ? "cursor-no-drop" : ""}
        >
          <img src={IMAGES.back_Shape} alt="" className="tickActive" />
          <img src={IMAGES.back_ShapeRight} alt="" className="tickInctive" />
        </li>

        {pageNumbers?.length >= 0 ? (
          pageNumbers?.map((pgNumber) => (
            <li
              key={pgNumber}
              onClick={() => setPageNumber(pgNumber)}
              className={pageNumber === pgNumber ? "active" : ""}
            >
              {pgNumber <= 9 ? "0" + pgNumber : pgNumber}
            </li>
          ))
        ) : (
          <li className="p-1 active">01</li>
        )}

        <li
          className={pageNumber === totalPages ? "cursor-no-drop" : ""}
          onClick={nextPage}
        >
          <img src={IMAGES.back_Shape} alt="" className="tickActive" />
          <img src={IMAGES.back_ShapeRight} alt="" className="tickInctive" />
        </li>
      </ul>
    </>
  );
};
