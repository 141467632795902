import { JourneyApiEndpoint } from "../apiEndpoints";
import { Instance } from "../apiServices";
import { journeyActions } from "./actionTypes";

export const getAllJourneys = (limit, pageNumber, status, search, userId) => {
  return async (dispatch, getState) => {
    const searchParam = search ? `&search=${search}` : ""; // Conditionally add the search query
    const statusParam = status ? `&status=${status}` : "";
    const userIdParam = userId ? `&userId=${userId}` : "";
    const response = await Instance(
      getState, // state
      "GET", // method
      JourneyApiEndpoint.GET_ALL_JOURNEYS_API +
        `?page=${pageNumber}&limit=${limit}` +
        statusParam +
        searchParam +
        userIdParam, // api-endpoint
      null, // data/params
      true // isAuth
    );

    if (response?.status === 200 && response?.data?.success) {
      const { journeys } = response?.data;
      dispatch({
        type: journeyActions.GET_JOURNEY_LIST,
        payload: journeys,
      });
    }
    return response;
  };
};

export const getSelectedJourney = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: journeyActions.GET_SELECTED_JOURNEY,
      payload: data,
      status: 200,
      success: true,
    });
  };
};

export const journeyStatus = (data) => {
  return async (dispatch) => {
    return dispatch({
      type: journeyActions.GET_JOURNEY_STATUS,
      payload: data,
      status: 200,
      success: true,
    });
  };
};
