import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";

import { IMAGES } from "../static";
import { ButtonLoader, ValidationError } from "../components";
import { doAuthSendOtp } from "../common/store/actions/authActions";

export const ForgotPasswordView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // loader states
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const ForgotPasswordFormik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t("Error.valid_email")).required(t("Error.email_required")),
        }),
        onSubmit: async (values) => {

            if (ForgotPasswordFormik.isValid && ForgotPasswordFormik.dirty) {
                setIsSubmitLoader(true);

                dispatch(doAuthSendOtp(values)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message);
                        navigate("/verify-code");
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message);
                    }
                });

            }
        },
    });

    return (
        <>
            <div className="cover-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="align-self-center d-flex form-section">
                                <div className="effect-box">

                                    <div className="logo_section">
                                        <img src={IMAGES.logo} alt="" />
                                    </div>

                                    <div className="cover-box-txt">
                                        <h1><button onClick={()=> navigate("/")}><img src={IMAGES.back_Shape} alt=""/></button> {t("ForgotPassword.forgot_password")}</h1>
                                        <div className="form-card">
                                            <form method="POST" onSubmit={ForgotPasswordFormik.handleSubmit}>

                                                <div className="form-group">
                                                    <label>Email</label>

                                                    <input type="text" className="form-control" placeholder={t("ForgotPassword.email_placeholder")} name="email" value={ForgotPasswordFormik?.values?.email}
                                                        onChange={ForgotPasswordFormik.handleChange} onBlur={ForgotPasswordFormik.handleBlur} autoComplete="false" />

                                                    {ForgotPasswordFormik.touched.email && ForgotPasswordFormik.errors.email && (
                                                        <ValidationError error={ForgotPasswordFormik.errors.email} />
                                                    )}

                                                </div>

                                                <div className="footer-buttons">
                                                    <div className="form-group">
                                                        <button type="submit" disabled={isSubmitLoader}>
                                                            {isSubmitLoader ?
                                                                <span className="d-flex justify-content-between">
                                                                    <ButtonLoader />
                                                                    {t("Loading.please_wait")}
                                                                </span>
                                                                :
                                                                t("Buttons.send_code")
                                                            }
                                                        </button>
                                                        {/* <p className="back-button" onClick={() => navigate("/")}>{t("Buttons.back_to_login")}</p> */}
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="rightAuthbx">
                                <img src={IMAGES.login_Auth_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
