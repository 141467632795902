import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';

import { ButtonLoader, DataLoader, HeaderView, PopupModal, SearchBar, SidebarPanel, ValidationError } from "../components";
import { IMAGES } from "../static";
import { PaginationView } from "../common";
import { dropDownLimits } from "../utils/helperFunctions";
import { addFocusArea, deleteFocusArea, editFocusArea, getFocusAreaList, getFocusAreaListById, getSelectedFocusArea } from "../common/store/actions/focusAreaActions";
import { getActionAreaListById } from "../common/store/actions/actionAreaActions";

export const FocusAreaView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { abundanceAreaId } = useParams();
    const focusAreaList = useSelector(({ focusArea }) => focusArea?.focusAreaList);
    const selectedAbundanceArea = useSelector(({ abundanceArea }) => abundanceArea?.selectedAbundanceArea);

    const [showBody, setShowBody] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);

    // loader states
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    // loader states

    const tableHeaders = ["#", t('TableHeader.title'), t('TableHeader.description'), t('TableHeader.help_text'), t('TableHeader.created_by'), t('TableHeader.status'), t('TableHeader.actions'),];

    const [limit, setLimit] = useState(dropDownLimits[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searched, setSearched] = useState(false);
    const [selectedFocusArea, setSelectedFocusArea] = useState({})

    const handleSelectedFocusArea = (focusAreaDetails) => {
        setSelectedFocusArea(focusAreaDetails);
        EditFocusAreaFormik.setValues({
            title: focusAreaDetails.title,
            status: focusAreaDetails.status,
            description: focusAreaDetails?.description,
            helpText: focusAreaDetails?.helpText
        });
        setEditModal(true);
    }

    const handleViewFocusArea = (focusAreaDetails) => {
        setSelectedFocusArea(focusAreaDetails)
        dispatch(getSelectedFocusArea(focusAreaDetails));
        setViewModal(true);
        // dispatch(getActionAreaListById(10, 1, null, focusAreaDetails?._id)).then((res) => {
        //     navigate("/action-area-management");
        // })
    }

    const handleNavigateActionArea = (focusArea) => {
        setSelectedFocusArea(focusArea);
        dispatch(getSelectedFocusArea(focusArea));
        dispatch(getActionAreaListById(10, 1, null, selectedFocusArea?._id)).then((res) => {
            // navigate("/action-area-management");
            navigate(`/action-area-management/${focusArea?._id}`);
        });
    }

    // const handleActionAreaNavigation = () => {
    //     setIsSubmitLoader(true);
    //     dispatch(getActionAreaListById(10, 1, null, selectedFocusArea?._id)).then((res) => {
    //         setIsSubmitLoader(false);
    //         // navigate("/action-area-management");
    //         navigate(`/action-area-management/${selectedFocusArea?._id}`);
    //     });
    // }

    const handleDeleteModal = (focusAreaDetails) => {
        setSelectedFocusArea(focusAreaDetails);
        setDeleteModal(true);
    }
    

    const handleDeleteFocusArea = () => {
        setIsSubmitLoader(true);
        dispatch(deleteFocusArea(selectedFocusArea?._id)).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
                setIsLoading(true);
                setIsSubmitLoader(false);
                toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                fetchFocusArea();
                setDeleteModal(false);
                AddFocusAreaFormik.resetForm();
            }
            else {
                setIsSubmitLoader(false);
                toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                setDeleteModal(false);
            }
        })
    }

    const handleSearch = () => {
        fetchFocusArea(searchKeyword);
        setSearched(true);
    };

    const AddFocusAreaFormik = useFormik({
        initialValues: {
            abundanceAreaId: selectedAbundanceArea?._id,
            title: "",
            status: "active",
            description: '',
            helpText: ''
        },
        validationSchema: Yup.object({
            abundanceAreaId: Yup.string().required("Abundance area is required!"),
            title: Yup.string().required("Title is required!"),
            status: Yup.string().required("Status is required!"),
            description: Yup.string(),
            helpText: Yup.string()
        }),
        onSubmit: async (values) => {

            if (AddFocusAreaFormik.isValid && AddFocusAreaFormik.dirty) {
                setIsSubmitLoader(true);
                dispatch(addFocusArea(values)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        setIsLoading(true);
                        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                        fetchFocusArea();
                        setAddModal(false);
                        AddFocusAreaFormik.resetForm();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                        setAddModal(false);
                    }
                })
            }
        },
    })

    const EditFocusAreaFormik = useFormik({
        initialValues: {
            // abundanceAreaId: "",
            title: "",
            status: "active",
            description: '',
            helpText: ''
        },
        validationSchema: Yup.object({
            // abundanceAreaId: Yup.string().required("Abundance area is required!"),
            title: Yup.string().required("Title is required!"),
            status: Yup.string().required("Status is required"),
            description: Yup.string(),
            helpText: Yup.string()
        }),
        onSubmit: async (values) => {

            if (EditFocusAreaFormik.isValid) {
                setIsSubmitLoader(true);

                const params = {
                    ...values,
                    id: selectedFocusArea?._id
                }
                dispatch(editFocusArea(params)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        setIsLoading(true);
                        toastr.success("Success", res?.data?.message || res?.data?.data?.message);
                        fetchFocusArea();
                        setEditModal(false);
                        EditFocusAreaFormik.resetForm();
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message || res?.data?.data?.message);
                        setEditModal(false);
                    }
                })
            }
        },
    })

    const fetchFocusArea = (search = null) => {
        setIsLoading(true);
        dispatch(abundanceAreaId ? getFocusAreaListById(limit, pageNumber, search, abundanceAreaId) : getFocusAreaList(limit, pageNumber, search)).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                // toastr.error("Error", res?.data?.message);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                const { limit, totalItems, totalPages } = res?.data;
                setCount(limit ? limit : 0);
                setTotalEntries(totalItems ? totalItems : 0);
                setTotalPages(totalPages ? totalPages : 0);
            }
        });
    }

    const handleCloseAddModal = () => {
        setAddModal(false);
        AddFocusAreaFormik.resetForm();
    }

    const handleCloseEditModal = () => {
        setEditModal(false);
        EditFocusAreaFormik.resetForm();
        setSelectedFocusArea("");
    }

    const handleCloseViewModal = () => {
        setViewModal(false);
        setSelectedFocusArea("");
    }

    const handleCloseDeleteModal = () => {
        setDeleteModal(false);
        setSelectedFocusArea("");
    }

    useEffect(() => {
        fetchFocusArea();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, limit, dispatch])

    useEffect(() => {
        if (searchKeyword.length === 0 && searched) {
            fetchFocusArea();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword])

    return (
        <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
            <HeaderView
                headingName={"Focus Area"}
                setShowBody={setShowBody}
                showBody={showBody}
            />

            <div className="wrapper">
                <SidebarPanel setShowBody={setShowBody} showBody={showBody} />
                <div className="main-container">
                    <div className="betcreBx">
                        <ul>
                            <li><button onClick={() => navigate("/dashboard")}>Dashboard</button></li>
                            <li>/</li>
                            <li><button onClick={() => navigate("/abundance-area-management")}>Abundance Area</button></li>
                            <li>/</li>
                            <li className="active">Focus Area</li>
                        </ul>
                    </div>
                    <div className="table-container mainCustomBG userDetail-container">
                        <div className="main-heading">
                            <h1>
                                {abundanceAreaId && `Abundance Area: ${selectedAbundanceArea?.title}`}
                                &nbsp;
                                {abundanceAreaId &&
                                    (
                                        <span className="d-flex"><button type="button" className="button-back mr-2" onClick={() => { setAddModal(true) }}>
                                            <img src={IMAGES.plus_icon} alt="" />
                                            {t("Buttons.add")}
                                        </button>
                                            <button type="button" className="button-back" onClick={() => { navigate(abundanceAreaId ? `/abundance-area-management` : "/dashboard") }}><img src={IMAGES.rightErrow} alt="" />
                                                {t('Buttons.back')}
                                            </button>
                                        </span>
                                    )
                                }
                            </h1>
                        </div>

                        <div className="headingTbL filterResponsiveCus filterMain">
                            <Dropdown drop className="dropdown filtstatus" onSelect={(e) => {
                                setLimit(e);
                            }}>
                                <span className="sortingShow">{t('Filterbar.show')}
                                    <Dropdown.Toggle>
                                        <span>{limit}</span>
                                        <img src={IMAGES.filter_dropdown_icon} alt="" />
                                    </Dropdown.Toggle>
                                    {t('Filterbar.enteries')}
                                </span>
                                <Dropdown.Menu
                                    variant=""
                                    id="style-5"
                                    className="cusDrupdown"
                                >
                                    {dropDownLimits?.map((limit, index) => (
                                        <Dropdown.Item eventKey={limit} key={`limit+${index}`}>
                                            {limit}
                                        </Dropdown.Item>
                                    ))}

                                </Dropdown.Menu>
                            </Dropdown>

                            <span className="search-container">
                                <SearchBar name="Search" value={searchKeyword} setSearchKeyword={setSearchKeyword} onClick={() => {
                                    handleSearch()
                                }} />
                            </span>

                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {tableHeaders?.map((heading, index) => (
                                            <th key={heading} className={tableHeaders.length - 1 === index ? "" : ""}>
                                                {heading}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <tr>
                                            <td colSpan={tableHeaders.length} className="text-center tableE_Caption">
                                                <DataLoader />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {focusAreaList?.length > 0 ? focusAreaList?.map((focusArea, index) => (
                                                <tr key={focusArea._id}>
                                                    <td>{(parseInt(limit * (pageNumber - 1) + index + 1) < 10) ? "0" + (limit * (pageNumber - 1) + index + 1) : (limit * (pageNumber - 1) + index + 1)}</td>
                                                    <td className="highLight-text" onClick={() => {
                                                        handleNavigateActionArea(focusArea);
                                                    }}>
                                                        {focusArea.title ? focusArea.title : "N/A"}
                                                    </td>
                                                    <td className="ellipsis-text">
                                                        {focusArea.description
                                                            ? focusArea.description
                                                            : "N/A"}
                                                    </td>
                                                    <td className="ellipsis-text">
                                                        {focusArea.helpText
                                                            ? focusArea.helpText
                                                            : "N/A"}
                                                    </td>
                                                    <td >
                                                        {focusArea.userId ? focusArea.userId?.userName ? focusArea.userId?.userName : focusArea?.type : focusArea?.type}
                                                    </td>
                                                    <td className={`font-weight-bold ${focusArea.status === "active" ? "activeclr" : "inactiveclr"}`}>
                                                        {focusArea.status}
                                                    </td>
                                                    <td className="">
                                                        <button className="icon-button" title="View" onClick={() => { handleViewFocusArea(focusArea) }}> <img src={IMAGES.view_icon} alt="" />
                                                        </button>
                                                        <button className="icon-button" title="Edit" onClick={() => { handleSelectedFocusArea(focusArea) }}><img src={IMAGES.edit_icon} alt="" /></button>
                                                        <button className="icon-button" title="Delete" onClick={() => { handleDeleteModal(focusArea) }}><img src={IMAGES.delete_icon} alt="Delete" /></button>
                                                        <button type="button" title="Go To Action Area" onClick={() => handleNavigateActionArea(focusArea)}><img src={IMAGES.rightTickImg} alt="" /></button>
                                                    </td>
                                                </tr>
                                            )) :
                                                <tr>
                                                    <td colSpan={tableHeaders.length} className="text-center">
                                                        {t('NotFound.focus_record')}
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="table-pagination">
                            <div className="col-md-6 show_entrieNum">
                                <p>
                                    {t('Pagination.showing')} {count > 0 ? 1 : 0} {t('Pagination.to')} {limit} {t('Pagination.of')} {totalEntries} {t('Pagination.entries')}
                                </p>
                            </div>
                            <div className="col-md-6 pagination">
                                <PaginationView
                                    totalPages={totalPages}
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {addModal &&
                <PopupModal className={"areaModal"} show={addModal} onHide={() => { handleCloseAddModal() }}>
                    <form method="POST" onSubmit={AddFocusAreaFormik.handleSubmit}>
                        <h1>{t('ModalTitles.add_focus_area')}</h1>
                        {/* <div className="row">
                            <div className="form-group">
                                <label>Abundance Area  <span>*</span></label>
                                <Dropdown drop className="dropdown" onSelect={(e) => {
                                    AddFocusAreaFormik.setFieldValue('abundanceAreaId', e)
                                }}  >
                                    <Dropdown.Toggle name="abundanceAreaId" onChange={AddFocusAreaFormik.handleChange} onBlur={AddFocusAreaFormik.handleBlur}
                                        value={AddFocusAreaFormik.values.abundanceAreaId}>
                                        <span>{selectedAbundanceArea?.title ? selectedAbundanceArea?.title : "Select Abundance Area"}</span>
                                        <img src={IMAGES.filter_dropdown_icon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="" className="cusDrupdown" onSelect={AddFocusAreaFormik.handleChange}>
                                        {allAbundanceArea?.length > 0 ?
                                            allAbundanceArea?.map((abundanceArea, index) => (
                                                <Dropdown.Item eventKey={abundanceArea?._id} key={`abundanceArea+${index}`} onClick={() => {
                                                    setSelectedAbundanceArea(abundanceArea)
                                                }}>
                                                    {abundanceArea?.title}
                                                </Dropdown.Item>
                                            ))
                                            :
                                            <Dropdown.Item eventKey={" No Abundance Area"} key={" No Abundance Area"} disabled>
                                                No Abundance Area
                                            </Dropdown.Item>
                                        }

                                    </Dropdown.Menu>
                                </Dropdown>
                                {AddFocusAreaFormik.touched.abundanceAreaId && AddFocusAreaFormik.errors.abundanceAreaId && (
                                    <ValidationError error={AddFocusAreaFormik.errors.abundanceAreaId} />
                                )}
                            </div>

                        </div> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Focus.title_label')}<span>*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter title" name="title"
                                        onChange={AddFocusAreaFormik.handleChange} onBlur={AddFocusAreaFormik.handleBlur}
                                        value={AddFocusAreaFormik.values.title} />
                                    {AddFocusAreaFormik.touched.title && AddFocusAreaFormik.errors.title && (
                                        <ValidationError error={AddFocusAreaFormik.errors.title} />
                                    )}

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Focus.status_label')} </label>
                                    <Dropdown drop className="dropdown" onSelect={(e) => {
                                        AddFocusAreaFormik.setFieldValue("status", e);
                                    }} >
                                        <Dropdown.Toggle disabled name="slotId" onChange={AddFocusAreaFormik.handleChange} onBlur={AddFocusAreaFormik.handleBlur}
                                            value={AddFocusAreaFormik.values.slotId}>
                                            <span>{AddFocusAreaFormik.values.status}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu variant="" className="cusDrupdown" >
                                            <Dropdown.Item eventKey={"active"} key={"active"}>
                                                Active
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey={"retrieve"} key={"retrieve"}>
                                                Retrieve
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {AddFocusAreaFormik.touched.status && AddFocusAreaFormik.errors.status && (
                                        <ValidationError error={AddFocusAreaFormik.errors.status} />
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.description_label')} </label>
                                    <textarea className="form-control" name="description" rows={4} value={AddFocusAreaFormik.values.description}
                                        onChange={AddFocusAreaFormik.handleChange} onBlur={AddFocusAreaFormik.handleBlur}>

                                    </textarea>
                                    {AddFocusAreaFormik.touched.description &&
                                        AddFocusAreaFormik.errors.description && (
                                            <ValidationError
                                                error={AddFocusAreaFormik.errors.description}
                                            />
                                        )}
                                </div>

                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.help_text_label')} </label>
                                    <textarea className="form-control" name="helpText" rows={3} value={AddFocusAreaFormik.values.helpText}
                                        onChange={AddFocusAreaFormik.handleChange} onBlur={AddFocusAreaFormik.handleBlur}>
                                    </textarea>
                                    {AddFocusAreaFormik.touched.helpText &&
                                        AddFocusAreaFormik.errors.helpText && (
                                            <ValidationError
                                                error={AddFocusAreaFormik.errors.helpText}
                                            />
                                        )}
                                </div>

                            </div>
                        </div>

                        <div className="footerBtnAll mt-3">
                            <button onClick={() => handleCloseAddModal()}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    t("Buttons.submit")
                                }
                            </button>
                        </div>
                    </form>
                </PopupModal>
            }

            {editModal &&
                <PopupModal className={"areaModal"} show={editModal} onHide={() => { handleCloseEditModal() }}>
                    <form method="POST" onSubmit={EditFocusAreaFormik.handleSubmit}>
                        <h1>{t('ModalTitles.edit_focus_area')}</h1>
                        {/* <div className="row">
                            <div className="form-group">
                                <label>Abundance Area  <span>*</span></label>
                                <Dropdown drop className="dropdown" aria-readonly onSelect={(e) => {
                                    EditFocusAreaFormik.setFieldValue('abundanceAreaId', e)
                                }}  >
                                    <Dropdown.Toggle name="abundanceAreaId" onChange={EditFocusAreaFormik.handleChange} onBlur={EditFocusAreaFormik.handleBlur}
                                        value={EditFocusAreaFormik.values.abundanceAreaId}>
                                        <span>{selectedFocusArea?.abundanceAreaId?.title ? selectedFocusArea?.abundanceAreaId?.title : "Select Abundance Area"}</span>
                                        <img src={IMAGES.filter_dropdown_icon} alt="" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu variant="" className="cusDrupdown" onSelect={EditFocusAreaFormik.handleChange}>
                                        {allAbundanceArea?.length > 0 ?
                                            allAbundanceArea?.map((abundanceArea, index) => (
                                                <Dropdown.Item disabled eventKey={abundanceArea?._id} key={`abundanceArea+${index}`} onClick={() => {
                                                    setSelectedAbundanceArea(abundanceArea)
                                                }}>
                                                    {abundanceArea?.title}
                                                </Dropdown.Item>
                                            ))
                                            :
                                            <Dropdown.Item eventKey={" No Abundance Area"} key={" No Abundance Area"} disabled>
                                                You can't modify abundance area
                                            </Dropdown.Item>
                                        }
                                        <Dropdown.Item eventKey={"No Abundance Area"} key={" No Abundance Area"} disabled>
                                            You can't modify abundance area
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                                {EditFocusAreaFormik.touched.abundanceAreaId && EditFocusAreaFormik.errors.abundanceAreaId && (
                                    <ValidationError error={EditFocusAreaFormik.errors.abundanceAreaId} />
                                )}
                            </div>

                        </div> */}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Focus.title_label')} <span>*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter title" name="title"
                                        onChange={EditFocusAreaFormik.handleChange} onBlur={EditFocusAreaFormik.handleBlur}
                                        value={EditFocusAreaFormik.values.title} />
                                    {EditFocusAreaFormik.touched.title && EditFocusAreaFormik.errors.title && (
                                        <ValidationError error={EditFocusAreaFormik.errors.title} />
                                    )}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Focus.status_label')} </label>
                                    <Dropdown drop className="dropdown" onSelect={(e) => {
                                        EditFocusAreaFormik.setFieldValue("status", e);
                                    }} >
                                        <Dropdown.Toggle disabled name="status" onChange={EditFocusAreaFormik.handleChange} onBlur={EditFocusAreaFormik.handleBlur}
                                            value={EditFocusAreaFormik.values.status}>
                                            <span>{EditFocusAreaFormik.values.status}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu variant="" className="cusDrupdown" >
                                            <Dropdown.Item eventKey={"active"} key={"active"}>
                                                Active
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey={"retrieve"} key={"retrieve"}>
                                                Retrieve
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {EditFocusAreaFormik.touched.status && EditFocusAreaFormik.errors.status && (
                                        <ValidationError error={EditFocusAreaFormik.errors.status} />
                                    )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.description_label')}</label>
                                    <textarea className="form-control" name="description" rows={4} value={EditFocusAreaFormik.values.description}
                                        onChange={EditFocusAreaFormik.handleChange} onBlur={EditFocusAreaFormik.handleBlur}>
                                    </textarea>
                                    {EditFocusAreaFormik.touched.description &&
                                        EditFocusAreaFormik.errors.description && (
                                            <ValidationError
                                                error={EditFocusAreaFormik.errors.description}
                                            />
                                        )}
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.help_text_label')}</label>
                                    <textarea className="form-control" name="helpText" rows={3} value={EditFocusAreaFormik.values.helpText}
                                        onChange={EditFocusAreaFormik.handleChange} onBlur={EditFocusAreaFormik.handleBlur}>
                                    </textarea>
                                    {EditFocusAreaFormik.touched.helpText &&
                                        EditFocusAreaFormik.errors.helpText && (
                                            <ValidationError
                                                error={EditFocusAreaFormik.errors.helpText}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="footerBtnAll mt-3">
                            <button onClick={() => handleCloseEditModal()}
                                className="cancelborder">
                                {t("Buttons.cancel")}
                            </button>
                            <button type="submit" disabled={isSubmitLoader} >
                                {isSubmitLoader ?
                                    <ButtonLoader />
                                    :
                                    "Update"
                                }
                            </button>
                        </div>
                    </form>
                </PopupModal>
            }

            {viewModal && (
                <PopupModal
                    className={"areaModal"}
                    show={viewModal}
                    onHide={() => {
                        handleCloseViewModal();
                    }}
                >
                    <form method="POST">
                        <h1>{t('ModalTitles.view_focus_area')}</h1>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Focus.title_label')} <span>*</span> </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter title"
                                        name="title"
                                        value={selectedFocusArea?.title}
                                        readOnly disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Focus.status_label')} </label>
                                    <Dropdown drop className="dropdown">
                                        <Dropdown.Toggle readOnly disabled name="slotId" value={selectedFocusArea?.status} >
                                            <span>{selectedFocusArea?.status}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.description_label')}</label>
                                    <textarea className="form-control" name="description" rows={4} value={selectedFocusArea.description} readOnly disabled>
                                    </textarea>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.help_text_label')}</label>
                                    <textarea className="form-control" name="helpText" rows={3} value={selectedFocusArea.helpText} readOnly disabled>
                                    </textarea>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Focus.created_by')}</label>
                                    <input type="text" className="form-control text-capitalize" name="userId" value={selectedFocusArea.userId ? selectedFocusArea.userId?.userName : selectedFocusArea?.type} readOnly disabled />
                                </div>
                            </div>
                        </div>
                        {/* <div className="footerBtnAll mt-3">
                            <button onClick={() => handleCloseViewModal()} className="cancelborder" >
                                {t("Buttons.cancel")}
                            </button>
                            <button type="button" disabled={isSubmitLoader} onClick={() => { handleActionAreaNavigation() }}>
                                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.add_action_area")}
                            </button>
                        </div> */}
                    </form>
                </PopupModal>
            )}

            {deleteModal && (
                <PopupModal
                    show={deleteModal}
                    onHide={() => {
                        handleCloseDeleteModal();
                    }}
                >
                    <form>
                        <img src={IMAGES.deleteAccountIcon} alt="" />
                        <p className="praregraphP width50">
                            {t('Confirmation.delete_focus_area')}
                        </p>
                        <div className="footerBtnAll">
                            <button
                                onClick={() => {
                                    handleCloseDeleteModal()
                                }}
                                className="cancelborder"
                            >
                                {t("Buttons.cancel")}
                            </button>
                            <button
                                type="button"
                                disabled={isSubmitLoader}
                                onClick={() => {
                                    handleDeleteFocusArea();
                                }}
                            >
                                {isSubmitLoader ? <ButtonLoader /> : t("Buttons.confirm")}
                            </button>
                        </div>
                    </form>
                </PopupModal>
            )}
        </div>
    )
}
