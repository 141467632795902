import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  DataLoader,
  HeaderView,
  SidebarPanel,
} from "../components";
import { getQuotesByAreaId, getScreenAreas, getSelectedScreen } from "../common/store/actions/quoteActions";
import { IMAGES } from "../static";

export const QuoteManagementView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screenAreas = useSelector(({ quotes }) => quotes?.screenAreas);  

  const [showBody, setShowBody] = useState(false);

  // loader states
  const [isLoading, setIsLoading] = useState(true);
  // loader states
  // const [searchKeyword, setSearchKeyword] = useState("");

  const fetchScreenAreas = () => {
    dispatch(getScreenAreas()).then((res) => {
      if (res?.status !== 200 && !res?.data?.success) {
        toastr.error("Error", res?.data?.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleViewScreenQuotes = async (screenAreaDetails) => {
    // dispatch(getQuotesByAreaId(screenAreaDetails?._id))
    dispatch(getSelectedScreen(screenAreaDetails)).then((res) => {
      if (res?.status === 200 && res?.success) {
        navigate("/quote-list");
      }
    })
  }

  useEffect(() => {
    setIsLoading(true);
    fetchScreenAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(() => {
  //   if (searchKeyword.length < 1) {
  //     setIsLoading(true);
  //     fetchScreenAreas();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchKeyword]);

  return (
    <>
      <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
        <HeaderView
          headingName={t("Sidebar.quotes_management")}
          setShowBody={setShowBody}
          showBody={showBody}
        />

        <div className="wrapper">
          <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

          <div className="main-container">

            <div className="mainCustomBG">
              <div className="main-heading">
                <h1>Quotes Screen Areas</h1>
              </div>
              <div className="quote-section">
                <ul className="screenAreaList">
                  <div className="row">
                    {isLoading ?
                      <DataLoader />
                      :
                      <>
                        {screenAreas?.length > 0 ?
                          screenAreas?.map((screenArea, index) => (
                            <div className="col-md-6" key={`screenArea + ${index}`} >
                              <li onClick={() => handleViewScreenQuotes(screenArea)}>
                                {screenArea?.areaName}
                                <img src={IMAGES.rightTickImg} alt="" />
                              </li>
                            </div>
                          ))
                          :
                          <li className="no-quotes">
                           {t('NotFound.quote_record')}
                          </li>
                        }
                      </>
                    }

                  </div>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
