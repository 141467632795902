import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { reducer as toastrReducer } from "react-redux-toastr";
import {
  abundanceAreaReducer,
  actionAreaReducer,
  authReducer,
  focusAreaReducer,
  instructionReducer,
  journeyReducer,
  quoteReducer,
  userReducer,
} from "./reducers";

// redux store states configuration

const persistConfig = {
  key: "root",
  storage,
};

const authConfig = {
  key: "auth",
  storage,
};

const userConfig = {
  key: "users",
  storage,
};

const journeyConfig = {
  key: "journeys",
  storage,
  blacklist: ["journeys"],
};

const focusAreaConfig = {
  key: "focusArea",
  storage,
};

const abundanceAreaConfig = {
  key: "abundanceArea",
  storage,
};

const actionAreaConfig = {
  key: "actionArea",
  storage,
};

const quoteConfig = {
  key: "quotes",
  storage,
};

const instructionConfig = {
  key: "instructions",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authConfig, authReducer),
  users: persistReducer(userConfig, userReducer),
  journeys: persistReducer(journeyConfig, journeyReducer),
  abundanceArea: persistReducer(abundanceAreaConfig, abundanceAreaReducer),
  focusArea: persistReducer(focusAreaConfig, focusAreaReducer),
  actionArea: persistReducer(actionAreaConfig, actionAreaReducer),
  quotes: persistReducer(quoteConfig, quoteReducer),
  instructions: persistReducer(instructionConfig, instructionReducer),
  toastr: toastrReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
