import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { IMAGES } from "../static";
import { AccordionContentView } from "./AccordionComponent";
import { journeyStatus } from "../common/store/actions/journeyActions";

export const SidebarPanel = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { abundanceAreaId, focusAreaId } = useParams();

  const { pathname } = useLocation();
  // const { activeIndex, setActiveIndex } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  // const activeStateIndex = useSelector(({ auth }) => auth.activeIndex);

  const { setShowBody, showBody } = props;

  const sideTabList = [
    {
      tabName: t("Sidebar.dashboard"),
      icon: IMAGES.dashboard_icon,
      icon_active: IMAGES.dashboard_iconActive,
      location: ["/dashboard"],
    },
    
    {
      tabName: t("Sidebar.users"),
      icon: IMAGES.user_icon,
      icon_active: IMAGES.user_iconActive,
      location: ["/users", "/user-details"],
    },
    {
      tabName: t("Sidebar.journeys"),
      icon: IMAGES.journey_icon,
      icon_active: IMAGES.journey_iconActive,
      location: ["/journeys", "/journey-details"],
    },
    {
      tabName: t("Sidebar.links_management"),
      icon: IMAGES.link_icon,
      icon_active: IMAGES.link_iconActive,
      location: ["/links-management"],
    },
    {
      tabName: t("Sidebar.logging_management"),
      icon: IMAGES.log_icon,
      icon_active: IMAGES.log_iconActive,
      location: ["/log-management"],
    },
    {
      tabName: t("Sidebar.abundance_area"),
      // icon: IMAGES.links_icon,
      location: ["/abundance-area-management", "/focus-area-management", "/action-area-management", `/focus-area-management/${abundanceAreaId}`, `/action-area-management/${focusAreaId}`],
      // location: ["/abundance-area-management"],
    },
    // {
    //   tabName: t("Sidebar.focus_area"),
    //   // icon: IMAGES.links_icon,
    //   location: ["/focus-area-management",`/focus-area-management/${abundanceAreaId}`],
    // },
    // {
    //   tabName: t("Sidebar.action_area"),
    //   // icon: IMAGES.links_icon,
    //   location: ["/action-area-management",`/action-area-management/${focusAreaId}`],
    // },
    {
      tabName: t("Sidebar.quotes_management"),
      // icon: IMAGES.links_icon,
      location: ["/quotes-management", "/quote-list"],
    },
    {
      tabName: t("Sidebar.instructions_management"),
      location: ["/instructions-management"],
    },
   
  ];

  return (
    <>
      <div className="side">
        <div className="toggle-wrap">
          <span className="toggle-bar"></span>
        </div>
        <aside>
          <div className="aside-logo">
            <img
              src={IMAGES.logo}
              alt=""
              onClick={() => {
                navigate("/dashboard");
              }}
              className="logoImg"
            />
            <img
              src={IMAGES.logo_Mark}
              alt=""
              onClick={() => {
                navigate("/dashboard");
              }}
              className="markImg"
            />
          </div>
          <div
            className="showmenuIcon"
            onClick={() => {
              setShowBody(!showBody);
              setActiveIndex(0);
            }}
          >
            <img src={IMAGES.back_Shape} alt="" />
          </div>

          <span className="menuScrol" id="style-5" onClick={() => { setShowBody(false); }}>
            {sideTabList?.length > 0 &&
              sideTabList?.splice(0, 5).map((tab) => (
                <li
                  className={tab?.location?.includes(pathname) ? "active" : ""}
                  key={tab.tabName}
                >
                  <button
                    onClick={() => {
                      navigate(tab?.location[0]);
                      dispatch(journeyStatus(""));
                      localStorage.removeItem('userId');
                    }}
                  >
                    <img src={tab.icon} alt="" className="LiIconImg" />
                    <img
                      src={tab.icon_active}
                      alt=""
                      className="LiIconImgActive"
                    />
                    <span>{tab.tabName}</span>
                  </button>
                </li>
              ))}
            <li >
              <AccordionContentView
                title={t("Sidebar.settings")}
                index={1}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                setShowBody={setShowBody}
              >
                {sideTabList?.length > 0 &&
                  sideTabList?.map((tab, index) => (
                    <span
                      // className="menuScrol"
                      // id="style-5"
                      key={`tab+${index}`}
                    >
                      <button
                        className={
                          tab?.location?.includes(pathname) ? "active" : ""
                        }
                        onClick={() => {
                          navigate(tab?.location[0]);
                        }}
                      >
                        {tab.icon &&
                          (
                            <>
                              <img src={tab.icon} alt="" className="LiIconImg" />
                              <img
                                src={tab.icon_active}
                                alt=""
                                className="LiIconImgActive"
                              />
                            </>
                          )
                        }

                        <span>{tab.tabName}</span>
                      </button>
                    </span>
                  ))}
              </AccordionContentView>
            </li>
          </span>
        </aside>
      </div>
    </>
  );
}
