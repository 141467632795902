import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

import { DataLoader, HeaderView, SearchBar, SidebarPanel } from "../components";
import { IMAGES } from "../static";
import { PaginationView } from "../common";
import { dropDownLimits, getFormatedDate, statusList } from "../utils/helperFunctions";
import { getAllJourneys, getSelectedJourney, journeyStatus } from "../common/store/actions/journeyActions";
import { getUsers } from "../common/store/actions/userActions";

export const JourneysView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userId = localStorage.getItem('userId');
    const journeys = useSelector(({ journeys }) => journeys?.journeys);
    const journeyStatusValue = useSelector(({ journeys }) => journeys?.journeyStatus);
    const users = useSelector(({ users }) => users?.allUsers);
    const userOptions = users?.map((user) => ({ value: user?._id, label: user?.userName }))

    const [showBody, setShowBody] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const tableHeaders = ["#", t('TableHeader.name'), t('TableHeader.abundance_area'), t('TableHeader.team_name'), t('TableHeader.start_date'), t('TableHeader.end_date'), t('TableHeader.status'), t('TableHeader.actions')];

    const [limit, setLimit] = useState(dropDownLimits[0]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [count, setCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searched, setSearched] = useState(false);
    const [statusFilter, setStatusFilter] = useState(journeyStatusValue ? journeyStatusValue : "");
    const [selectedUser, setSelectedUser] = useState(null);

    const handleSelectedJourney = (journeyDetails) => {
        if (selectedUser) {
            localStorage?.setItem('userId', selectedUser?.value)
        }
        dispatch(getSelectedJourney(journeyDetails)).then((res) => {
            if (res?.status === 200 && res?.success) {
                navigate("/journey-details");
            }
        });
    }

    const handleFilterUser = async () => {
        const user = await userOptions?.find((user) => user?.value === userId);
        if (user) {
            setSelectedUser(user);
        }
    }

    const fetchAllJourneys = async (search = null, status = null, userId = null) => {
        setIsLoading(true);
        await dispatch(getAllJourneys(limit, pageNumber, statusFilter, search, userId)).then((res) => {
            if (res?.status !== 200 && !res?.data?.success) {
                toastr.error("Error", res?.data?.message);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                const { limit, totalItems, totalPages } = res?.data;
                setCount(limit ? limit : 0);
                setTotalEntries(totalItems ? totalItems : 0);
                setTotalPages(totalPages ? totalPages : 0);
            }
        })
    }

    const handleSearch = () => {
        fetchAllJourneys(searchKeyword);
        setSearched(true);
    };

    const customStyles = {
        // Styles for the control (container) when focused
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? 'none' : provided.border,
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            width: 200,
        }),

        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'none' : state.isSelected ? 'none' : provided.backgroundColor,
            color: state.isFocused ? 'none' : state.isSelected ? 'none' : provided.color,
            ':hover': {
                background: '#ffc000',
                color: '#0f0bda',
            }
        })
    };

    useEffect(() => {
        if (searchKeyword.length === 0 && searched) {
            fetchAllJourneys(null, null, selectedUser?.value ? selectedUser?.value : userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyword])

    useEffect(() => {   
        dispatch(getUsers());
        fetchAllJourneys(null, null, selectedUser?.value ? selectedUser?.value : userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, limit, pageNumber, statusFilter, selectedUser])

    useEffect(() => {
        if (userId) {
            handleFilterUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])


    return (
        <>
            <div className={showBody ? "mainBody sortingMain" : "mainBody"}>
                <HeaderView
                    headingName={t("Sidebar.journeys")}
                    setShowBody={setShowBody}
                    showBody={showBody}
                />

                <div className="wrapper">
                    <SidebarPanel setShowBody={setShowBody} showBody={showBody} />

                    <div className="main-container">
                        <div className="table-container mainCustomBG">
                            <div className="headingTbL filterResponsiveCus filterMain">
                                <Dropdown drop className="dropdown filtstatus" onSelect={(e) => {
                                    setLimit(e);
                                }}>
                                    <span className="sortingShow">{t('Filterbar.show')}
                                        <Dropdown.Toggle>
                                            <span>{limit}</span>
                                            <img src={IMAGES.filter_dropdown_icon} alt="" />
                                        </Dropdown.Toggle>
                                        {t('Filterbar.enteries')}
                                    </span>
                                    <Dropdown.Menu
                                        variant=""
                                        id="style-5"
                                        className="cusDrupdown"
                                    >
                                        {dropDownLimits?.map((limit, index) => (
                                            <Dropdown.Item eventKey={limit} key={`limit+${index}`}>
                                                {limit}
                                            </Dropdown.Item>
                                        ))}

                                    </Dropdown.Menu>
                                </Dropdown>

                                <span className="search-container">
                                    <div className="respoCusBottom">
                                        <Select
                                            className="react-select-container dropdown"
                                            placeholder="Select User..." classNamePrefix="react-select" styles={customStyles} isClearable onChange={(e) => {
                                                localStorage.removeItem('userId')
                                                setSelectedUser(e);
                                            }}
                                            value={selectedUser} options={userOptions} />

                                        {/* user dropdown */}
                                        {/* <Dropdown drop className="dropdown" onSelect={(e) => {
                                            setUser(e);
                                            setSelectedUser(e);
                                        }}>
                                            <span className="sortingShow">
                                                <Dropdown.Toggle>
                                                    <span>{selectedUser ? selectedUser : "Select User"}</span>
                                                    <img src={IMAGES.filter_dropdown_icon} alt="" />
                                                </Dropdown.Toggle>
                                            </span>
                                            <Dropdown.Menu variant="" id="style-5" className="cusDrupdown" >
                                                <Dropdown.Item eventKey={"user 01"} key={"user 01"} >
                                                    User 01
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                        {/* user dropdown */}

                                        {/* status dropdown */}
                                        <Dropdown drop className="dropdown" onSelect={(e) => {
                                            setStatusFilter(e);
                                            dispatch(journeyStatus(e))
                                        }}>
                                            <span className="sortingShow">
                                                <Dropdown.Toggle>
                                                    <span>{statusFilter ? statusFilter : "All"}</span>
                                                    <img src={IMAGES.filter_dropdown_icon} alt="" />
                                                </Dropdown.Toggle>
                                            </span>
                                            <Dropdown.Menu variant=""  className="cusDrupdown" >
                                                <Dropdown.Item eventKey={""} key={"All"} className="text-uppercase">
                                                    All
                                                </Dropdown.Item>
                                                {statusList.map((statusType, index) => (
                                                    <Dropdown.Item eventKey={statusType} key={statusType}>
                                                        {statusType ? statusType : "Status"}
                                                    </Dropdown.Item>
                                                ))
                                                }

                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/* status dropdown */}
                                    </div>

                                    <SearchBar name="Search" value={searchKeyword} setSearchKeyword={setSearchKeyword} onClick={() => {
                                        handleSearch();
                                    }} />

                                </span>

                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {tableHeaders?.map((heading, index) => (
                                                <th key={heading} className={tableHeaders.length - 1 === index ? "text-center" : ""}>
                                                    {heading}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <tr>
                                                <td colSpan={tableHeaders.length} className="text-center tableE_Caption">
                                                    <DataLoader />
                                                </td>

                                            </tr>
                                            :
                                            <>
                                                {journeys?.length > 0 ? journeys?.map((journey, index) => (
                                                    <tr key={journey._id}>
                                                        <td>{(parseInt(limit * (pageNumber - 1) + index + 1) < 10) ? "0" + (limit * (pageNumber - 1) + index + 1) : (limit * (pageNumber - 1) + index + 1)}</td>
                                                        <td>
                                                            {journey.name ? journey.name : "N/A"}
                                                        </td>
                                                        <td style={{ color: journey?.colourCode }}>
                                                            {journey.abundanceArea ? journey.abundanceArea : "N/A"}
                                                        </td>
                                                        <td>
                                                            {journey.teamName ? journey.teamName : "N/A"}
                                                        </td>
                                                        <td>
                                                            {journey.journeyStartDate ? getFormatedDate(journey.journeyStartDate) : "N/A"}
                                                        </td>
                                                        <td>
                                                            {journey.desiredJourneyEndDate ? getFormatedDate(journey.desiredJourneyEndDate) : "N/A"}
                                                        </td>
                                                        <td className={journey.status === "active" || journey.status === "completed" ? "activeclr" : "inactiveclr"}>
                                                            {journey.status}
                                                        </td>
                                                        <td className="text-center">
                                                            <button className="icon-button" title="View" onClick={() => { handleSelectedJourney(journey) }}><img src={IMAGES.view_icon} alt="" /></button>
                                                            <button className="icon-button" title="Edit"><img src={IMAGES.edit_icon} alt="" /></button>
                                                        </td>
                                                    </tr>

                                                )) :
                                                    <tr>
                                                        <td colSpan={tableHeaders.length} className="text-center">
                                                            {t('NotFound.journey_record')}
                                                        </td>
                                                    </tr>

                                                }
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-pagination">
                                <p>
                                    {t('Pagination.showing')} {count > 0 ? 1 : 0} {t('Pagination.to')} {limit} {t('Pagination.of')} {totalEntries} {t('Pagination.entries')}
                                </p>
                                <div className="col-md-6 pagination">
                                    <PaginationView
                                        totalPages={totalPages}
                                        pageNumber={pageNumber}
                                        setPageNumber={setPageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
