import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";

import { IMAGES } from "../static";
import { ButtonLoader, ValidationError } from "../components";
import { doAuthSendOtp, doAuthVerifyOtp } from "../common/store/actions/authActions";

export const VerifyCodeView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userEmail = useSelector(({ auth }) => auth?.userEmail);

    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime(remainingTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        } else {
            setIsResendDisabled(false);
        }
    }, [remainingTime]);

    const handleResendClick = () => {
        setIsResendDisabled(true);
        setRemainingTime(60); // 1 minute
    };

    const handleResendCode = () => {
        dispatch(doAuthSendOtp({ email: userEmail })).then((res) => {
            if (res?.status === 200 && res?.data?.success) {
                handleResendClick();
                toastr.success("Success", res?.data?.message);
            }
        });
    }

    const VerifyCodeFormik = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: Yup.object({
            code: Yup.string().required(t("Error.code_required")).min(6, t("Error.code_length")),
        }),
        onSubmit: async (values) => {
            if (VerifyCodeFormik.isValid && VerifyCodeFormik.dirty) {
                setIsSubmitLoader(true);
                const params = {
                    email: userEmail,
                    otp: values?.code
                }
                dispatch(doAuthVerifyOtp(params)).then((res) => {
                    if (res?.status === 200 && res?.data?.success) {
                        setIsSubmitLoader(false);
                        toastr.success("Success", res?.data?.message);
                        navigate("/reset-password");
                    }
                    else {
                        setIsSubmitLoader(false);
                        toastr.error("Error", res?.data?.message);
                    }
                });
            }
        },
    });


    return (
        <>
            <div className="cover-main ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="align-self-center d-flex form-section">
                                <div className="effect-box">
                                    <div className="logo_section">
                                        <img src={IMAGES.logo} alt="" />
                                    </div>
                                    <div className="cover-box-txt">
                                        <h1><button onClick={() => navigate("/forgot-password")}><img src={IMAGES.back_Shape} alt="" /></button> {t("VerifyCode.verify_code")}</h1>
                                        <p className="subheading">{t("VerifyCode.email_sent_text")} "<b>{userEmail}</b>" </p>
                                        <div className="form-card">
                                            <form method="POST" onSubmit={VerifyCodeFormik.handleSubmit}>
                                                <div className="form-group">
                                                    <label>{t("VerifyCode.otp_label")}</label>
                                                    <input type="text" className="form-control" placeholder={t("VerifyCode.code_placeholder")} name="code" value={VerifyCodeFormik?.values?.code}
                                                        onChange={VerifyCodeFormik.handleChange} onBlur={VerifyCodeFormik.handleBlur} autoComplete="false" />
                                                    {VerifyCodeFormik.touched.code && VerifyCodeFormik.errors.code && (
                                                        <ValidationError error={VerifyCodeFormik.errors.code} />
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <div className="forg-box">
                                                        {isResendDisabled ? (
                                                            <span >{t('VerifyCode.resend_OTP_in')} <b>{remainingTime}</b> {t('VerifyCode.seconds')}</span>
                                                        ) : (
                                                            <button type="button" onClick={() => { handleResendCode() }} >{t("Buttons.resend_code")}</button>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="footer-buttons">
                                                    <div className="form-group">
                                                        <button type="submit" disabled={isSubmitLoader}>
                                                            {isSubmitLoader ?
                                                                <span className="d-flex justify-content-between">
                                                                    <ButtonLoader />
                                                                    {t("Loading.please_wait")}
                                                                </span>
                                                                :
                                                                t("Buttons.next")
                                                            }
                                                        </button>
                                                        {/* <p className="back-button" onClick={() => navigate(-1)}>{t("Buttons.back")}</p> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="rightAuthbx">
                                <img src={IMAGES.login_Auth_img} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
