export const BaseUrl = process.env.REACT_APP_BASE_URL;

export const AuthApiEndpoint = {
  AUTH_LOGIN_API: BaseUrl + "admin/login",
  AUTH_SEND_OTP_API: BaseUrl + "admin/sendOtp",
  AUTH_VERIFY_OTP_API: BaseUrl + "admin/verifyOtp",
  AUTH_SET_PASSWORD_API: BaseUrl + "admin/setPassword",
  AUTH_CHANGE_PASSWORD_API: BaseUrl + "admin/changePassword",
  AUTH_REFRESH_TOKEN_API: BaseUrl + "admin/generateTokenByRefreshToken",
  AUTH_LOGOUT_API: BaseUrl + "admin/logout",
  AUTH_DASHBOARD_DATA_API: BaseUrl + "admin/dashboard",
  AUTH_ADD_LINK_API: BaseUrl + "admin/addlink",
  AUTH_GET_LINK_API: BaseUrl + "admin/link",
};

export const UserApiEndpoint = {
  GET_USER_LIST_API: BaseUrl + "user/listForAdmin",
  GET_USER_LOGS_API: BaseUrl + "user/logs",
  UPDATE_USER_DETAILS_API: BaseUrl + "admin/updateUser",
  DELETE_USER_API: BaseUrl + "admin/deleteUserAccount"
};


export const JourneyApiEndpoint = {
  GET_ALL_JOURNEYS_API: BaseUrl + "journey/getJourneyListing",
};

export const AbundanceAreaApiEndpoint = {
  ADD_ABUNDANCE_AREA_API: BaseUrl + "admin/addAbundanceArea",
  GET_ABUNDANCE_AREA_LIST_API: BaseUrl + "admin/getAbundanceAreaListing",
  UPDATE_ABUNDANCE_AREA_API: BaseUrl + "admin/updateAbundanceArea",
  DELETE_ABUNDANCE_AREA_API: BaseUrl + "admin/deleteAbundanceArea/",
};

export const FocusAreaApiEndpoint = {
  ADD_FOCUS_AREA_API: BaseUrl + "admin/addFocusArea",
  GET_FOCUS_AREA_LIST_API: BaseUrl + "admin/getFocusAreaListing",
  UPDATE_FOCUS_AREA_API: BaseUrl + "admin/updateFocusArea",
  DELETE_FOCUS_AREA_API: BaseUrl + "admin/deleteFocusArea/",
};

export const ActionAreaApiEndpoint = {
  ADD_ACTION_AREA_API: BaseUrl + "admin/addActionArea",
  GET_ACTION_AREA_LIST_API: BaseUrl + "admin/getActionAreaListing",
  UPDATE_ACTION_AREA_API: BaseUrl + "admin/updateActionArea",
  DELETE_ACTION_AREA_API: BaseUrl + "admin/deleteActionArea/",
};

export const QuotesApiEndpoint = {
  GET_ALL_SCREEN_AREAS_API: BaseUrl + "admin/journeyScreenAreas",
  GET_ALL_QUOTES_API: BaseUrl + "admin/getQuote/",
  ADD_UPDATE_QUOTE_API: BaseUrl + "admin/addUpdateQuote",
};

export const InstructionsApiEndpoint={
  GET_ALL_SCREEN_AREAS_API: BaseUrl + "admin/journeyScreenAreas",
  ADD_JOURNEY_INSTRUCTIONS_API:BaseUrl + "admin/addJourneyInstruction", //For journey instructions
  GET_INSTRUCTIONS_LIST_API:BaseUrl + "admin/listJourneyInstruction"
}

export const AWSCredentialsApiEndpoint = {
  GET_CREDENTIALS: BaseUrl + "admin/configKey",
};
