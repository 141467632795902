import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { IMAGES } from "../../static/images";

export const PopupModal = ({ className, show, onHide, children, title }) => {
  return (
    <Modal
      className={`common_modal ${className ? className : ""}`}
      show={show}
      centered
    >
      <Modal.Header>
        <h2>{title}</h2>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            onHide();
          }}
        >
          <img src={IMAGES.close_modal_icon} alt="Close Button" />
        </button>
      </Modal.Header>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};
