import { journeyActions } from "../actions/actionTypes";

const getInitialState = () => ({
  journeys: [],
  selectedJourney: {},
  journeyStatus: "",
});

export const journeyReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case journeyActions.GET_JOURNEY_LIST:
      return {
        ...state,
        journeys: action.payload,
      };

    case journeyActions.GET_SELECTED_JOURNEY:
      return {
        ...state,
        selectedJourney: action.payload,
      };

    case journeyActions.GET_JOURNEY_STATUS:
      return {
        ...state,
        journeyStatus: action.payload,
      };

    case journeyActions.AUTH_LOGOUT:
      return {
        ...state,
        journeys: [],
        selectedJourney: {},
        journeyStatus: "",
      };

    default:
      return state;
  }
};
