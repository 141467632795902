import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './common/store/rootReducer';
import thunk from 'redux-thunk';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';

import './i18n';
import "./static/css/styles.css";
import "./static/css/login-css.css";
import "./static/css/modal-css.css";
import "./static/css/bootstrap.min.css";
import "./static/css/font-awesome.min.css";


const root = ReactDOM.createRoot(document.getElementById('root'));

export const store = configureStore({ reducer: rootReducer, middleware: [thunk] });
const persistor = persistStore(store);

root.render(
  // <React.StrictMode>
  <Provider store={store} >
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
