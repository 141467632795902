import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IMAGES } from "../static";

export const PageNotFoundView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="notfoundbox">
        <div className="container-fluid">
          <div className="notfoundPage">
            <img src={IMAGES.notfoundImg} alt="" />
            <h1>{t("Error.page_not_found")}</h1>
            <p>Page Not Found</p>
            <div className="form-group">
              <button
                type="button"
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("Buttons.back_to_home")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
