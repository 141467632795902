import { instructionActions, quoteActions } from "../actions/actionTypes";

const getInitialState = () => ({
  instructionScreenAreas: [],
  selectedInstructionScreenArea: {},
  instructions: [],
});

export const instructionReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case instructionActions.GET_INSTRUCTION_SCREENS_AREAS:
      return {
        ...state,
        instructionScreenAreas: action.payload,
      };

    case instructionActions.GET_SELECTED_INSTRUCTION_AREA:
      return {
        ...state,
        selectedInstructionScreenArea: action.payload,
      };
    case instructionActions.GET_INSTRUCTIONS:
      return {
        ...state,
        instructions: action.payload,
      };

    case quoteActions.AUTH_LOGOUT:
      return {
        ...state,
        instructionScreenAreas: [],
        selectedInstructionScreenArea: {},
        instructions: [],
      };

    default:
      return state;
  }
};
