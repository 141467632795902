import { focusAreaActions } from "../actions/actionTypes";

const getInitialState = () => ({
  focusAreaList: [],
  selectedFocusArea: {},
});

export const focusAreaReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case focusAreaActions.GET_FOCUS_AREA_LIST:
      return {
        ...state,
        focusAreaList: action.payload,
      };
    case focusAreaActions.SELECTED_FOCUS_AREA:
      return {
        ...state,
        selectedFocusArea: action.payload,
      };

    case focusAreaActions.AUTH_LOGOUT:
      return {
        ...state,
        focusAreaList: [],
        selectedFocusArea: {},
      };

    default:
      return state;
  }
};
